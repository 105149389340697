import { inject, Injectable } from '@angular/core'
import { Bookmark, BookmarkType, DataQueryProps } from '@ti-platform/contracts'
import { ListModel, ListModelConfig } from '@ti-platform/web/common'
import { DataGridColumnType } from '@ti-platform/web/ui-kit/layout/components'
import { Router } from '@angular/router'
import { BookmarksManager } from '@ti-platform/web/bookmarks'

@Injectable()
export class BookmarksListModel extends ListModel<Bookmark> {
  public override title = 'bookmarks.title'
  protected readonly bookmarks = inject(BookmarksManager)
  protected readonly router = inject(Router)

  async showEditBookmarkNameDialog(bookmark: Bookmark) {
    if (await this.bookmarks.editBookmarkName(bookmark)) {
      this.refresh()
    }
  }

  async editBookmarkName(bookmark: Bookmark) {
    await this.bookmarks.editName(bookmark)
    this.refresh()
  }

  navigateToBookmark(bookmark: Bookmark) {
    return this.bookmarks.navigateToBookmark(bookmark)
  }

  public async remove(bookmark: Bookmark) {
    await this.bookmarks.remove(bookmark)
    return this.refresh()
  }

  public applyTypeFilter(filter: Record<string, any>) {
    if (filter?.type?.length < 1) {
      this.resetFilter()
    } else {
      this.setFilter(filter)
    }
  }

  protected config(): ListModelConfig {
    return {
      name: 'BookmarksListModel',
      retainCacheUrl: ['bookmarks'],
      loadCount: () => this.loadCount(),
      loadFilterOptions: () => this.loadFilterOptions(),
      defaultOrderColumn: 'createdAt',
      defaultOrderDirection: 'DESC',
      gridColumns: [
        {
          field: 'name',
          label: 'bookmarks.labels.name',
          type: DataGridColumnType.Template,
          sortable: true,
        },
        {
          field: 'type',
          label: 'bookmarks.labels.type',
          type: DataGridColumnType.Template,
          sortable: true,
        },
        {
          field: 'createdAt',
          label: 'bookmarks.labels.added',
          type: DataGridColumnType.Date,
          sortable: true,
        },
        {
          field: 'actions',
          label: '',
          type: DataGridColumnType.Template,
          sortable: false,
        },
      ],
    }
  }

  protected loadPage(props: DataQueryProps): Promise<Bookmark[]> {
    return this.api.fleet.bookmarks.list(props)
  }

  protected async loadCount() {
    return this.api.fleet.bookmarks.count()
  }

  protected async loadFilterOptions() {
    const labels = await this.languageService.massTranslate({
      vehicleDetails: 'bookmarks.types.vehicle-details',
      triggeredAlert: 'bookmarks.types.triggered-alert',
      mediaGroup: 'bookmarks.types.media-group',
      report: 'bookmarks.types.report',
    })

    return [
      {
        key: 'type',
        title: 'bookmarks.labels.types',
        placeholder: 'bookmarks.filter-placeholder',
        options: [
          {
            label: labels.vehicleDetails,
            value: BookmarkType.VehicleDetails,
          },
          {
            label: labels.triggeredAlert,
            value: BookmarkType.TriggeredAlert,
          },
          {
            label: labels.mediaGroup,
            value: BookmarkType.MediaGroup,
          },
          {
            label: labels.report,
            value: BookmarkType.Report,
          },
        ],
        searchable: false,
      },
    ]
  }
}
