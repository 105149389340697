import { Point } from '@ti-platform/contracts/data'
import { MediaConnection, MediaFileType, MediaRequest, MediaRequestType } from '../models'

export enum FlespiMediaType {
  Photo = 'image',
  Video = 'video',
}

export enum VideoQualityStreamtype {
  Substream = 'sub',
  Mainstream = 'main',
}

type FlespiMediaCommonMetaData = {
  channel: number
  type: FlespiMediaType
  command_id?: number
  height: number
  width: number
}

type FlespiVideoMetaData = {
  // in seconds
  duration: number
  has_audio?: boolean
  video_codec?: string
}

export type FlespiMediaMetaData = FlespiMediaCommonMetaData &
  FlespiVideoMetaData &
  Record<string, any>

export type FlespiMediaData = {
  // timestamp in seconds
  created: number
  meta: FlespiMediaMetaData
  mime: string
  name: string
  // bytes
  size: number
  url: string
  uuid: string
}

export type CommonVehicleData = {
  tspId: number
  fleetId: number
  vehicleId: string
  deviceId: string
  externalDeviceId: number
}

export type FlespiMediaFileDataDto = CommonVehicleData & {
  data: FlespiMediaData
  request?: Partial<MediaRequest>
}

// TODO: Add correct types and reduce `any` usage
// TODO: Consider move this class outside of `contracts` library
export class FlespiMediaFileData implements FlespiMediaData {
  created!: number
  meta!: FlespiMediaMetaData
  mime!: string
  name!: string
  // bytes
  size!: number
  url!: string
  uuid!: string

  constructor(props: FlespiMediaData) {
    Object.assign(this, props)
  }

  get requestId(): number | undefined {
    return this.meta.command_id
  }

  get type(): MediaFileType {
    return this.meta.type === FlespiMediaType.Photo ? MediaFileType.Photo : MediaFileType.Video
  }

  get startDate(): Date {
    return new Date(this.created * 1000)
  }

  get endDate(): Date {
    if (this.type === MediaFileType.Photo) {
      return this.startDate
    } else {
      const timestamp = this.created + (this.meta.duration || 0)
      return new Date(timestamp * 1000)
    }
  }

  get metadata() {
    return {
      ...this.meta,
      mime: this.mime,
      flespiUrl: this.url,
    }
  }
}

type commandRequestCommonData = {
  id: number
  name: string
  properties: Record<string, any>
  device_id: number
  executed: boolean
  // in seconds
  timestamp: number
}

export type commandRequestAddedInQueueData = commandRequestCommonData & {
  response: null
  position: number
  expires: number
}

export type commandRequestCommonResultData = commandRequestCommonData & {
  channel_id?: number
  event_code?: number
  ident?: string
  origin_id?: number
  origin_type?: number
}

export type mediaRequestCommonResultData = commandRequestCommonResultData & {
  response: FlespiMediaData | string | null
} & Record<string, any>

export type videoRequestResultDataEvent = {
  status: 'processed' | 'expired'
  command_result_data: mediaRequestCommonResultData
}

export type SendVideoRequestsDto = {
  vehicleData: CommonVehicleData
  channels: number[]
  beginSecTimestamp: number
  endSecTimestamp: number
  streamtype?: VideoQualityStreamtype
  triggeredAlertId?: string
  userId?: string
  location?: Point
  address?: string
  locationIsAccurate?: boolean
  requestGroupId?: string
}

export type FlespiMediaRequestDataDto = CommonVehicleData & {
  tspId: number
  fleetId: number
  vehicleId: string
  deviceId: string
  externalDeviceId: number
  data: commandRequestAddedInQueueData | mediaRequestCommonResultData
  type: MediaRequestType
  startDate: Date
  endDate: Date
  triggeredAlertId?: string
  groupId?: string
  location?: Point
  address?: string
  createdBy?: string
  locationIsAccurate?: boolean
}

export type TakeRealTimePhotosDto = {
  vehicleData: CommonVehicleData
  channels: number[]
  userId?: string
  location?: Point
  address?: string
  locationIsAccurate?: boolean
}

export type GetVideoTimelineDto = {
  deviceId: number
  beginSecTimestamp: number
  endSecTimestamp: number
  streamtype?: VideoQualityStreamtype
}

export type TimelineRequestItemData = {
  begin: number
  end: number
  channel: 1
  size: number
  streamtype: VideoQualityStreamtype
  filetype?: 'video' | 'image'
  locked?: boolean
}

export type TimelineRequestCommonResultData = commandRequestCommonResultData & {
  response: TimelineRequestItemData[] | string | null
} & Record<string, any>

export type VideoStreamData = {
  channel: number
  command_id: number
  connection_id: number
  has_audio: boolean
  height: number
  // link
  hls: string
  uuid: string
  video_codec: string
  width: number
}

export type ConnectionMetaWithVideoStreamData = {
  mediastream: VideoStreamData
}

export type DeviceConnectionData = {
  channel_id: number
  ip?: string
  meta: Record<string, any> | (Record<string, any> & ConnectionMetaWithVideoStreamData) | null
  id: number
  source: string
  established: number
  secondary: boolean
  device_id: number
  ident: string
  transport: string
}

export type VehicleConnectionStatusData = {
  connected: boolean
  videoStreams: VideoStreamData[]
}

export type StartDeviceLivestreamDto = {
  deviceId: number
  channels: number[]
  streamtype?: VideoQualityStreamtype
}

export type videoStreamRequestCommonResultData = commandRequestCommonResultData & {
  response: VideoStreamData | string | null
} & Record<string, any>

export type CloseConnectionLogData = {
  close_code: number
  connection_id: number
  duration: number
  event_code: number
  // flespi device id ???
  id: number
  ident: string
  // in bytes
  media_traffic: number
  msgs: number
  // flespi device id
  origin_id: number
  origin_type: number
  recv: number
  secondary: boolean
  send: number
  source: string
  timestamp: number
  transport: string
}

export type MediaConnectionUpsertData = Partial<MediaConnection> & {
  id: string
  externalDeviceId: number
  startDate?: Date
  endDate?: Date
  isVideoStream?: boolean
  mediaTraffic?: number
  fleetId?: number
  tspId?: number
  vehicleId?: string
  deviceId?: string
}
