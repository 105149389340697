import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  signal,
} from '@angular/core'
import { MenuItem, PrimeIcons } from 'primeng/api'
import { map, Observable, takeUntil } from 'rxjs'
import { Action, Bookmark, Resource } from '@ti-platform/contracts'
import { AccessControl, injectDestroy$, Memoize } from '@ti-platform/web/common'
import { BookmarksListModel } from '@ti-platform/web/bookmarks'

@Component({
  selector: 'app-bookmarks-list-item',
  templateUrl: 'bookmarks-list-item.component.html',
  styleUrls: ['bookmarks-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookmarksListItemComponent {
  @Input() bookmark!: Bookmark
  @Input() selectedBookmarkId?: string
  public readonly elementRef = inject(ElementRef)
  protected readonly bookmarks = inject(BookmarksListModel)
  protected readonly accessControl = inject(AccessControl)
  protected readonly destroy$ = injectDestroy$()
  protected readonly isHighlighted = signal(false)

  @Memoize()
  public get options$(): Observable<MenuItem[]> {
    return this.accessControl
      .massCheck$([
        [Resource.Location, Action.Update],
        [Resource.Location, Action.Delete],
      ])
      .pipe(
        map(([canUpdate, canDelete]) => {
          return [
            {
              label: 'bookmarks.actions.rename',
              icon: 'pi pi-pencil',
              styleClass: `has-bottom-border ${canUpdate ? '' : 'not-allowed'} context-actions-menu-item`,
              command: () => this.bookmarks.showEditBookmarkNameDialog(this.bookmark),
            },
            {
              label: 'bookmarks.actions.remove',
              icon: PrimeIcons.BOOKMARK,
              styleClass: `error ${canDelete ? '' : 'not-allowed'} context-actions-menu-item`,
              command: () => this.bookmarks.remove(this.bookmark),
            },
          ]
        }),
        takeUntil(this.destroy$),
      )
  }

  protected clickAtBody() {
    document.body.click()
  }
}
