export enum BookmarkType {
  VehicleDetails = 'vehicle-details',
  TriggeredAlert = 'triggered-alert',
  MediaGroup = 'media-group',
  Report = 'report',
}

export interface Bookmark {
  id: string
  name: string
  route: string
  type: BookmarkType
  entityId: string
  tspId: number
  fleetId: number
  createdAt: Date
  createdBy: string
  deleted: boolean
  deletedAt: Date | null
}

export interface BookmarkDTO {
  type: BookmarkType
  route: string
  name: string
  entityId: string
}
