<main class="table-wrapper">
  <app-data-grid
    (loadMoreClicked)="model.loadNextPage()"
    (rowItemClicked)="rowItemClicked.emit($event)"
    (sortUpdated)="model.setOrder($event)"
    [columns]="(displayColumns$ | async) ?? []"
    [data]="(state.items$ | async) ?? []"
    [isLoading]="(state.isLoading$ | async) ?? false"
    [isSelectable]="true"
    [noDataDescription]="
      this.model.isSearchOrFilterApplied
        ? ('search.no-data-description' | translate)
        : ('bookmarks.no-data-description' | translate)
    "
    [noDataIconTemplate]="
      this.model.isSearchOrFilterApplied ? gridEmptySearchIconTmpl : gridEmptyStateIconTmpl
    "
    [noDataTitle]="'bookmarks.title' | translate"
    [paginationEnabled]="(state.canLoadNextPage$ | async) ?? false"
    [selectedRow]="(state.selectedItem$ | async) || null"
    [sortOrder]="(state.gridSortOrder$ | async) || undefined"
    [templates]="{
      name,
      type,
      actions,
    }"
  ></app-data-grid>
</main>

<ng-template #gridEmptyStateIconTmpl>
  <app-bookmarks-icon></app-bookmarks-icon>
</ng-template>

<ng-template #gridEmptySearchIconTmpl>
  <app-icon-no-search></app-icon-no-search>
</ng-template>

<ng-template #name let-row="row">
  <section class="name-container flex flex--row text-14 fw-400 text-color-grey-900">
    <app-fleet-bookmarks-type-icon [type]="row.type" class="mr-8"></app-fleet-bookmarks-type-icon>

    <app-inline-text-editor
      (cancelled)="onCancelEditName()"
      (enterEdit)="onClickEditName(row)"
      (submitted)="onSaveName(row, $event)"
      [isEditMode]="editedNameStatus.bookmark?.id === row.id"
      [isLoading]="editedNameStatus.isLoading"
      [maxLength]="nameMaxLength"
      [minLength]="nameMinLength"
      [value]="row.name"
      [keepPencilIcon]="true"
    ></app-inline-text-editor>
  </section>
</ng-template>

<ng-template #type let-row="row">
  <span class="text-14 fw-400 text-color-grey-900">
    {{ 'bookmarks.types.' + row.type | translate }}
  </span>
</ng-template>

<ng-template #actions let-data="row">
  <section (click)="$event.stopPropagation()" class="actions-wrapper flex flex--row">
    <app-unbookmark-icon
      (click)="model.remove(data)"
      [height]="20"
      [pTooltip]="'bookmarks.actions.remove' | translate"
      [width]="20"
    ></app-unbookmark-icon>
  </section>
</ng-template>
