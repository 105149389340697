export interface Role {
  id: number
  name: string
  description: string
  permissions?: UserPermissions
}

// TODO: Replace user role ids
export enum UserRole {
  /* 101 */ TIOwner = 1,
  /* 102 */ TIAdmin = 2,
  /* 103 */ TISupport = 3,
  /* 201 */ TSPOwner = 4,
  /* 202 */ TSPAdmin = 5,
  /* 203 */ TSPSupport = 6,
  /* 204 */ TSPFinance = 7,
  /* 205 */ TSPViewer = 8,
  /* 301 */ FleetOwner = 9,
  /* 302 */ FleetAdmin = 10,
  /* 303 */ FleetFinance = 11,
  /* 304 */ FleetViewer = 12,
  /* 305 */ FleetInstaller = 13,
  /* 306 */ FleetDispatcher = 14,
  /* 307 */ FleetMaintenance = 15,
  /* 308 */ FleetDemo = 16,
}

export enum Resource {
  Alert = 'alert',
  Billing = 'billing',
  Bookmarks = 'bookmarks',
  Device = 'device',
  Driver = 'driver', // for future
  Fleet = 'fleet',
  Location = 'location',
  Reports = 'reports',
  TSP = 'tsp',
  User = 'user',
  Vehicle = 'vehicle',
  WhiteLabel = 'white-label',
  Media = 'media',
}

export enum Action {
  View = 'view',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  LoginAs = 'login-as',
  Suspend = 'suspend',
}

export type UserPermissions = Record<Resource, Action[]>

export const CRUD_ACTIONS = [Action.View, Action.Create, Action.Update, Action.Delete]
export const FULL_PERMISSIONS: UserPermissions = {
  [Resource.Alert]: [...CRUD_ACTIONS],
  [Resource.Bookmarks]: [...CRUD_ACTIONS],
  [Resource.Billing]: [Action.View],
  [Resource.Device]: [...CRUD_ACTIONS],
  [Resource.Driver]: [...CRUD_ACTIONS],
  [Resource.Fleet]: [...CRUD_ACTIONS, Action.Suspend, Action.LoginAs],
  [Resource.Location]: [...CRUD_ACTIONS],
  [Resource.Reports]: [Action.View],
  [Resource.TSP]: [...CRUD_ACTIONS, Action.LoginAs],
  [Resource.User]: [...CRUD_ACTIONS],
  [Resource.Vehicle]: [Action.View, Action.Update],
  [Resource.WhiteLabel]: [Action.View, Action.Update],
  [Resource.Media]: [...CRUD_ACTIONS],
}

export const TI_RESOURCES = [Resource.TSP, Resource.User]
export const TSP_RESOURCES = [Resource.Billing, Resource.Device, Resource.Fleet, Resource.User]
export const FLEET_RESOURCES = [
  Resource.Alert,
  Resource.Billing,
  Resource.Driver,
  Resource.Location,
  Resource.User,
  Resource.Vehicle,
  Resource.Media,
]

export const checkAccess = (
  resource: Resource,
  action: Action,
  userPermissions: UserPermissions,
): boolean => {
  return userPermissions && userPermissions?.[resource]?.includes(action)
}

/**
 * Check whether the permission request is satisfied by FULL_PERMISSIONS
 * @throws {Error}
 */
export const validatePermissionsToCheck = (
  resource: Resource,
  actions: Action[],
  throwError = true,
) => {
  actions.forEach((action) => {
    if (!checkAccess(resource, action, FULL_PERMISSIONS)) {
      const message = `Detected invalid permission check ${resource} -> ${action} is not valid!`
      if (throwError) {
        throw new Error(message)
      }
      console.error(message)
    }
  })
}
