import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'

@Component({
  selector: 'app-duration-dialog',
  templateUrl: 'duration-dialog.component.html',
  styleUrl: 'duration-dialog.component.scss',
})
export class DurationDialogComponent {
  @Output() readonly durationChange = new EventEmitter<number>()
  @Output() readonly closed = new EventEmitter<void>()

  protected readonly deviceService = inject(DeviceService)
  protected readonly changeDetectorRef = inject(ChangeDetectorRef)

  @Input() duration?: number
  @Input() caption?: string
  @Input() description?: string
  @Input() visible = false
  @Input() dissmisable?: boolean = true
  @Input() modal?: boolean = true
  @Input() minValue?: number
  @Input() maxValue?: number

  protected isDurationChanged = false

  protected get isDurationTooShort() {
    return this.minValue && typeof this.duration === 'number' && this.duration < this.minValue
  }

  protected get isDurationTooLong() {
    return this.maxValue && typeof this.duration === 'number' && this.duration > this.maxValue
  }

  protected get inputInvalid() {
    return Boolean(!this.duration || this.isDurationTooShort || this.isDurationTooLong)
  }

  protected durationUpdated(value: number) {
    this.isDurationChanged = true
    this.duration = value
    this.changeDetectorRef.markForCheck()
  }
}
