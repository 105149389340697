<p-button (click)="open($event)" [severity]="'secondary'" class="h--40" icon="pi">
  <i class="pi pi-ellipsis-v"></i>
</p-button>

<p-menu
  #menuRef
  (click)="$event.stopPropagation()"
  [appendTo]="appendToBody ? 'body' : ''"
  [model]="(options$ | async | translateLabels) ?? []"
  [popup]="true"
  class="menu-primary"
>
  <ng-template pTemplate="start">
    @if (statusTitle) {
      <div class="menu-header">
        <div class="status-box">
          <div class="status text-10 fw-700">
            {{ statusTitle | translate }}
          </div>
        </div>
      </div>
    }
  </ng-template>

  <ng-template let-item pTemplate="item">
    <a
      [routerLink]="item.routerLink"
      class="flex media-actions-menu-item align-items-center p-menuitem-link"
      pRipple
    >
      <div class="p-menuitem {{ item.cssClass }}">
        @if (item.customIcon) {
          <div class="icon-wrapper">
            <app-dynamic-host
              class="p-menuitem-icon"
              [componentType]="item.customIcon"
              [componentInputs]="{ color: 'var(--icon-color)', height: 20, width: 20 }"
            ></app-dynamic-host>
          </div>
        }
        @if (item.icon) {
          <i class="p-menuitem-icon" [class]="item.icon"></i>
        }
        <span class="p-menuitem-text text-14">{{ item.label }}</span>
      </div>
    </a>
  </ng-template>
</p-menu>

<p-menu
  #exportMenu
  (mouseleave)="exportMenu.hide()"
  [model]="(exportMenuItems$ | async) || []"
  [popup]="true"
  styleClass="export-menu"
>
  <ng-template pTemplate="start">
    <div class="menu-start">
      <div class="menu-start__title">{{ 'button.export' | translate }}</div>
      <div class="menu-start__description">{{ 'export.description' | translate }}</div>
    </div>
  </ng-template>
  <ng-template let-item pTemplate="item">
    <a class="flex align-items-center p-menuitem-link export-menu-item" pRipple>
      @switch (item.type) {
        @case (ExportFormat.Excel) {
          <app-icon-xls-file class="mr-8" width="20px" height="20px"></app-icon-xls-file>
        }
        @case (ExportFormat.CSV) {
          <app-icon-csv-file class="mr-8" width="20px" height="20px"></app-icon-csv-file>
        }
        @case (ExportFormat.PDF) {
          <app-icon-pdf-file class="mr-8" width="20px" height="20px"></app-icon-pdf-file>
        }
      }
      <span class="ml-2 menu-item-label">{{ item.label }}</span>
    </a>
  </ng-template>
</p-menu>
