import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-video-player',
  templateUrl: 'video-player.component.html',
  styleUrl: 'video-player.component.scss',
})
export class VideoPlayerComponent implements OnDestroy, OnInit {
  @Output() paused = new EventEmitter<void>()
  @Output() started = new EventEmitter<void>()
  @Output() restarted = new EventEmitter<void>()
  @Output() timeUpdated = new EventEmitter<number>()

  @Input() startDate!: number
  @Input() endDate!: number

  @ViewChild('slider') slider!: ElementRef<HTMLDivElement>
  @ViewChild('cursor') cursor!: ElementRef<HTMLDivElement>

  protected currentCursorPositionInPercents$ = new BehaviorSubject<number>(0)
  protected isPaused$ = new BehaviorSubject(true)
  protected isVideoEnded$ = new BehaviorSubject(false)

  protected currentCursorPosition = 0
  protected startTime = 0
  protected endTime = 0

  protected intervalId: any | null = null
  private isDragging = false

  private globalMouseMoveListener!: (value: any) => void
  private globalMouseUpListener!: () => void

  public ngOnInit() {
    this.endTime = this.endDate - this.startDate
    this.currentCursorPosition = this.startTime
  }

  protected handleGlobalMouseUp() {
    const isAllowedToUpdate = this.endTime - this.currentCursorPosition !== 0
    if (isAllowedToUpdate) {
      this.play()
      this.timeUpdated.emit(this.currentCursorPosition - this.startTime)
    } else {
      this.isVideoEnded$.next(true)
    }

    document.removeEventListener('mouseup', this.globalMouseUpListener)
    document.removeEventListener('mousemove', this.globalMouseMoveListener)
  }

  protected sliderClick(event: MouseEvent) {
    const sliderRect = this.slider.nativeElement.getBoundingClientRect()
    this.updateSliderClickCursorPosition(event.clientX - sliderRect.left)
  }

  protected handleMouseDown() {
    this.isDragging = true
    this.pause()

    this.globalMouseMoveListener = this.handleMouseMove.bind(this)
    this.globalMouseUpListener = this.handleGlobalMouseUp.bind(this)
    document.addEventListener('mousemove', this.globalMouseMoveListener)
    document.addEventListener('mouseup', this.globalMouseUpListener)
  }

  private handleMouseMove(event: MouseEvent) {
    if (!this.isDragging) return

    const cursorRect = this.cursor.nativeElement.getBoundingClientRect()
    const cursorLeft = cursorRect.left

    this.updateDragCursorPosition(event.clientX - cursorLeft)
  }

  protected play() {
    this.isPaused$.next(false)
    this.isVideoEnded$.next(false)

    this.started.emit()
    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        this.increaseTime()
      }, 10)
    }
  }

  protected pause() {
    this.isPaused$.next(true)
    this.paused.emit()
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
  }

  protected restart() {
    this.isPaused$.next(true)
    this.isVideoEnded$.next(false)
    this.restarted.emit()
    this.currentCursorPosition = this.startTime
    this.currentCursorPositionInPercents$.next(0)
  }

  protected increaseTime() {
    const newValue = this.currentCursorPosition + 10

    if (newValue >= this.endTime) {
      this.pause()
      this.currentCursorPosition = this.endTime
      this.isVideoEnded$.next(true)
    } else {
      this.currentCursorPosition = newValue
    }

    this.calculatePercents()
  }

  protected calculatePercents() {
    const value = +(
      ((this.currentCursorPosition - this.startTime) / (this.endTime - this.startTime)) *
      100
    ).toFixed(1)

    this.currentCursorPositionInPercents$.next(value > 100 ? 100 : value)
  }

  protected updateSliderClickCursorPosition(offsetAbsolute: number) {
    const percentage = +(offsetAbsolute / this.slider.nativeElement.getBoundingClientRect().width)

    if (percentage > 1) {
      this.currentCursorPositionInPercents$.next(100)
    } else if (percentage < 0) {
      this.currentCursorPositionInPercents$.next(0)
    } else {
      this.currentCursorPositionInPercents$.next(percentage * 100)
    }

    this.currentCursorPosition = this.endTime * percentage
    this.timeUpdated.emit(this.currentCursorPosition - this.startTime)
    this.isVideoEnded$.next(false)
  }

  protected updateDragCursorPosition(offsetRelative: number) {
    const sliderWidth = this.slider.nativeElement.getBoundingClientRect().width
    const offsetAbsolute = Math.abs(offsetRelative)

    if (offsetRelative < 0) {
      const percentage = offsetAbsolute / sliderWidth
      const offset = (this.endTime - this.startTime) * percentage

      const cursorNewPosition = this.currentCursorPosition + (0 - offset)

      if (cursorNewPosition < this.startTime) {
        this.currentCursorPosition = this.startTime
      } else {
        this.currentCursorPosition = cursorNewPosition
      }

      this.calculatePercents()
    } else {
      const percentage = offsetRelative / sliderWidth
      const offset = (this.endTime - this.startTime) * percentage
      const cursorNewPosition = this.currentCursorPosition + offset

      if (cursorNewPosition > this.endTime) {
        this.currentCursorPosition = this.endTime
      } else {
        this.currentCursorPosition = cursorNewPosition
      }

      this.calculatePercents()
    }
  }

  ngOnDestroy() {
    if (this.globalMouseUpListener) {
      document.removeEventListener('mouseup', this.globalMouseUpListener)
    }
  }
}
