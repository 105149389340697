import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { UserProfile, UserRole } from '@ti-platform/contracts'
import { Profile } from '@ti-platform/web/auth'
import { Memoize, injectDestroy$, CONFIG } from '@ti-platform/web/common'
import { SelectItem } from 'primeng/api'
import { BehaviorSubject, Observable, combineLatest, map, takeUntil } from 'rxjs'
import { UserRolesProvider } from '../../providers'

@Component({
  selector: 'app-user-form',
  templateUrl: 'user-form.component.html',
  styleUrl: 'user-form.component.scss',
})
export class UserFormComponent implements OnInit {
  protected readonly config = inject(CONFIG)
  protected readonly profile = inject(Profile)
  protected readonly userOptionsProvider = inject(UserRolesProvider)
  protected readonly destroy$ = injectDestroy$()

  protected readonly editedUserRole$ = new BehaviorSubject<UserRole | null>(null)

  @Output() cancelForm = new EventEmitter<void>()
  @Output() submitForm = new EventEmitter()
  @Input() displayRoles: 'TI' | 'TSP' | 'Fleet' = 'TSP'

  protected _data: Partial<UserProfile> = {}

  get data() {
    return this._data
  }

  @Input()
  set data(value: Partial<UserProfile>) {
    this._data = value
    this.editedUserRole$.next(this.data.roleId ?? null)
  }

  @Memoize()
  get tspRoleOptions$(): Observable<SelectItem[]> {
    return combineLatest([
      this.profile.state,
      this.userOptionsProvider.userRoleLabels$,
      this.editedUserRole$,
    ]).pipe(
      takeUntil(this.destroy$),
      map(([currentUser, labels, editedUserRole]) => {
        const data: SelectItem[] = []
        if (editedUserRole === UserRole.TSPOwner) {
          data.push({ label: labels[UserRole.TSPOwner], value: UserRole.TSPOwner })
        }
        if (currentUser.roleId === UserRole.TSPOwner || editedUserRole === UserRole.TSPAdmin) {
          data.push({ label: labels[UserRole.TSPAdmin], value: UserRole.TSPAdmin })
        }
        if (currentUser.roleId === UserRole.TSPOwner || editedUserRole === UserRole.TSPFinance) {
          data.push({ label: labels[UserRole.TSPFinance], value: UserRole.TSPFinance })
        }
        return data.concat([
          { label: labels[UserRole.TSPSupport], value: UserRole.TSPSupport },
          { label: labels[UserRole.TSPViewer], value: UserRole.TSPViewer },
        ])
      }),
    )
  }

  @Memoize()
  get fleetRoleOptions$(): Observable<SelectItem[]> {
    return combineLatest([
      this.profile.state,
      this.userOptionsProvider.userRoleLabels$,
      this.editedUserRole$,
    ]).pipe(
      takeUntil(this.destroy$),
      map(([currentUser, labels, editedUserRole]) => {
        const data: SelectItem[] = []
        if (editedUserRole === UserRole.FleetOwner) {
          data.push({ label: labels[UserRole.FleetOwner], value: UserRole.FleetOwner })
        }
        if (
          editedUserRole === UserRole.FleetAdmin ||
          currentUser.roleId === UserRole.FleetOwner ||
          currentUser.roleId === UserRole.FleetDemo
        ) {
          data.push({ label: labels[UserRole.FleetAdmin], value: UserRole.FleetAdmin })
        }
        return data.concat([
          // The FleetInstaller role is disabled due to Sergei comment in #86c0ygt02.
          // { label: labels[UserRole.FleetInstaller], value: UserRole.FleetInstaller },
          { label: labels[UserRole.FleetViewer], value: UserRole.FleetViewer },
          // Disabled until Standard release
          // { label: labels[UserRole.FleetDispatcher], value: UserRole.FleetDispatcher },
          // { label: labels[UserRole.FleetFinance], value: UserRole.FleetFinance },
          // { label: labels[UserRole.FleetMaintenance], value: UserRole.FleetMaintenance },
        ])
      }),
    )
  }

  protected readonly form = new FormGroup({
    id: new FormControl<string>(''),
    name: new FormControl<string>('', [Validators.required]),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    phoneNumber: new FormControl<string>('', []),
    roleId: new FormControl<number | undefined>(undefined, [Validators.required]),
  })
  protected readonly UserRole = UserRole

  public ngOnInit() {
    // Role is not editable for TSP or fleet Owners
    if (this.data.roleId === UserRole.TSPOwner || this.data.roleId === UserRole.FleetOwner) {
      this.form.controls.roleId.disable()
    }

    this.form.setValue({
      id: this.data.id || '',
      name: this.data.name || '',
      email: this.data.email || '',
      phoneNumber: this.data.phoneNumber || '',
      roleId: this.data.roleId || null,
    })
  }

  public cancel() {
    this.form.reset()
    this.cancelForm.emit()
  }

  protected onFormSubmit() {
    if (!this.form.valid) {
      return this.form.markAllAsTouched()
    }

    this.submitForm.emit(this.form.value)
  }
}
