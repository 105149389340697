<div
  class="switcher-wrapper"
  [class]="{
    'desktop-view': deviceService.isFullHDBreakPoint$ | async,
  }"
>
  <p-selectButton
    styleClass="view-switcher"
    [(ngModel)]="_value"
    (ngModelChange)="onValueChange($event)"
    [options]="options"
    optionValue="value"
    optionLabel="label"
  >
    <ng-template let-item pTemplate="item">
      <div class="p-button-label switch-item">
        <div class="icon">
          <ng-container [ngComponentOutlet]="item.iconComponent"></ng-container>
        </div>
        <div class="label text-14">{{ item.label | translate }}</div>
      </div>
    </ng-template>
  </p-selectButton>
</div>
