<p-button
  [class.btn-compact]="mode === 'compact' && !filtersCount"
  [class.p--10]="mode === 'mobile' || mode === 'compact'"
  [class.p--16]="mode === 'desktop'"
  [outlined]="mode === 'mobile'"
  [severity]="mode === 'mobile' ? 'primary' : 'secondary'"
  badge="{{ filtersCount || undefined }}"
  class="flat text-14 fw-600 h--40"
  icon="{{ mode === 'mobile' && !showText && !filtersCount ? 'pi' : '' }}"
>
  @if (sortingApplied) {
    <app-icon-filters-active
      [height]="20"
      [width]="20"
      class="filters icon"
      [class.mr-0]="mode !== 'desktop' && !showText"
    ></app-icon-filters-active>
  } @else {
    <app-icon-filters
      [height]="20"
      [width]="20"
      class="filters icon"
      [class.mr-0]="mode !== 'desktop' && !showText"
    ></app-icon-filters>
  }

  @if (mode === 'desktop' || showText) {
    {{ 'button.filters' | translate }}
  }
</p-button>
