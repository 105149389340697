import { inject, Injectable } from '@angular/core'
import { ResolveEnd, Router } from '@angular/router'
import { injectDestroy$ } from '@ti-platform/web/common'
import { BehaviorSubject, filter, takeUntil } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class NavigationHistoryProvider {
  public readonly currentUrl$ = new BehaviorSubject<string | null>(null)
  public readonly previousUrl$ = new BehaviorSubject<string | null>(null)
  public readonly currentUrlParts$ = new BehaviorSubject<string[] | null>(null)
  public readonly previousUrlParts$ = new BehaviorSubject<string[] | null>(null)
  protected readonly destroy$ = injectDestroy$()
  protected readonly router = inject(Router)
  private history: string[] = []

  constructor() {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof ResolveEnd),
      )
      .subscribe((event) => {
        if (event instanceof ResolveEnd) {
          this.addToHistory(event.urlAfterRedirects)
        }
      })

    // Initialize history with current URL (delayed to avoid race conditions)
    setTimeout(() => this.addToHistory(this.router.url), 250)
  }

  // Return previous url and remove from history
  popPreviousUrl(): string | null {
    if (this.history.length > 1) {
      // Remove current URL and return the previous one
      this.history.pop()
      const prevUrl = this.history[this.history.length - 1] ?? null
      this.previousUrl$.next(this.history.length > 1 ? this.history[this.history.length - 2] : null)
      this.currentUrl$.next(prevUrl)

      this.previousUrlParts$.next(
        this.history.length > 1 ? this.history[this.history.length - 2].split('/') : null,
      )
      this.currentUrlParts$.next(prevUrl ? prevUrl.split('/') : null)

      return prevUrl
    }
    return null
  }

  private addToHistory(url: string) {
    const formattedUrl = url.startsWith('/') ? url.substring(1) : url
    const formattedParts = formattedUrl.split('/')

    // Prevent duplicate consecutive URLs
    if (this.history.length > 0 && this.history[this.history.length - 1] === formattedUrl) {
      return
    }

    this.history.push(formattedUrl)

    // Update observables
    this.previousUrl$.next(this.history.length > 1 ? this.history[this.history.length - 2] : null)
    this.currentUrl$.next(formattedUrl)

    this.previousUrlParts$.next(
      this.history.length > 1 ? this.history[this.history.length - 2].split('/') : null,
    )
    this.currentUrlParts$.next(formattedParts)
  }

  async goTo(defaultRoute: string[], trackHistory = true) {
    const navigationChange = await this.router.navigate(
      defaultRoute?.length ? defaultRoute : ['..'],
    )
    if (navigationChange && !trackHistory) {
      // remove last url
      this.popPreviousUrl()
    }
    return navigationChange
  }
}
