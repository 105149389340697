import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-bookmark-media-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.333 8.58097L16.229 6.79584C16.4861 6.63735 16.8088 6.63036 17.0726 6.77759C17.3363 6.92481 17.4997 7.2032 17.4997 7.50523V13.3359C17.4997 13.6379 17.3363 13.9163 17.0726 14.0635C16.8089 14.2107 16.4862 14.2038 16.2291 14.0453L13.333 12.2601"
        fill="#E9D7FE"
        style="fill:#E9D7FE;fill:color(display-p3 0.9137 0.8431 0.9961);fill-opacity:1;"
      />
      <path
        d="M13.333 8.58097L16.229 6.79584C16.4861 6.63735 16.8088 6.63036 17.0726 6.77759C17.3363 6.92481 17.4997 7.2032 17.4997 7.50523V13.3359C17.4997 13.6379 17.3363 13.9163 17.0726 14.0635C16.8089 14.2107 16.4862 14.2038 16.2291 14.0453L13.333 12.2601"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="2.5"
        y="5"
        width="10.8333"
        height="10.8333"
        rx="3"
        fill="#E9D7FE"
        [attr.stroke]="color"
        style="fill:#E9D7FE;fill:color(display-p3 0.9137 0.8431 0.9961);fill-opacity:1;stroke:#7F56D9;stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: start;
        justify-content: center;
        cursor: pointer;
      }
    `,
  ],
})
export class BookmarkMediaIconComponent extends BaseIconComponent {
  @Input() override color = '#7F56D9'
}
