<app-grid-controls
  (searchChanged)="model.setSearch($event)"
  (sortChanged)="model.setOrder($event)"
  [actionButtonsTemplate]="actionButtons"
  [columns]="state.columns$ | async"
  [returnButtonTemplate]="returnButton"
  [extraControlsTemplate]="extraControls"
  [sortOrder]="state.gridSortOrder$ | async"
  [defaultSortOrder]="state.defaultSortOrder$ | async"
  [showSearch]="true"
  [showMobileMenuButton]="false"
  titleText="tsp-admin.devices.media-usage.title"
  [descriptionText]="'tsp-admin.devices.media-usage.description' | translate"
>
</app-grid-controls>

<app-tsp-media-usage-grid></app-tsp-media-usage-grid>

<ng-template #extraControls>
  <app-export-btn
    [compact]="(deviceService.isMobile$ | async) || false"
    (export)="model.exportReport($event)"
    class="export-button"
  ></app-export-btn>
</ng-template>

<ng-template #actionButtons let-isMobile="isMobile">
  @if (isMobile) {
    <ng-container [ngTemplateOutlet]="extraControls"></ng-container>
  }
  <app-month-range-select
    [includeCurrentMonth]="true"
    [useUTC]="true"
    [value]="state.dateRange$ | async"
    (monthRangeSelected)="model.setDateRange($event)"
  ></app-month-range-select>
</ng-template>

<ng-template #returnButton>
  <p-button (click)="goBack()" class="button-back h--48 flex" icon="pi" [severity]="'secondary'">
    <app-icon-arrow [height]="24" [width]="24" direction="left"></app-icon-arrow>
  </p-button>
</ng-template>
