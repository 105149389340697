@if (style === 'filter') {
  <app-filter-button
    [filtersCount]="filtersCount"
    [sortingApplied]="
      defaultSortOrder?.order !== gridSortOrder?.order ||
      defaultSortOrder?.column !== gridSortOrder?.column
    "
    (click)="filterPanelService.openPanel()"
    [mode]="'mobile'"
  ></app-filter-button>
}
