import { DataQueryProps, MediaLibraryItem, VideoQualityStreamtype } from '@ti-platform/contracts'
import { DateRange } from '@ti-platform/web/common'
import { ApiRoute } from '../../../services/api.route'
import { prepareQueryOptions } from '../../../utils'

export class MediaRoute extends ApiRoute {
  public override init() {
    this.route = 'fleet/media'
  }

  public list(options: DataQueryProps) {
    return this.get<MediaLibraryItem[]>().withParams(prepareQueryOptions(options)).endpoint('list')
  }

  public countAll() {
    return this.get<number>().endpoint('count')
  }

  public updateMediaFileGroup(groupId: string, data: { isNew?: boolean; isLocked?: boolean }) {
    return this.post<void>().withData(data).endpoint(`update/file-group/${groupId}`)
  }

  public startLiveStream() {
    //
  }

  public takeRealTimePhoto() {
    //
  }

  public createVideoRequest(data: VideoRequestData) {
    return this.post()
      .withData({ ...data })
      .endpoint(`video-request`)
  }

  public deleteMediaGroupById(groupId: string) {
    return this.delete().endpoint(`media-group/${groupId}/delete`)
  }

  public retryFailedByGroupId(groupId: string) {
    return this.put().endpoint(`request-group/${groupId}/retry-failed`)
  }

  public removeFailedByGroupId(groupId: string) {
    return this.delete().endpoint(`request-group/${groupId}/delete-failed`)
  }

  public cancelProcessingRequestByGroupId(groupId: string) {
    return this.delete().endpoint(`request-group/${groupId}/cancel-processing`)
  }

  public getVehicleTimeline(vehicleId: string, dateRange: DateRange) {
    return this.get<VideoTimelineResponseItem[]>()
      .withParams({
        beginSecTimestamp: (dateRange[0].valueOf() / 1000).toFixed(0),
        endSecTimestamp: (dateRange[1].valueOf() / 1000).toFixed(0),
      })
      .endpoint(`vehicle/${vehicleId}/video-timeline`)
  }

  public getVehicleConnectionStatus(vehicleId: string) {
    return this.get().endpoint(`vehicle/${vehicleId}/connection-status`)
  }

  public deleteVehicleConnections() {
    //
  }
}

export interface VideoTimelineResponseItem {
  begin: number
  channel: number
  end: number
  filetype: 'video'
  locked: boolean
  size: number
  streamtype: 'main'
}

export interface VideoRequestData {
  vehicleId: string
  channels: number[]
  beginSecTimestamp: number
  endSecTimestamp: number
  streamtype?: VideoQualityStreamtype
  latitude?: number
  longitude?: number
  address?: string
  locationIsAccurate?: boolean
  triggeredAlertId?: string
}
