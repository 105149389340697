import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core'
import { SelectItem } from 'primeng/api'

@Component({
  selector: 'app-filter-list',
  templateUrl: 'filter-list.component.html',
  styleUrls: ['filter-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterListComponent implements OnChanges {
  @Output() readonly selectedItemsChange = new EventEmitter<Set<any>>()

  @Input() items?: SelectItem[]
  @Input() selectedItems: Set<any> = new Set()

  protected readonly searchTerm = signal<string>('')
  protected readonly _items = signal<SelectItem[]>([])
  protected readonly _selectedItems = signal(new Set<any>())
  protected readonly showMoreClicked = signal(false)

  protected readonly selectAllCheckboxValue = computed(() => {
    const filteredItems = this.filteredItems()
    const selectedItems = this._selectedItems()
    const selectedFilteredItems = filteredItems.filter((item) => selectedItems.has(item.value))
    return filteredItems.length > 0 && filteredItems.length === selectedFilteredItems.length
  })

  protected readonly isSearchEnabled = computed(() => this._items().length > 5)

  protected readonly isSelectAllCheckboxEnabled = computed(
    () => this.isSearchEnabled() && this.searchTerm().length > 0 && this.filteredItems().length > 0,
  )

  protected readonly filteredItems = computed(() =>
    this._items().filter((item) =>
      item.label?.toLowerCase().includes(this.searchTerm().toLowerCase()),
    ),
  )

  protected readonly visibleItems = computed(() => {
    return this.showMoreClicked() ? this.filteredItems() : this.filteredItems().slice(0, 10)
  })

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this._items.set(changes.items.currentValue)
    }

    if (changes.selectedItems) {
      if (changes.selectedItems.currentValue === undefined) {
        this.selectedItems = new Set()
      }
      this._selectedItems.set(this.selectedItems)
    }
  }

  protected toggleSelectAll() {
    const currentState = this.selectAllCheckboxValue()
    this.filteredItems().forEach((item) => {
      if (!currentState) {
        this.selectedItems.add(item.value)
      } else {
        this.selectedItems.delete(item.value)
      }
    })
    this.selectedItemsChange.emit(new Set(this.selectedItems))
  }

  protected onItemSelect(value: any) {
    if (this.selectedItems && this.selectedItems.has(value)) {
      this.selectedItems.delete(value)
    } else {
      this.selectedItems.add(value)
    }
    this.selectedItemsChange.emit(new Set(this.selectedItems))
  }
}
