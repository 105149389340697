import { inject, Injectable } from '@angular/core'
import { BehaviorSubject, debounceTime, filter, takeUntil, tap, withLatestFrom } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { Vehicle } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { TripHistoryItem } from '@ti-platform/web/api/scopes/fleet/routes/vehicles.route'
import { DateRange, injectDestroy$ } from '@ti-platform/web/common'
import { UITripHistoryData } from '../contracts'

@Injectable()
export class TripsListModel {
  // TODO: Rework to store / state approach
  public readonly loadedTrips$ = new BehaviorSubject<TripHistoryItem[]>([])
  public readonly areTripsLoading$ = new BehaviorSubject<boolean>(true)
  public readonly canLoadMoreTrips$ = new BehaviorSubject<boolean>(true)
  public readonly selectedTrip$ = new BehaviorSubject<UITripHistoryData | null>(null)

  protected readonly api = inject(ApiService)
  protected readonly destroy$ = injectDestroy$()

  protected readonly loadNextPage$ = new BehaviorSubject<void>(undefined)
  protected readonly currentVehicle$ = new BehaviorSubject<Vehicle | undefined>(undefined)
  protected readonly appliedDateRange$ = new BehaviorSubject<DateRange | undefined>(undefined)
  protected readonly tripsPageSize$ = new BehaviorSubject<number>(10)
  protected readonly initialPageSize$ = new BehaviorSubject<number>(5)

  public constructor() {
    Promise.resolve()
      .then(() => this.init())
      .catch((error) => console.warn(`Cannot initialize TripsListModel`, error))
  }

  public setVehicle(vehicle: Vehicle) {
    this.currentVehicle$.next(vehicle)
    this.loadedTrips$.next([])
    return this.loadTripsNextPage()
  }

  public setDateRange(range: DateRange) {
    this.appliedDateRange$.next(range)
    this.loadedTrips$.next([])
    return this.loadTripsNextPage()
  }

  public selectTrip(trip: UITripHistoryData) {
    const selected = this.selectedTrip$.getValue()
    if (trip.id === selected?.id) {
      this.selectedTrip$.next(null)
    } else {
      this.selectedTrip$.next(trip)
    }
  }

  public loadTripsNextPage() {
    this.loadNextPage$.next()
  }

  public setPageSize(pageSize: number, initialPageSize?: number) {
    this.tripsPageSize$.next(pageSize)
    this.initialPageSize$.next(initialPageSize ?? pageSize)
  }

  protected init() {
    this.loadNextPage$
      .pipe(
        debounceTime(256),
        withLatestFrom(this.loadedTrips$, this.currentVehicle$, this.appliedDateRange$),
        filter(([, , currentVehicle]) => !!currentVehicle),
        tap(() => this.areTripsLoading$.next(true)),
        switchMap(async ([, loadedTrips, currentVehicle, appliedDateRange]) => {
          const pageSize = loadedTrips.length
            ? this.tripsPageSize$.getValue()
            : this.initialPageSize$.getValue()

          const nextTrips = await this.api.fleet.vehicle.timeline(
            (currentVehicle as Vehicle).primaryExternalDeviceId,
            {
              limit: pageSize,
              timestampTo: loadedTrips.length
                ? loadedTrips[loadedTrips.length - 1].begin
                : Math.round(
                    (appliedDateRange ? appliedDateRange[1].valueOf() : Date.now()) / 1000,
                  ),
              timestampFrom: appliedDateRange
                ? Math.round(appliedDateRange[0].valueOf() / 1000)
                : undefined,
            },
          )

          if (nextTrips.length < pageSize) {
            this.canLoadMoreTrips$.next(false)
          }

          const tripIds = loadedTrips.map((t) => t.id)
          return [...loadedTrips, ...nextTrips.filter((t) => !tripIds.includes(t.id))]
        }),
        tap(() => this.areTripsLoading$.next(false)),
        takeUntil(this.destroy$),
      )
      .subscribe((totalTrips) => this.loadedTrips$.next(totalTrips))
  }
}
