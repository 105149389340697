export enum ApiErrors {
  unknown = 'unknown',
  accessDenied = 'access-denied',
  authUserInviteTimeout = 'auth-user-invite-timeout',
  authUserInviteExpired = 'auth-user-invite-expired',
  authUserPasswordInvalid = 'auth-user-password-invalid',
  authResetPasswordExpired = 'auth-reset-password-expired',
  authResetPasswordTimeout = 'auth-reset-password-timeout',
  authUserWithSameEmailExists = 'auth-user-with-same-email-exists',
  suchIdentifierAndProtocolAlreadyExists = 'such-identifier-and-protocol-already-exists',
  demoFleetLimitDevicesReached = 'demo-fleet-limit-devices-reached',
  identifierNotMatchPattern = 'identifier-not-match-pattern',
  simNotMatchPattern = 'sim-not-match-pattern',
  deviceNotFound = 'device-not-found',
  deviceConnectedToAnotherTsp = 'device-connected-to-another-tsp',
  fleetNotFound = 'fleet-not-found',
  fleetConnectedToAnotherTsp = 'fleet-connected-to-another-tsp',
  videoDevicesNeedsVideoChannels = 'video-devices-needs-video-channels',
  onlyVideoDeviceMayHaveVideoChannels = 'only-video-device-may-have-video-channels',
  identifierRequiredOnChangeDeviceType = 'identifier-required-on-change-device-type',
  invalidCommandConfigViaSchema = 'invalid-command-config-via-schema',
  deviceIsNotAssigned = 'device-is-not-assigned',
  deviceIsAlreadyAssigned = 'device-is-already-assigned',
  mediaConfigStorageCapacityLittleForLockedFiles = 'media-config-storage-capacity-little-for-locked-files',
  livestreamDurationIsExceeded = 'live-stream-duration-is-exceed',
  playbackVideoDurationIsExceeded = 'playback-video-duration-is-exceeded',
  playbackVideoDidNotStart = 'playback-video-did-not-start',
}
