<div class="table-wrapper" [ngClass]="{ 'flex-grow-1': (state.items$ | async)?.length === 0 }">
  <app-data-grid
    [data]="(state.items$ | async) ?? []"
    [columns]="(state.columns$ | async) ?? []"
    [sortOrder]="(state.gridSortOrder$ | async) || undefined"
    [isLoading]="(state.isLoading$ | async) ?? false"
    [paginationEnabled]="(state.canLoadNextPage$ | async) ?? false"
    [noDataTitle]="noDataTitleKey | translate"
    [noDataDescription]="noDataDescriptionKey | translate"
    [noDataIconTemplate]="noDataIconTmpl"
    (loadMoreClicked)="model.loadNextPage()"
    (sortUpdated)="model.setOrder($event)"
    [templates]="{
      video_channels_number: camerasTmpl,
      media_files_size_gb: mediaStorageTmpl,
      media_traffic_gb: mediaTrafficTmpl,
      media_stream_duration_seconds: liveStreamDurationTmpl,
      media_stream_traffic_gb: liveStreamTrafficTmpl,
    }"
  ></app-data-grid>
</div>

<ng-template #noDataIconTmpl>
  @if (!!model.isActiveSearch) {
    <app-icon-no-search [width]="32" [height]="32"></app-icon-no-search>
  } @else {
    <app-icon-no-devices></app-icon-no-devices>
  }
</ng-template>

<ng-template #camerasTmpl let-row="row">
  <div class="align-right">
    {{ row.video_channels_number }}
  </div>
</ng-template>

<ng-template #mediaStorageTmpl let-row="row">
  <div class="align-right">
    <span class="base-font text-14 fw-400">
      {{ model.formatGBValue(row.media_files_size_gb) }} /
      {{ row.media_config?.media_storage_max_size_gb || 0 }}
    </span>
    <span class="base-font text-14 fw-400 gb-measure">&nbsp;GB</span>
  </div>
</ng-template>

<ng-template #mediaTrafficTmpl let-row="row">
  <div class="align-right">
    <span class="base-font text-14 fw-400">{{ model.formatGBValue(row.media_traffic_gb) }}</span>
    <span class="base-font text-14 fw-400 gb-measure">&nbsp;GB</span>
  </div>
</ng-template>

<ng-template #liveStreamDurationTmpl let-row="row">
  <span class="base-font text-14 fw-400">
    {{ model.formatSecDurationToMin(row.media_stream_duration_seconds) }} min /
    @if (row.media_config?.is_unlimited_livestream) {
      {{ 'tsp-admin.devices.media-usage.unlimited' | translate }}
    } @else {
      {{ model.formatSecDurationToMin(row.media_config?.max_livestream_duration) }} min
    }
  </span>
</ng-template>

<ng-template #liveStreamTrafficTmpl let-row="row">
  <div class="align-right">
    <span class="base-font text-14 fw-400">{{
      model.formatGBValue(row.media_stream_traffic_gb)
    }}</span>
    <span class="base-font text-14 fw-400 gb-measure">&nbsp;GB</span>
  </div>
</ng-template>
