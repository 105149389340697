import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-pause',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.08334 5.625V14.375"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9167 5.625V14.375"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class PauseIconComponent extends BaseIconComponent {
  @Input() override height = 20
  @Input() override width = 20
  @Input() override color = 'var(--color-gray-0)'
}
