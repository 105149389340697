import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { BookmarkType } from '@ti-platform/contracts'

@Component({
  selector: 'app-fleet-bookmarks-type-icon',
  templateUrl: 'bookmarks-type-icon.component.html',
  styleUrls: ['bookmarks-type-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookmarksTypeIconComponent {
  BookmarkType = BookmarkType
  @Input() type: BookmarkType | null = null
}
