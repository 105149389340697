@switch (type) {
  @case (BookmarkType.VehicleDetails) {
    <app-bookmark-vehicle-icon [width]="20" [height]="20"></app-bookmark-vehicle-icon>
  }
  @case (BookmarkType.MediaGroup) {
    <app-bookmark-media-icon [width]="20" [height]="20"></app-bookmark-media-icon>
  }
  @case (BookmarkType.TriggeredAlert) {
    <app-bookmark-alert-icon [width]="20" [height]="20"></app-bookmark-alert-icon>
  }
  @case (BookmarkType.Report) {
    <app-bookmark-report-icon [width]="20" [height]="20"></app-bookmark-report-icon>
  }
  @default {
    <app-bookmark-icon [width]="20" [height]="20"></app-bookmark-icon>
  }
}
