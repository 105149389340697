import { VideoQualityStreamtype } from '../media'
import { Channel } from './channel'
import { DeviceType } from './device-type'
import { Fleet } from './fleet'
import { UserProfile } from './user-profile'
import { Vehicle } from './vehicle'

export interface Device {
  id: string
  name: string
  model: string
  pricingPlanId: number
  isVideo?: boolean
  videoChannelsNumber?: number
  identifier: string
  config: DeviceConfig
  sensorsConfig: DeviceSensorsConfig
  mediaConfig: DeviceMediaConfig
  sim: string
  status: DeviceStatus
  createdAt: Date
  fleetId: number
  tspId: number
  externalId: number
  externalDeviceTypeId: number
  channelId: number
  createdBy: string
  channel?: Channel
  fleet?: Fleet
  vehicle?: Vehicle
  creator?: UserProfile
  deviceType?: DeviceType
}

export enum DeviceStatus {
  Unassigned = 0,
  Inactive = 1,
  Active = 2,
  Deleted = 3,
}

export type DeviceCamera = {
  name: string
  channel: number
}

export type DeviceConfig = {
  ident?: string
  phone?: string
  password?: string
  settings_polling?: string
  cameras?: Array<DeviceCamera>
  ftp_password?: string
}

export enum OdometerSourceTypes {
  telemetry = 'telemetry',
  calculator = 'calculator',
}

export interface DeviceSensorsConfig {
  odometer_source_type?: OdometerSourceTypes
  odometer_parameter?: string
  battery_parameter?: string
  ignition_parameter?: string
  // km
  initial_mileage?: number
}

export type DeviceMediaConfig = {
  cameras: Array<DeviceCamera>
  default_video_quality: VideoQualityStreamtype
  video_quality_selectable: boolean
  // GB
  media_storage_max_size_gb: number
  // seconds (5 - 300)
  max_clip_duration: number
  is_unlimited_livestream: boolean
  // seconds
  max_livestream_duration?: number
  // seconds
  livestream_timeout: number
  with_audio: boolean
  // was added later if underfined then - true
  is_unlimited_playback?: boolean
  // seconds
  max_playback_duration?: number
}
