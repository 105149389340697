@if (bookmark) {
  <p-panel class="panel-primary w-380 header-24 content-24 border-top">
    <ng-template pTemplate="header">
      <header class="header">
        <div class="mb-16">
          <svg
            width="49"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.5 6.0006C35.0253 5.47532 35.6489 5.05864 36.3352 4.77435C37.0215 4.49007 37.7571 4.34375 38.5 4.34375C39.2429 4.34375 39.9785 4.49007 40.6648 4.77435C41.3511 5.05864 41.9747 5.47532 42.5 6.0006C43.0253 6.52589 43.442 7.1495 43.7263 7.83582C44.0105 8.52214 44.1569 9.25774 44.1569 10.0006C44.1569 10.7435 44.0105 11.4791 43.7263 12.1654C43.442 12.8517 43.0253 13.4753 42.5 14.0006L15.5 41.0006L4.5 44.0006L7.5 33.0006L34.5 6.0006Z"
              stroke="#7F56D9"
              style="
                stroke: #7f56d9;
                stroke: color(display-p3 0.4964 0.3384 0.8498);
                stroke-opacity: 1;
              "
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="title center text-18 fw-600 mb-12">
          {{ 'bookmarks.actions.rename-title' | translate }}
        </div>
        <div class="subtitle center text-14 mb-16">
          {{ 'bookmarks.actions.rename-description' | translate }}
        </div>

        <div class="field small">
          <input
            [(ngModel)]="bookmark.name"
            pInputText
            placeholder=""
            type="text"
            [value]="bookmark.name"
          />
        </div>
      </header>
    </ng-template>
    <div class="content" [class]="{ reversed: !!reverseButtons }">
      <p-button
        [severity]="'secondary'"
        class="cancel text-14 fw-600 h--40 w-grow"
        (onClick)="dynamicDialogRef.close(false)"
        >{{ cancelButton }}
      </p-button>
      <p-button
        class="delete text-14 fw-600 h--40 w-grow"
        [ngStyle]="{
          '--p-button__hover-bg-color': cancelButtonColor,
          '--p-button__hover-border-color': cancelButtonColor,
          '--p-button__active-bg-color': cancelButtonColor,
          '--p-button__active-border-color': cancelButtonColor,
          '--p-button__bg-color': cancelButtonColor,
          '--p-button__border-color': cancelButtonColor,
        }"
        (onClick)="save()"
      >
        {{ 'button.save' | translate }}
      </p-button>
    </div>
  </p-panel>
}
