import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-bookmark-vehicle-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.5 12.9141V10.6186C2.5 9.95498 2.76347 9.31851 3.2325 8.84906L4.16667 7.91406H15.8333L16.7675 8.84823C17.2365 9.31724 17.5 9.95336 17.5 10.6166V12.9141C17.5 13.8345 16.7538 14.5807 15.8333 14.5807H4.16667C3.24619 14.5807 2.5 13.8345 2.5 12.9141Z"
        fill="#E9D7FE"
        [attr.stroke]="color"
        style="fill:#E9D7FE;fill:color(display-p3 0.9137 0.8431 0.9961);fill-opacity:1;stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5003 12.9141V15.8307C17.5003 16.291 17.1272 16.6641 16.667 16.6641H15.0003C14.5401 16.6641 14.167 16.291 14.167 15.8307V14.5807"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83333 14.5807V15.8307C5.83333 16.291 5.46024 16.6641 5 16.6641H3.33333C2.8731 16.6641 2.5 16.291 2.5 15.8307V12.9141"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.4997 7.08333L15.833 7.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.16667 7.5L2.5 7.08333"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.16699 7.91927V7.4976L4.86366 4.88344C5.10683 3.97091 5.9332 3.33592 6.87758 3.33594H13.1964C14.1608 3.33594 14.9991 3.99785 15.2228 4.93594L15.8337 7.4976V7.91927"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 11.2526H6.66667"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.333 11.2526H14.9997"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: start;
        justify-content: center;
        cursor: pointer;
      }
    `,
  ],
})
export class BookmarkVehicleIconComponent extends BaseIconComponent {
  @Input() override color = '#7F56D9'
}
