import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { Action, Resource } from '@ti-platform/contracts'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { injectDestroy$, selectState } from '@ti-platform/web/common'
import { BookmarksListModel } from '../models'

@Component({
  selector: 'app-fleet-bookmarks-page',
  templateUrl: 'bookmarks-page.component.html',
  styleUrls: ['bookmarks-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BookmarksListModel],
})
export class BookmarksPageComponent {
  protected readonly model = inject(BookmarksListModel)
  protected readonly state = selectState(BookmarksListModel)
  protected readonly deviceService = inject(DeviceService)

  protected readonly destroy$ = injectDestroy$()

  protected readonly Action = Action
  protected readonly Resource = Resource
}
