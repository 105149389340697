import { BaseUserRoute } from '@ti-platform/web/api/scopes/common/base-user.route'
import { DataQueryProps, PAGE_SIZE, UserProfile } from '@ti-platform/contracts'
import { stripEmptyKeys } from '@ti-platform/web/common'

export class UserRoute extends BaseUserRoute {
  public override init() {
    this.route = 'ti/user'
  }

  public listTsp(options: DataQueryProps) {
    return this.get<UserProfile[]>()
      .withParams(
        stripEmptyKeys({
          page: `${options.page}`,
          pageSize: `${options.pageSize ?? PAGE_SIZE}`,
          search: options.search ?? '',
          order: options.order,
          orderBy: options.orderBy,
        }),
      )
      .endpoint('list-tsp')
  }

  public countTsp(options: { search?: string }) {
    return this.get<number>().withParams(stripEmptyKeys(options)).endpoint(`count-tsp`)
  }

  public initLoginAsSession(idToken: string, targetUserId: string) {
    return this.post<{ sessionId: string }>()
      .withData({ idToken, targetUserId })
      .endpoint(`init-login-as-session`)
  }
}
