import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Fleet, FuelConsumptionType, MeasurementSystem, PricingPlans } from '@ti-platform/contracts'
import { CONFIG, injectDestroy$, Memoize } from '@ti-platform/web/common'
import {
  countryValidator,
  FormatOptionsProvider,
  getSettingsByCountryCode,
  LanguageService,
} from '@ti-platform/web/ui-kit/i18n'
import { cloneDeep, uniqBy } from 'lodash'
import { SelectItem } from 'primeng/api'
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete'
import { BehaviorSubject, combineLatest, map, Observable, takeUntil } from 'rxjs'
import { CountyOptionsProvider, PricingPlansProvider } from '../../models'

@Component({
  selector: 'app-tsp-admin-fleet-form',
  templateUrl: 'fleet-form.component.html',
  styleUrl: 'fleet-form.component.scss',
})
export class TspAdminFleetFormComponent implements OnInit, OnChanges {
  @Output() cancelEvent = new EventEmitter<void>()
  @Output() submitEvent = new EventEmitter()

  @Input() processing = false
  @Input() errorMessage = ''

  @ViewChild('errorBlock') errorBlock!: ElementRef

  protected readonly config = inject(CONFIG)
  protected readonly languageService = inject(LanguageService)
  protected readonly changeDetector = inject(ChangeDetectorRef)
  protected readonly formatOptionsProvider = inject(FormatOptionsProvider)
  protected readonly destroy$ = injectDestroy$()
  protected readonly pricingPlansProvider = inject(PricingPlansProvider)
  protected readonly countyOptionsProvider = inject(CountyOptionsProvider)

  protected isLoading = false
  protected modelWasChanged = false
  protected disableMode$ = new BehaviorSubject<boolean>(false)

  protected readonly countrySearch$ = new BehaviorSubject<string | null>('')
  protected readonly currentPricingPlan$ = new BehaviorSubject<PricingPlans | null>(null)

  protected readonly fleetEditForm = new FormGroup({
    name: new FormControl<string>('', [Validators.required]),
    contactName: new FormControl<string>('', [Validators.required]),
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    phone: new FormControl<string>(''),
    country: new FormControl<string>('', [Validators.required, countryValidator]),
    address: new FormControl<string>(''),
    city: new FormControl<string>(''),
    region: new FormControl<string>(''),
    zipCode: new FormControl<string>(''),
    distance: new FormControl<MeasurementSystem>(MeasurementSystem.Imperial),
    volume: new FormControl<MeasurementSystem>(MeasurementSystem.Imperial),
    consumption: new FormControl<FuelConsumptionType>(FuelConsumptionType.MPG),
    temperature: new FormControl<MeasurementSystem>(MeasurementSystem.Imperial),
    pricingPlanId: new FormControl<PricingPlans>(PricingPlans.TnT),
    isDemo: new FormControl<boolean>(false),
  })

  protected _fleet: Partial<Fleet> = {}

  get fleet(): Partial<Fleet> {
    return this._fleet
  }

  @Input()
  set fleet(value: Partial<Fleet>) {
    this._fleet = value
    // set start values

    const pricingPlanId = this.fleet.pricingPlanId ?? PricingPlans.TnT

    this.fleetEditForm.setValue({
      name: this.fleet.name || '',
      contactName: this.fleet.contactName || '',
      email: this.fleet.email || '',
      phone: this.fleet.phone || '',
      country: this.fleet.country || '',
      address: this.fleet.address || '',
      city: this.fleet.city || '',
      region: this.fleet.region || '',
      zipCode: this.fleet.zipCode || '',
      distance: this.fleet.measurementSystem?.distance ?? MeasurementSystem.Imperial,
      volume: this.fleet.measurementSystem?.volume ?? MeasurementSystem.Imperial,
      consumption: this.fleet.measurementSystem?.consumption ?? FuelConsumptionType.MPG,
      temperature: this.fleet.measurementSystem?.temperature ?? MeasurementSystem.Imperial,
      pricingPlanId,
      isDemo: this.fleet.isDemo || false,
    })

    this.currentPricingPlan$.next(pricingPlanId)
    this.modelWasChanged = false
  }

  get newFleetMode() {
    return !this._fleet.id
  }

  get isValidForm() {
    return this.fleetEditForm.valid
  }

  @Memoize()
  get countryOptions$(): Observable<SelectItem[]> {
    return combineLatest([this.countyOptionsProvider.countryOptions$, this.countrySearch$]).pipe(
      takeUntil(this.destroy$),
      map(([options, search]) => {
        let data = cloneDeep(options)
        if (search) {
          data = data.filter((value) => value.label?.toLowerCase()?.includes(search.toLowerCase()))
        }
        return data
      }),
    )
  }

  protected onCountrySearch(event: AutoCompleteCompleteEvent) {
    const query = event.query
    this.countrySearch$.next(query ?? '')
  }

  @Memoize()
  get pricingPlanOptions$(): Observable<SelectItem[]> {
    return combineLatest([
      this.pricingPlansProvider.pricingPlansLabels$,
      this.currentPricingPlan$,
    ]).pipe(
      takeUntil(this.destroy$),
      map(([labels, currentPricingPlan]) => {
        const data: SelectItem[] = []
        if (
          currentPricingPlan !== null &&
          currentPricingPlan !== undefined &&
          currentPricingPlan !== PricingPlans.TnT &&
          labels[currentPricingPlan]
        ) {
          data.push({ label: labels[currentPricingPlan], value: currentPricingPlan })
        }

        if (this.config.envType !== 'production') {
          data.push({ label: labels[PricingPlans.Advanced], value: PricingPlans.Advanced })
          data.push({ label: labels[PricingPlans.Premium], value: PricingPlans.Premium })
        }

        return uniqBy(
          [
            { label: labels[PricingPlans.TnT], value: PricingPlans.TnT },
            { label: labels[PricingPlans.Standard], value: PricingPlans.Standard },
            ...data,
          ],
          (item) => item.value,
        )
      }),
    )
  }

  async ngOnInit() {
    this.fleetEditForm.controls.country.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((country) => {
        if (country) {
          const settings = getSettingsByCountryCode(country)
          this.fleetEditForm.controls.distance.setValue(settings.measurementSystem.distance)
          this.fleetEditForm.controls.volume.setValue(settings.measurementSystem.volume)
          this.fleetEditForm.controls.consumption.setValue(settings.measurementSystem.consumption)
          this.fleetEditForm.controls.temperature.setValue(settings.measurementSystem.temperature)
        }
      })

    this.fleetEditForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.errorMessage = ''
      this.modelWasChanged = true
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['errorMessage'] && this.errorMessage) {
      this.changeDetector.detectChanges()
      this.focusOnErrorDiv()
    }
  }

  public onCancel() {
    if (!this.disableMode$.getValue()) {
      this.fleetEditForm.reset()
      this.cancelEvent.emit()
    }
  }

  protected async onSubmit() {
    if (!this.fleetEditForm.valid) {
      // this.errorMessage = await this.languageService.translate('common.please-check-entered-data')
      return this.fleetEditForm.markAllAsTouched()
    }
    this.submitEvent.emit(this.fleetEditForm.value)
  }

  private focusOnErrorDiv(): void {
    if (this.errorBlock) {
      this.errorBlock.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }
}
