import {
  EventEmitter,
  Component,
  Input,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
  inject,
  NgZone,
} from '@angular/core'
import { fromEvent, takeUntil } from 'rxjs'
import { createTextMaskInputElement } from 'text-mask-core'
import { injectDestroy$ } from '@ti-platform/web/common'
// TODO: Move duration constants to the global scope
import { ONE_MINUTE_MS } from '@ti-platform/web/video/contracts'

@Component({
  selector: 'app-duration-input',
  templateUrl: 'duration-input.component.html',
  styleUrls: ['duration-input.component.scss'],
})
export class DurationInputComponent implements AfterViewInit {
  @Output() durationChanged = new EventEmitter<number>()

  @ViewChild('durationInputRef')
  protected readonly durationInputRef?: ElementRef<HTMLInputElement>

  protected readonly ngZone = inject(NgZone)
  protected readonly destroy$ = injectDestroy$()

  protected _duration = '0:00'

  @Input() placeholderMask = 'min:sec'
  @Input() isSecondsSupported = false
  @Input() isOutlined = false
  @Input() invalid?: boolean = false
  @Input() maxDuration = 5 * ONE_MINUTE_MS

  @Input() set duration(milliseconds: number | undefined) {
    const value = this.convertMilliseconds(milliseconds ?? 0)
    this.updateDuration(value)
  }

  public ngAfterViewInit() {
    this.ngZone.runOutsideAngular(() => {
      if (this.durationInputRef?.nativeElement) {
        this.initDurationInputMask(this.durationInputRef.nativeElement)
      }
    })
  }

  protected updateDuration(value: { minutes: number; seconds: number }) {
    const formattedMinutes = value.minutes.toString()
    const formattedSeconds = value.seconds.toString().padStart(2, '0')
    this._duration = `${formattedMinutes}:${formattedSeconds}`
  }

  protected convertMilliseconds(ms: number) {
    const seconds = Math.floor((ms / 1000) % 60)
    const minutes = Math.floor(ms / (1000 * 60))
    return { minutes, seconds }
  }

  protected initDurationInputMask(durationInput: HTMLInputElement) {
    durationInput.value = this._duration
    durationInput.focus()
    durationInput.setSelectionRange(durationInput.value.length, durationInput.value.length)

    const textMask = createTextMaskInputElement({
      inputElement: durationInput,
      mask: [/[0-5]/, ':', /[0-5]/, /[0-9]/],
      guide: true,
      placeholderChar: '0',
    })

    fromEvent<KeyboardEvent>(durationInput, 'keydown')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        if (event.key === 'Enter') {
          return durationInput.blur()
        }

        if (/\d/.test(event.key)) {
          const caretPos = durationInput.selectionStart
          // Get the current digits by removing the colon.
          const currentDigits = durationInput.value.replace(':', '')
          /*
            If the field is empty (or not yet complete, i.e. fewer than 3 digits)
            OR the caret is at the very end (even if the field is complete),
            we handle the key so that the new digit is appended at the right.
          */
          if (
            currentDigits.length < 3 ||
            (caretPos !== null && caretPos === durationInput.value.length)
          ) {
            event.preventDefault()
            // Pad missing digits with zeros on the left.
            // We expect a total of 3 digits ([minute, second tens, second ones]).
            const padded = currentDigits.padStart(2, '0') // if empty => "00"
            // Append the new digit and take the last three characters.
            const newDigits = (padded + event.key).slice(-3)
            // Reassemble into the "M:SS" format.
            const newValue = (durationInput.value = `${newDigits[0]}:${newDigits.slice(1)}`)
            // Move the caret to the end.
            durationInput.setSelectionRange(durationInput.value.length, durationInput.value.length)

            textMask.update()
            this.onDurationChanges(newValue)
          }
        }
      })

    // Standard input handler (for paste or editing not at the end).
    fromEvent(durationInput, 'input')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        textMask.update()
        this.onDurationChanges(durationInput.value)
      })
  }

  protected onDurationChanges(durationText?: string) {
    if (!durationText) return

    const [minuteStr, secondStr] = durationText.split(':')
    let minutes = parseInt(minuteStr, 10)
    let seconds = parseInt(secondStr, 10)
    if (isNaN(minutes)) {
      minutes = 0
    }
    if (isNaN(seconds)) {
      seconds = 0
    }
    if (seconds > 59) {
      seconds = 59
    }

    let duration = (minutes * 60 + seconds) * 1000
    if (duration >= this.maxDuration) {
      duration = this.maxDuration
    }

    this.durationChanged.emit(duration)
  }
}
