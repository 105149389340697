import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { AppFeatures, BookmarkDTO } from '@ti-platform/contracts'
import { ExportFormat, injectDestroy$, Memoize, PricingPlanService } from '@ti-platform/web/common'
import { DeviceService, LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { MenuItem, PrimeIcons } from 'primeng/api'
import { Menu } from 'primeng/menu'
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  Observable,
  shareReplay,
  takeUntil,
} from 'rxjs'
import { map } from 'rxjs/operators'
import { BookmarksManager } from '@ti-platform/web/bookmarks'
import { ExportIconComponent, UnbookmarkIconComponent } from '@ti-platform/web/ui-kit/icons'

export interface OptionsItem extends MenuItem {
  customIcon?: any
}

@Component({
  selector: 'app-fleet-header-options-menu',
  templateUrl: 'header-options-menu.component.html',
  styleUrls: ['header-options-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderOptionsMenuComponent {
  @Output() export = new EventEmitter<ExportFormat>()
  @Output() bookmarkClicked = new EventEmitter<void>()
  @Output() searchClicked = new EventEmitter<void>()

  @Input() statusTitle?: string
  @Input() appendToBody = false
  @ViewChild('menuRef')
  protected readonly menuRef!: Menu
  @ViewChild('exportMenu')
  protected readonly exportMenuRef!: Menu
  protected readonly bookmark$ = new BehaviorSubject<BookmarkDTO | null | undefined>(null)
  protected readonly isBookmarked$ = new BehaviorSubject<boolean>(false)
  protected readonly areBookmarksAllowed$ = new BehaviorSubject<boolean>(false)
  protected readonly showExport$ = new BehaviorSubject<boolean>(false)
  protected readonly showSearch$ = new BehaviorSubject<boolean>(false)
  protected readonly items$ = new BehaviorSubject<OptionsItem[]>([])
  protected readonly commands$ = new BehaviorSubject<OptionsItem[]>([])
  protected readonly lang = inject(LanguageService)
  protected readonly destroy$ = injectDestroy$()
  protected readonly deviceService = inject(DeviceService)
  protected readonly bookmarks = inject(BookmarksManager)
  protected readonly pricingPlanService = inject(PricingPlanService)
  protected readonly ExportFormat = ExportFormat

  @Input() set items(items: OptionsItem[]) {
    if (this.items$.value != items) {
      this.items$.next(items)
    }
  }

  @Input() set bookmark(bookmark: BookmarkDTO | null | undefined) {
    if (this.bookmark$.value !== bookmark) {
      this.bookmark$.next(bookmark)
      this.revalidateIsBookmarked().catch((e) => console.error(e))
      this.revalidateAreBookmarkedAllowed().catch((e) => console.error(e))
    }
  }

  @Input() set showExport(show: boolean) {
    if (this.showExport$.value !== show) {
      this.showExport$.next(show)
    }
  }

  @Input() set showSearch(show: boolean) {
    if (this.showSearch$.value !== show) {
      this.showSearch$.next(show)
    }
  }

  @Input() set commands(commands: OptionsItem[]) {
    if (this.commands$.value !== commands) {
      this.commands$.next(commands)
    }
  }

  @Memoize()
  get exportMenuItems$(): Observable<MenuItem[]> {
    return this.lang
      .massTranslate$({
        exportXls: 'export.xls',
        exportCsv: 'export.csv',
        exportPDF: 'export.pdf',
      })
      .pipe(
        map((labels) => {
          return [
            {
              type: ExportFormat.Excel,
              label: labels?.exportXls,
              command: () => {
                this.exportExcel()
              },
            },
            {
              type: ExportFormat.CSV,
              label: labels?.exportCsv,
              command: () => {
                this.exportCsv()
              },
            },
            {
              type: ExportFormat.PDF,
              label: labels?.exportPDF,
              command: () => {
                this.exportPDF()
              },
            },
          ]
        }),
        takeUntil(this.destroy$),
      )
  }

  @Memoize()
  protected get options$(): Observable<OptionsItem[]> {
    return combineLatest([
      this.items$,
      this.commands$,
      this.bookmark$,
      this.areBookmarksAllowed$,
      this.isBookmarked$,
      this.showSearch$,
      this.showExport$,
    ]).pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
      map(([items, commands, bookmark, bookmarksAllowed, isBookmarked, showSearch, showExport]) => {
        const contextCommands: OptionsItem[] = []
        if (bookmark && bookmarksAllowed) {
          if (isBookmarked) {
            contextCommands.push({
              label: 'bookmarks.actions.remove',
              customIcon: UnbookmarkIconComponent,
              styleClass: 'w-16 h-16',
              command: () => {
                this.bookmarks.toggleBookmark(bookmark).then(() => this.bookmarkClicked.emit())
              },
            })
          } else {
            contextCommands.push({
              label: 'bookmarks.actions.add',
              icon: PrimeIcons.BOOKMARK,
              command: () => {
                this.bookmarks.toggleBookmark(bookmark).then(() => this.bookmarkClicked.emit())
              },
            })
          }
        }

        if (showSearch) {
          contextCommands.push({
            label: 'button.search',
            icon: PrimeIcons.SEARCH,
            command: () => this.searchClicked.emit(),
          })
        }

        if (showExport) {
          contextCommands.push({
            label: 'button.export',
            customIcon: ExportIconComponent,
            command: (event: any) => {
              // event.originalEvent is the real MouseEvent
              this.exportMenuRef.show(event.originalEvent)
            },
          })
        }

        const menuCommands: OptionsItem[] = [...contextCommands, ...commands]
        if (items.length > 0 && menuCommands.length > 0) {
          menuCommands[0].styleClass = menuCommands[0].styleClass
            ? menuCommands[0].styleClass + ' has-top-border'
            : 'has-top-border'
        }

        return [...items, ...menuCommands]
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this.destroy$),
    )
  }

  public open(event: MouseEvent) {
    if (!this.menuRef.visible) {
      this.menuRef.toggle(event)
    }

    event.stopPropagation()
    document.body.click()
  }

  public close() {
    this.menuRef.hide()
  }

  protected exportExcel() {
    this.export.emit(ExportFormat.Excel)
  }

  protected exportCsv() {
    this.export.emit(ExportFormat.CSV)
  }

  protected exportPDF() {
    this.export.emit(ExportFormat.PDF)
  }

  protected async revalidateIsBookmarked() {
    if (this.bookmark$.value) {
      this.isBookmarked$.next(await this.bookmarks.isBookmarked(this.bookmark$.value))
    }
  }

  protected async revalidateAreBookmarkedAllowed() {
    this.areBookmarksAllowed$.next(
      await this.pricingPlanService.areFeaturesAllowed([AppFeatures.Bookmarks]),
    )
  }
}
