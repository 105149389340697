import { inject, Injectable } from '@angular/core'
import { DataQueryProps, Fleet, FleetForm, FleetView } from '@ti-platform/contracts'
import {
  checkExistApiError,
  getApiErrorKey,
  ListModel,
  ListModelConfig,
  ListModelState,
} from '@ti-platform/web/common'
import { getSettingsByCountryCode } from '@ti-platform/web/ui-kit/i18n'
import { DataGridColumnType } from '@ti-platform/web/ui-kit/layout/components'
import { DialogFacade } from '@ti-platform/web/ui-kit/layout/services'
import { MessageService } from 'primeng/api'
import { BehaviorSubject, Observable, takeUntil } from 'rxjs'

export type FleetAccountsState = ListModelState<Fleet> & {
  errorMessage$: Observable<string>
}

@Injectable()
export class FleetAccountsModel extends ListModel<Fleet, FleetAccountsState> {
  protected readonly messageService = inject(MessageService)
  protected readonly errorMessage$ = new BehaviorSubject<string>('')
  protected readonly dialogFacade = inject(DialogFacade)

  public override loadState(): FleetAccountsState {
    return {
      ...super.loadState(),
      errorMessage$: this.errorMessage$.pipe(takeUntil(this.destroy$)),
    }
  }

  public async selectById(id: number): Promise<Fleet> {
    const fleet = await this.api.tsp.fleet.one(id)
    this.select(fleet)

    return fleet
  }

  public async launchFleet(data: FleetForm) {
    this.errorMessage$.next('')
    try {
      if (data.isDemo && (await this.api.tsp.fleet.isDemoLimitReached())) {
        this.errorMessage$.next(
          await this.languageService.translate('tsp-admin.fleets.errors.demo-limits-reached'),
        )
        return false
      }
    } catch (e: any) {
      console.error(e)
      await this.setErrorMessage(e.message)
      return false
    }

    try {
      await this.api.tsp.fleet.launch(this.prepareFleetData(data))
      this.reset()
      this.messageService.add({
        summary: await this.languageService.translate(
          'tsp-admin.fleets.notifications.launched-title',
        ),
        detail: await this.languageService.translate(
          'tsp-admin.fleets.notifications.launched-summary',
          {
            email: data.email,
          },
        ),
        life: 5000,
        severity: 'success',
      })
      this.errorMessage$.next('')
      return true
    } catch (e: any) {
      console.error(e)
      await this.setErrorMessage(e.message)
      this.reset()
      return false
    }
  }

  public async updateFleet(data: FleetForm) {
    this.errorMessage$.next('')
    const selectedFleet = this.store.selectedItem$.value
    const id = selectedFleet?.id
    if (!id) {
      await this.setErrorMessage()
      return false
    }

    try {
      if (
        !selectedFleet?.isDemo &&
        data.isDemo &&
        (await this.api.tsp.fleet.isDemoLimitReached())
      ) {
        this.errorMessage$.next(
          await this.languageService.translate('tsp-admin.fleets.errors.demo-limits-reached'),
        )
        return false
      }
    } catch (e: any) {
      await this.setErrorMessage(e.message)
      return false
    }

    try {
      const updatedFleet = await this.api.tsp.fleet.update(id, this.prepareFleetData(data))

      this.reset()
      this.select({
        ...selectedFleet,
        ...updatedFleet,
      })

      this.messageService.add({
        summary: await this.languageService.translate('tsp-admin.fleets.notifications.updated', {
          name: data.name,
        }),
        life: 5000,
        severity: 'success',
      })
      this.errorMessage$.next('')
      return true
    } catch (e: any) {
      console.error(e)
      await this.setErrorMessage(e.message)
      return false
    }
  }

  public async activateFleet(fleet: FleetView) {
    try {
      await this.api.tsp.fleet.activate(fleet.id)
      this.reset()
      await this.selectById(fleet.id)
      this.messageService.add({
        summary: await this.languageService.translate('tsp-admin.fleets.notifications.resumed', {
          name: fleet.name,
        }),
        life: 5000,
        severity: 'success',
      })
      return true
    } catch (error) {
      console.warn(`Cannot activate fleet`, error)
      await this.showUnknownErrorNotification()
    }
    return false
  }

  public async deleteFleet(fleet: FleetView) {
    const labels = await this.getDeleteDialogLabels(fleet.name)
    const dialogResult = await this.dialogFacade.confirm({
      ...labels,
      cancelButtonColor: 'var(--color-alert-500)',
      confirmButtonIcon: 'pi-trash',
    })
    if (dialogResult) {
      try {
        await this.api.tsp.fleet.deleteFleet(fleet.id)
        this.reset()
        this.messageService.add({
          summary: await this.languageService.translate('tsp-admin.fleets.notifications.deleted', {
            name: fleet.name,
          }),
          life: 5000,
          severity: 'success',
        })
        return true
      } catch (error) {
        console.warn(`Cannot delete fleet`, error)
        await this.showUnknownErrorNotification()
      }
    }

    return false
  }

  public async suspendFleet(fleet: FleetView) {
    const labels = await this.getSuspendDialogLabels(fleet.name)
    const dialogResult = await this.dialogFacade.confirm({
      ...labels,
      cancelButtonColor: 'var(--color-alert-500)',
      confirmButtonIcon: 'pi-trash',
    })
    if (dialogResult) {
      try {
        await this.api.tsp.fleet.deactivate(fleet.id)
        this.reset()
        this.messageService.add({
          summary: await this.languageService.translate(
            'tsp-admin.fleets.notifications.suspended',
            {
              name: fleet.name,
            },
          ),
          life: 5000,
          severity: 'success',
        })
        return true
      } catch (error) {
        console.warn(`Cannot suspend fleet`, error)
        await this.showUnknownErrorNotification()
      }
    }

    return false
  }

  protected async getDeleteDialogLabels(name: string) {
    const [summary, description, confirmationMessage, confirmButton, cancelButton] =
      await Promise.all([
        this.languageService.translate('tsp-admin.fleets.delete-dialog.summary', { name }),
        this.languageService.translate('tsp-admin.fleets.delete-dialog.description'),
        this.languageService.translate('tsp-admin.fleets.delete-dialog.delete-confirm-text'),
        this.languageService.translate('tsp-admin.fleets.delete-dialog.confirm-button'),
        this.languageService.translate('tsp-admin.fleets.delete-dialog.cancel-button'),
      ])
    return {
      summary,
      description,
      confirmationMessage,
      confirmButton,
      cancelButton,
    }
  }

  protected async getSuspendDialogLabels(name: string) {
    const [summary, description, confirmButton, cancelButton] = await Promise.all([
      this.languageService.translate('tsp-admin.fleets.suspend-dialog.summary', { name }),
      this.languageService.translate('tsp-admin.fleets.suspend-dialog.description'),
      this.languageService.translate('tsp-admin.fleets.suspend-dialog.confirm-button'),
      this.languageService.translate('tsp-admin.fleets.suspend-dialog.cancel-button'),
    ])
    return { summary, description, confirmButton, cancelButton }
  }

  protected async setErrorMessage(message = 'tsp-admin.fleets.errors.unknown') {
    if (checkExistApiError(message)) {
      message = getApiErrorKey(message)
    } else {
      message = 'tsp-admin.fleets.errors.unknown'
    }
    this.errorMessage$.next(await this.languageService.translate(message))
  }

  protected async showUnknownErrorNotification() {
    this.messageService.add({
      summary: await this.languageService.translate('tsp-admin.fleets.errors.unknown'),
      severity: 'error',
      life: 5000,
    })
  }

  protected config(): ListModelConfig {
    return {
      name: 'FleetAccountsModel',
      retainCacheUrl: ['accounts'],
      loadCount: () => this.loadCount(),
      gridColumns: [
        {
          field: 'name',
          label: 'tsp-admin.fleets.fields.name',
          type: DataGridColumnType.Template,
          sortable: true,
        },
        {
          field: 'devicesCount',
          label: 'tsp-admin.fleets.fields.devices-count',
          type: DataGridColumnType.Text,
          sortable: false,
        },
        {
          field: 'createdAt',
          label: 'tsp-admin.fleets.fields.created-at',
          type: DataGridColumnType.Date,
          sortable: true,
        },
        // {
        //   field: 'invoicesOverdue',
        //   label: 'tsp-admin.fleets.fields.invoices-overdue',
        //   type: DataGridColumnType.Template,
        //   sortable: false,
        // },
        {
          field: 'pricingPlanId',
          label: 'tsp-admin.fleets.form.input-label.pricing-plan',
          type: DataGridColumnType.Template,
          sortable: true,
        },
        {
          field: 'isActive',
          label: 'tsp-admin.fleets.fields.suspended',
          type: DataGridColumnType.Template,
          sortable: true,
        },
      ],
    }
  }

  protected loadPage(props: DataQueryProps): Promise<Fleet[]> {
    return this.api.tsp.fleet.list(props)
  }

  protected async loadCount() {
    return this.api.tsp.fleet.count()
  }

  protected prepareFleetData(data: FleetForm) {
    return {
      name: data.name,
      contactName: data.contactName,
      email: data.email,
      phone: data.phone,
      country: data.country,
      language: getSettingsByCountryCode(data.country).lang,
      address: data.address,
      city: data.city,
      region: data.region,
      zipCode: data.zipCode,
      measurementSystem: {
        distance: data.distance,
        volume: data.volume,
        consumption: data.consumption,
        temperature: data.temperature,
      },
      pricingPlanId: data.pricingPlanId,
      isDemo: data.isDemo,
    }
  }
}
