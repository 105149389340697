import { Component, inject, Input, OnInit } from '@angular/core'
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog'
import { Bookmark } from '@ti-platform/contracts'
import { BookmarksManager } from '@ti-platform/web/bookmarks'

@Component({
  selector: 'app-edit-bookmark-dialog',
  templateUrl: 'edit-bookmark-dialog.component.html',
  styleUrl: 'edit-bookmark-dialog.component.scss',
})
export class EditBookmarkDialogComponent implements OnInit {
  @Input() bookmark: Bookmark | null = null
  @Input() summary = ''
  @Input() description = ''
  @Input() confirmButton = 'Confirm'
  @Input() confirmButtonIcon?: string
  @Input() cancelButton = 'Cancel'
  @Input() cancelButtonColor = ''
  @Input() confirmationMessage?: string
  @Input() reverseButtons = false
  protected readonly bookmarks = inject(BookmarksManager)
  protected readonly dynamicDialogRef = inject(DynamicDialogRef)
  protected readonly dynamicDialogConfig = inject(DynamicDialogConfig)

  public ngOnInit() {
    // Assign data from dynamic dialog config
    Object.assign(this, this.dynamicDialogConfig.data)
  }

  save() {
    this.dynamicDialogRef.close(this.bookmark)
  }
}
