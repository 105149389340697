import { Component, inject, Input } from '@angular/core'
import { Observable } from 'rxjs'
import { Memoize } from '@ti-platform/web/common'
import { map } from 'rxjs/operators'
import { ApiService } from '@ti-platform/web/api/services/api.service'
import { TripsListModel } from '@ti-platform/web/fleet/services/trips-list.model'
import { UnitType } from '@ti-platform/web/ui-kit/i18n'
import { UITripHistoryData } from '@ti-platform/web/fleet/contracts'

@Component({
  selector: 'app-trips-list',
  templateUrl: 'trips-list.component.html',
  styleUrl: 'trips-list.component.scss',
})
export class TripsListComponent {
  protected readonly UnitType = UnitType

  protected readonly api = inject(ApiService)
  protected readonly trips = inject(TripsListModel)
  protected readonly tripsTrackBy = (i: number, trip: UITripHistoryData) => trip.id

  @Input() listStyle?: 'fleet' | 'video' = 'fleet'

  @Memoize()
  public get tripsHistory$(): Observable<UITripHistoryData[]> {
    return this.trips.loadedTrips$.pipe(
      map((tripsData) => {
        return tripsData.map((data) => {
          return {
            id: data.id,
            type: data.name,
            route: data.route,
            duration: Math.round(data.duration * 1000),
            mileage: data.mileage,
            startTimestamp: Math.round(data.begin * 1000),
            endTimestamp: Math.round(data.end * 1000),
            startAddress: data.wialon_address_start,
            endAddress: data.wialon_address_end,
          } as UITripHistoryData
        })
      }),
    )
  }
}
