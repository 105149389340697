import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { Action, Bookmark, Resource } from '@ti-platform/contracts'
import { Memoize, selectState } from '@ti-platform/web/common'
import { BookmarksListModel } from '../../models'
import { combineLatest, map } from 'rxjs'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'

interface EditedNameStatus {
  isLoading: boolean
  bookmark: Bookmark | null
  value: string
  valid: boolean
}

@Component({
  selector: 'app-fleet-bookmarks-grid',
  templateUrl: 'bookmarks-grid.component.html',
  styleUrls: ['bookmarks-grid.component.scss'],
})
export class BookmarksGridComponent {
  @Output() readonly edit = new EventEmitter<Bookmark>()
  @Output() readonly delete = new EventEmitter<Bookmark>()
  @Output() readonly rowItemClicked = new EventEmitter<Bookmark>()

  @Input() isTableCompactMode = false

  protected readonly state = selectState(BookmarksListModel)
  protected readonly model = inject(BookmarksListModel)
  protected readonly deviceService = inject(DeviceService)

  protected readonly Action = Action
  protected readonly Resource = Resource

  protected editedNameStatus: EditedNameStatus = {
    isLoading: false,
    bookmark: null,
    value: '',
    valid: false,
  }
  protected readonly nameMaxLength = 256
  protected readonly nameMinLength = 1

  @Memoize() get displayColumns$() {
    return combineLatest([this.state.columns$, this.deviceService.isTablet$]).pipe(
      map((data) => {
        const [columns, isTablet] = data
        if (isTablet) {
          return columns.filter((column) => column.field !== 'type')
        } else {
          return columns
        }
      }),
    )
  }

  protected onClickEditName(bookmark: Bookmark) {
    this.editedNameStatus.bookmark = bookmark
  }

  protected onCancelEditName() {
    this.resetNameEditingStatus()
  }

  protected async onSaveName(bookmark: Bookmark, name: string): Promise<void> {
    if (!name || name.length < this.nameMinLength || name.length > this.nameMaxLength) {
      this.editedNameStatus.valid = false
      return
    }

    try {
      await this.model.editBookmarkName({
        ...bookmark,
        name,
      })
    } catch (e) {
      this.editedNameStatus.valid = false
    }
    this.resetNameEditingStatus()
  }

  protected resetNameEditingStatus() {
    this.editedNameStatus.isLoading = false
    this.editedNameStatus.bookmark = null
    this.editedNameStatus.value = ''
    this.editedNameStatus.valid = false
  }
}
