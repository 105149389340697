<!-- date button-->
@if ({ isMobile: device.isMobile$ | async }; as view) {
  <p-button
    class="fw-btn no-text-wrap date-time-button text-14 fw-600 h--40 p--16 {{ btnClass }}"
    [ngClass]="{
      flat: (flatBtnClassOnMobile && view.isMobile) || isActive,
      saturated: isActive,
    }"
    [class.icon-only]="hideDateRange"
    [styleClass]="btnClass"
    [severity]="$any(view.isMobile ? null : isActive ? 'primary' : 'secondary')"
    [outlined]="(outlineBtnClassOnMobile && view.isMobile) || isActive"
    (click)="dateRangePickerPanel.toggle($event); dateRangePicker.triggerHighlightUpdate()"
  >
    @if (dateRange) {
      <app-icon-date [height]="20" [width]="20" class="icon"></app-icon-date>

      @if (!hideDateRange) {
        @switch (dateRange) {
          @case (PreDefinedRange.Today) {
            {{ 'date-range.today' | translate }}
          }
          @case (PreDefinedRange.Yesterday) {
            {{ 'date-range.yesterday' | translate }}
          }
          @case (PreDefinedRange.Last7Days) {
            {{ 'date-range.last-7-days' | translate }}
          }
          @case (PreDefinedRange.Last30Days) {
            {{ 'date-range.last-30-days' | translate }}
          }
          @default {
            @if (dateRange[0] && dateRange[1]) {
              @if (isOneDay && !showTime) {
                {{ dateRange[0] | formatDate: 'grid-date' }}
              } @else if (showTime) {
                {{ dateRange[0] | formatDate: 'grid-date-time' }} -
                {{ dateRange[1] | formatDate: 'grid-date-time' }}
              } @else {
                {{ dateRange[0] | formatDate: 'grid-date' }} -
                {{ dateRange[1] | formatDate: 'grid-date' }}
              }
            }
          }
        }
      }
    } @else if (placeholder) {
      {{ placeholder }}
    }
  </p-button>
}

<div
  (click)="dateRangePickerPanel.hide()"
  [ngClass]="{ hidden: !dateRangePickerPanel.overlayVisible }"
  class="backdrop"
></div>

<!-- date-range picker -->
<p-overlayPanel
  #dateRangePickerPanel
  appendTo="body"
  styleClass="custom-overlay-panel {{ device.isMobile() ? 'full-width' : '' }}"
>
  <app-date-range-picker
    #dateRangePicker
    (cancelClicked)="dateRangePickerPanel.hide()"
    (savedClicked)="onSavedClicked($event); dateRangePickerPanel.hide()"
    [disabled]="disabled"
    [enabledPreDefined]="enabledPreDefined"
    [maxDate]="maxDate"
    [maxDays]="maxDays"
    [numberOfMonths]="numberOfMonths"
    [showPreDefined]="showPreDefined"
    [singleDay]="singleDay"
    [value]="dateRange"
    [withTime]="withTime"
  ></app-date-range-picker>
</p-overlayPanel>
