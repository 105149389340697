@if (deviceService.isMobile$ | async) {
  <ng-container [ngTemplateOutlet]="mobileView"></ng-container>
} @else if (useCompactLayout && (deviceService.isSmallDesktop$ | async)) {
  <ng-container [ngTemplateOutlet]="tabletView"></ng-container>
} @else if (deviceService.isTablet$ | async) {
  <ng-container [ngTemplateOutlet]="tabletView"></ng-container>
} @else {
  <ng-container [ngTemplateOutlet]="desktopView"></ng-container>
}

<ng-template #desktopView>
  @if (breadcrumbs) {
    <section class="breadcrumbs flex flex-row">
      <p-button
        (click)="sidebarService.show()"
        [severity]="'secondary'"
        class="menu-button h--40 show-on--mobile"
        icon="pi pi-bars"
      ></p-button>

      <app-breadcrumbs [items]="breadcrumbs"></app-breadcrumbs>
    </section>
  }

  <header [class.with-breadcrumbs]="!!breadcrumbs" class="page-header">
    <div class="heading-group flex mr-8">
      @if (showReturnButton) {
        <div class="heading-desktop-back-section flex">
          <p-button
            (click)="goBack()"
            [severity]="'secondary'"
            class="button-back h--40 flex"
            icon="pi"
          >
            <app-icon-arrow [height]="20" [width]="20" direction="left"></app-icon-arrow>
          </p-button>
        </div>
      } @else if (templates?.returnButton) {
        <ng-container [ngTemplateOutlet]="templates?.returnButton ?? null"></ng-container>
      }

      <section class="title-wrapper flex flex--col">
        <section class="title-section">
          <section class="title-content flex flex--row">
            @if (templates?.title) {
              <ng-container [ngTemplateOutlet]="templates?.title ?? null"></ng-container>
            } @else {
              <h1 class="text-{{ headerTextSize }} fw-600">
                {{ titleText | translate }}
              </h1>
            }

            @if (this.showCounter || templates?.countIcon) {
              <app-counter class="ml-8" [brackets]="false" [text]="''" [value]="counter ?? 0">
                @if (templates?.countIcon) {
                  <ng-container [ngTemplateOutlet]="templates?.countIcon ?? null"></ng-container>
                }
              </app-counter>
            }
          </section>
          @if (bookmark) {
            <app-toggle-bookmark-button
              class="mr-8 ml-8"
              [bookmark]="bookmark"
              (bookmarkClicked)="refreshBookmark()"
              *appCheckPricingPlan="[AppFeatures.Bookmarks]"
            ></app-toggle-bookmark-button>
          }
          @if (templates?.titleBadge) {
            <ng-container [ngTemplateOutlet]="templates?.titleBadge ?? null"></ng-container>
          }
        </section>

        @if (templates?.description) {
          <ng-container [ngTemplateOutlet]="templates?.description ?? null"></ng-container>
        } @else {
          <span class="description text-light-description--panel hide-on--mobile">
            {{ descriptionText | translate }}
          </span>
        }
      </section>
    </div>

    <div class="controls">
      @if (templates?.leftControls) {
        <ng-container [ngTemplateOutlet]="templates?.leftControls ?? null"></ng-container>

        @if (showSearch || showDatePicker || filterOptions) {
          <div class="vertical-spacer--12"></div>
        }
      }
      @if (showSearch) {
        <div class="search-container p-fluid">
          <p-iconField class="h--40">
            <p-inputIcon>
              <app-icon-search [height]="20" [width]="20" [color]="'#667085'"></app-icon-search>
            </p-inputIcon>
            <input
              (ngModelChange)="searchChanged.emit($event)"
              [ngModel]="search"
              [placeholder]="'button.search' | translate"
              class="search-input text-14"
              pInputText
              type="text"
            />
            <p-inputIcon *ngIf="search?.length">
              <app-icon-close
                class="clickable"
                (click)="clearSearch.emit()"
                [color]="'var(--color-gray-500)'"
              ></app-icon-close>
            </p-inputIcon>
          </p-iconField>
        </div>
      }

      @if (showDatePicker) {
        <app-date-range-picker-dialog
          (dateRangeChange)="dateRangeChanged.emit($event)"
          [btnClass]="'fw-btn--mobile'"
          [withTime]="showDatePickerTime"
          [dateRange]="dateRange || undefined"
          class="controls-button"
          [class.hide-on--mobile]="filterPanelStyle === 'none'"
        ></app-date-range-picker-dialog>
      }

      @if (filterOptions) {
        <p-button
          (click)="filterPanelRef.open()"
          [severity]="'secondary'"
          badge="{{ filtersCount || undefined }}"
          class="controls-button fw-btn--mobile filters text-14 fw-600 h--40 p--18"
        >
          <app-icon-filters [height]="20" [width]="20" class="icon"></app-icon-filters>
          {{ 'button.filters' | translate }}
        </p-button>
      }

      @if (templates?.rightControls) {
        <ng-container [ngTemplateOutlet]="templates?.rightControls ?? null"></ng-container>
      }

      @if (templates?.actionButtons) {
        @if (showActionsSpacer) {
          <div class="vertical-spacer--12"></div>
        }

        <section class="grid-actions flex flex--row">
          @if (showExport) {
            <app-export-btn
              [class.mr-8]="!!templates?.actionButtons"
              (export)="export.emit($event)"
            ></app-export-btn>
          }
          <ng-container [ngTemplateOutlet]="templates?.actionButtons ?? null"></ng-container>
        </section>
      } @else if (showExport) {
        @if (showActionsSpacer) {
          <div class="vertical-spacer--12"></div>
        }

        <section class="flex flex--row">
          <app-export-btn (export)="export.emit($event)"></app-export-btn>
        </section>
      }
    </div>
  </header>

  @if (filterTags?.length) {
    <div class="filters-tags-wrapper">
      <app-slider [tags]="filterTags ?? []" (tagRemoved)="removeFilter($event)"></app-slider>
    </div>
  }
</ng-template>

<ng-template #tabletView>
  <header [class.mb-16]="filterPanelStyle !== 'default'" class="page-header-tablet flex gap-16">
    @if (!isSearchOpened()) {
      <div class="heading-group flex mr-8">
        @if (showReturnButton) {
          <div class="heading-desktop-back-section flex">
            <p-button
              (click)="goBack()"
              [severity]="'secondary'"
              class="button-back h--40 flex"
              icon="pi"
            >
              <app-icon-arrow [height]="20" [width]="20" direction="left"></app-icon-arrow>
            </p-button>
          </div>
        } @else if (templates?.returnButton) {
          <ng-container [ngTemplateOutlet]="templates?.returnButton ?? null"></ng-container>
        }

        <section class="title-wrapper flex flex--row">
          <section class="title-container flex flex--col">
            <section class="title-section">
              <section class="title-content flex flex--row">
                @if (templates?.title) {
                  <ng-container [ngTemplateOutlet]="templates?.title ?? null"></ng-container>
                } @else {
                  <h1 class="text-{{ headerTextSize }} fw-600">
                    {{ titleText | translate }}
                  </h1>
                }
              </section>
              @if (bookmark) {
                <app-toggle-bookmark-button
                  class="ml-8"
                  [bookmark]="bookmark"
                  (bookmarkClicked)="refreshBookmark()"
                  *appCheckPricingPlan="[AppFeatures.Bookmarks]"
                ></app-toggle-bookmark-button>
              }

              @if (templates?.titleBadge) {
                <section class="ml-8">
                  <ng-container [ngTemplateOutlet]="templates?.titleBadge ?? null"></ng-container>
                </section>
              }

              @if (this.showCounter) {
                <app-counter class="ml-8" [brackets]="false" [text]="''" [value]="counter ?? 0">
                  @if (templates?.countIcon) {
                    <ng-container [ngTemplateOutlet]="templates?.countIcon ?? null"></ng-container>
                  }
                </app-counter>
              }
            </section>

            @if (templates?.description) {
              <ng-container [ngTemplateOutlet]="templates?.description ?? null"></ng-container>
            } @else {
              <span class="description text-light-description--panel hide-on--mobile">
                {{ descriptionText | translate }}
              </span>
            }
          </section>
        </section>
      </div>

      <section class="controls flex flex--row">
        @if (templates?.leftControls) {
          <ng-container [ngTemplateOutlet]="templates?.leftControls ?? null"></ng-container>

          @if (showSearch || showDatePicker || filterOptions) {
            <div class="vertical-spacer--12"></div>
          }
        }

        @if (showSearch) {
          <app-icon-search
            class="clickable mr-8"
            [height]="20"
            [width]="20"
            [color]="'#667085'"
            (click)="openTabletSearch()"
          ></app-icon-search>
        }

        <section class="grid-actions flex flex--row">
          @if (showDatePicker) {
            <app-date-range-picker-dialog
              (dateRangeChange)="dateRangeChanged.emit($event)"
              [btnClass]="'fw-btn--mobile btn-compact'"
              [withTime]="showDatePickerTime"
              [hideDateRange]="true"
              [dateRange]="dateRange || undefined"
              [outlineBtnClassOnMobile]="true"
              class="date-picker-mobile"
              [class.mr-8]="!!filterOptions"
            ></app-date-range-picker-dialog>
          }

          @if (filterOptions) {
            <app-filter-control-btn
              [gridSortOrder]="sortOrder"
              [defaultSortOrder]="defaultSortOrder"
              [filtersCount]="filtersCount"
              [mode]="'compact'"
            >
            </app-filter-control-btn>
          }

          @if (templates?.actionButtons) {
            @if (showActionsSpacer && (showDatePicker || filterOptions || showExport)) {
              <div class="vertical-spacer--12"></div>
            }
            <section class="flex flex--row">
              @if (showExport) {
                <app-export-btn
                  [class.mr-8]="!!templates?.actionButtons"
                  (export)="export.emit($event)"
                  [compact]="true"
                ></app-export-btn>
              }
              <ng-container
                [ngTemplateOutlet]="templates?.actionButtons ?? null"
                [ngTemplateOutletContext]="{ isMobile: true }"
              ></ng-container>
            </section>
          } @else if (showExport) {
            @if (showActionsSpacer) {
              <div class="vertical-spacer--12"></div>
            }

            <section class="flex flex--row">
              <app-export-btn (export)="export.emit($event)" [compact]="true"></app-export-btn>
            </section>
          }
        </section>
      </section>
    } @else {
      <div class="search-container p-fluid">
        <p-iconField class="h--40">
          <p-inputIcon>
            <app-icon-search [height]="20" [width]="20" [color]="'#667085'"></app-icon-search>
          </p-inputIcon>
          <input
            #tabletSearchInput
            (ngModelChange)="searchChanged.emit($event)"
            [ngModel]="search"
            [placeholder]="'button.search' | translate"
            class="search-input text-14"
            pInputText
            type="text"
          />
          <p-inputIcon *ngIf="search?.length">
            <app-icon-close
              class="clickable"
              (click)="clearSearch.emit()"
              [color]="'var(--color-gray-500)'"
            ></app-icon-close>
          </p-inputIcon>
        </p-iconField>
      </div>

      <p-button
        severity="primary"
        [link]="true"
        (click)="clearSearch.emit(); isSearchOpened.set(false)"
      >
        {{ 'common.done' | translate }}
      </p-button>
    }
  </header>
</ng-template>

<ng-template #mobileView>
  <header [class.mb-16]="filterPanelStyle !== 'default'" class="page-header-mobile flex gap-16">
    @if (showMobileMenuButton && !templates?.mobileMenuBtn) {
      <p-button
        (click)="sidebarService.show()"
        [severity]="'secondary'"
        class="menu-button h--40 show-on--mobile"
        icon="pi pi-bars"
      ></p-button>
    } @else if (templates?.mobileMenuBtn) {
      <ng-container [ngTemplateOutlet]="templates?.mobileMenuBtn ?? null"></ng-container>
    } @else if (showReturnButton) {
      <p-button
        (click)="goBack()"
        [severity]="'secondary'"
        class="button-back h--40 flex"
        icon="pi"
      >
        <app-icon-arrow [height]="20" [width]="20" direction="left"></app-icon-arrow>
      </p-button>
    } @else if (templates?.returnButton) {
      <ng-container [ngTemplateOutlet]="templates?.returnButton ?? null"></ng-container>
    }

    @if (!isSearchOpened()) {
      <section class="left-side-header flex flex--col">
        <section class="title-section flex flex--row">
          <section class="title-content flex flex--row">
            @if (templates?.title) {
              <ng-container [ngTemplateOutlet]="templates?.title ?? null"></ng-container>
            } @else {
              <h1 class="title text-16 fw-600 mr-8">
                {{ titleText | translate }}
              </h1>
            }

            @if (this.showCounter) {
              <app-counter
                class="mr-8"
                [brackets]="false"
                [text]="''"
                [value]="counter ?? 0"
                [compactMode]="true"
              >
              </app-counter>
            }
          </section>

          @if (bookmark) {
            <app-toggle-bookmark-button
              class="mr-8"
              [bookmark]="bookmark"
              (bookmarkClicked)="refreshBookmark()"
              *appCheckPricingPlan="[AppFeatures.Bookmarks]"
            ></app-toggle-bookmark-button>
          }
        </section>

        @if (showDescriptionOnMobile) {
          @if (templates?.description) {
            <ng-container [ngTemplateOutlet]="templates?.description ?? null"></ng-container>
          } @else {
            <span class="description text-light-description--panel">
              {{ descriptionText | translate }}
            </span>
          }
        }
      </section>

      @if (showSearch && !adaptiveMenuOptions) {
        <app-icon-search
          class="clickable"
          [height]="20"
          [width]="20"
          [color]="'#667085'"
          (click)="openMobileSearch()"
        ></app-icon-search>
      }
      <section class="grid-actions flex flex--row">
        @if (showDatePicker && !filterOptions) {
          <app-date-range-picker-dialog
            (dateRangeChange)="dateRangeChanged.emit($event)"
            [btnClass]="'fw-btn--mobile'"
            [withTime]="showDatePickerTime"
            [hideDateRange]="true"
            [dateRange]="dateRange || undefined"
            class="date-picker-mobile"
          ></app-date-range-picker-dialog>
        } @else if (filterOptions && !showDatePicker) {
          <app-filter-control-btn
            [gridSortOrder]="sortOrder"
            [defaultSortOrder]="defaultSortOrder"
            [filtersCount]="filtersCount"
            [mode]="'mobile'"
          >
          </app-filter-control-btn>
        }

        @if (adaptiveMenuOptions) {
          <app-fleet-header-options-menu
            [items]="adaptiveMenuOptions"
            [bookmark]="this.bookmark"
            [showSearch]="showSearch"
            [showExport]="showExport"
            [statusTitle]="adaptiveMenuTitle"
            (bookmarkClicked)="refreshBookmark()"
            (searchClicked)="openMobileSearch()"
            (export)="export.emit($event)"
          ></app-fleet-header-options-menu>
        } @else if (templates?.actionButtons) {
          <section
            class="flex flex--row"
            [class.ml-8]="(showDatePicker && !filterOptions) || (filterOptions && !showDatePicker)"
          >
            @if (showExport) {
              <app-export-btn
                [class.mr-8]="!!templates?.actionButtons"
                (export)="export.emit($event)"
                [compact]="true"
              ></app-export-btn>
            }
            <ng-container
              [ngTemplateOutlet]="templates?.actionButtons ?? null"
              [ngTemplateOutletContext]="{ isMobile: true }"
            ></ng-container>
          </section>
        } @else if (showExport) {
          <section
            class="flex flex--row"
            [class.ml-8]="(showDatePicker && !filterOptions) || (filterOptions && !showDatePicker)"
          >
            <app-export-btn (export)="export.emit($event)" [compact]="true"></app-export-btn>
          </section>
        }
      </section>
    } @else {
      <div class="search-container p-fluid">
        <p-iconField class="h--40">
          <p-inputIcon>
            <app-icon-search [height]="20" [width]="20" [color]="'#667085'"></app-icon-search>
          </p-inputIcon>
          <input
            #mobileSearchInput
            (ngModelChange)="searchChanged.emit($event)"
            [ngModel]="search"
            [placeholder]="'button.search' | translate"
            class="search-input text-14"
            pInputText
            type="text"
          />
          <p-inputIcon *ngIf="search?.length">
            <app-icon-close
              class="clickable"
              (click)="clearSearch.emit()"
              [color]="'var(--color-gray-500)'"
            ></app-icon-close>
          </p-inputIcon>
        </p-iconField>
      </div>

      <p-button
        severity="primary"
        [link]="true"
        (click)="clearSearch.emit(); isSearchOpened.set(false)"
      >
        {{ 'common.done' | translate }}
      </p-button>
    }
  </header>

  @if (showDatePicker && (filterOptions?.length ?? 0) > 0) {
    <app-filter-bar
      class="p-16 flex"
      [dateRange]="dateRange"
      [defaultSortOrder]="defaultSortOrder"
      [gridSortOrder]="sortOrder"
      [filtersCount]="filtersCount"
      [showFilters]="(filterOptions?.length ?? 0) > 0"
      [showDatePicker]="showDatePicker"
      (dateRangeChanged)="dateRangeChanged.emit($event)"
    />
  }
</ng-template>

<app-filter-panel
  #filterPanelRef
  (filterValueChange)="filterChanged.emit($event)"
  (gridSortChanged)="sortChanged.emit($event)"
  [class.hide-on--mobile]="filterPanelStyle === 'none'"
  [defaultSortOrder]="defaultSortOrder"
  [filterOptions]="filterOptions"
  [filterValue]="filterValue"
  [gridColumns]="columns"
  [gridSortOrder]="sortOrder"
  [isFilteringEnabled]="true"
  [isSortingEnabled]="true"
/>
