import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-icon-no-warning',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9998 12.0012V16.0012M15.9998 20.0012H16.0098M14.2898 6.86118L5.81978 21.0012C5.64514 21.3036 5.55274 21.6465 5.55177 21.9957C5.55079 22.3449 5.64127 22.6883 5.8142 22.9917C5.98714 23.2951 6.2365 23.5479 6.53748 23.725C6.83847 23.9021 7.18058 23.9973 7.52978 24.0012H24.4698C24.819 23.9973 25.1611 23.9021 25.4621 23.725C25.7631 23.5479 26.0124 23.2951 26.1854 22.9917C26.3583 22.6883 26.4488 22.3449 26.4478 21.9957C26.4468 21.6465 26.3544 21.3036 26.1798 21.0012L17.7098 6.86118C17.5315 6.56729 17.2805 6.3243 16.981 6.15567C16.6814 5.98703 16.3435 5.89844 15.9998 5.89844C15.656 5.89844 15.3181 5.98703 15.0186 6.15567C14.7191 6.3243 14.468 6.56729 14.2898 6.86118Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23 7.67188L27 3.67188"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27 7.67188L23 3.67188"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `,
  ],
})
export class NoWarningIconComponent extends BaseIconComponent {
  @Input() override height = 32
  @Input() override width = 32
}
