import { Amplify } from 'aws-amplify'
import { decodeJWT } from 'aws-amplify/auth'
import type { TokenProvider } from '@aws-amplify/core'
import { AmplifyConfig, LOGIN_AS_SESSION_STORAGE_KEY } from '@ti-platform/web/common'
import { LoginAsSessionData } from '../contracts'

export interface CustomAuthOptions {
  resolveSessionUrl: string
  sessionIdParamName: string
}

// Overrides Amplify.Auth::tokenProvider for LoginAs functionality
export const initAmplify = async (config: AmplifyConfig, options: CustomAuthOptions) => {
  const session = await getActiveSessionData(options)
  const customAuth = session
    ? {
        Auth: {
          tokenProvider: {
            getTokens: async () => {
              return session
                ? {
                    accessToken: decodeJWT(session.accessToken),
                    idToken: decodeJWT(session.idToken),
                  }
                : null
            },
          } as TokenProvider,
        },
      }
    : undefined

  if (customAuth && session) {
    console.warn('AMPLIFY CUSTOM AUTH MODE', {
      sessionId: session.sessionId,
      targetUserId: session.targetUserId,
    })
  }

  Amplify.configure(
    {
      Auth: {
        Cognito: {
          userPoolId: config.userPoolId,
          allowGuestAccess: false,
          userPoolClientId: config.userPoolClientId,
          identityPoolId: config.identityPoolId,
        },
      },
    },
    customAuth,
  )
}

const getActiveSessionData = async (options: CustomAuthOptions) => {
  let session: LoginAsSessionData | undefined

  // Load new session if query parameter is provided
  const urlSearchParams = new URLSearchParams(location.search)
  const sessionIdParam = urlSearchParams.get(options.sessionIdParamName)
  if (sessionIdParam) {
    try {
      session = await fetch(`${options.resolveSessionUrl}/${sessionIdParam}`)
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 'error') throw new Error(res.message)
          return res.data
        })
    } catch (error) {
      console.error(`Cannot load auth session data`, error)
    } finally {
      // Remove sessionId search parameter from the URL
      history.replaceState({}, document.title, location.origin + location.pathname)
    }
  }

  // Try to restore session from session storage
  if (!session) {
    try {
      const cachedSession = window.sessionStorage.getItem(LOGIN_AS_SESSION_STORAGE_KEY)
      if (cachedSession) {
        session = JSON.parse(cachedSession)
      }
    } catch (error) {
      console.error(`Cannot parse cached session data`, error)
    }
  }

  if (isSessionValid(session)) {
    window.sessionStorage.setItem(LOGIN_AS_SESSION_STORAGE_KEY, JSON.stringify(session))
    return session
  }

  // Remove session from the storage if expired or invalid
  window.sessionStorage.removeItem(LOGIN_AS_SESSION_STORAGE_KEY)
}

const isSessionValid = (session?: LoginAsSessionData) => {
  if (!session) return false

  const accessTokenData = decodeJWT(session.accessToken)
  const idTokenData = decodeJWT(session.idToken)
  const expiresAt = Date.now() + 60_000 // Should last for at least 1 minute
  return (
    (accessTokenData?.payload?.exp ?? 0) * 1000 >= expiresAt &&
    (idTokenData?.payload?.exp ?? 0) * 1000 >= expiresAt
  )
}
