<ng-container [ngSwitch]="telemetry.lastStatus">
  <ng-container *ngSwitchCase="VehicleStatus.Running">
    <div class="stats-badge">
      <i>
        <app-icon-speedometer-solid
          [width]="16"
          [height]="16"
          [color]="'var(--icon-color)'"
        ></app-icon-speedometer-solid
      ></i>
      <span class="text text-12 fw-600">
        {{ telemetry.speed | formatUnit: UnitType.Speed }}
      </span>
    </div>

    <div
      class="stats-badge"
      [class.green]="!telemetry.lastStatusInvalid"
      [pTooltip]="
        telemetry.lastStatusInvalid ? ('fleet.message.may-not-be-current' | translate) : ''
      "
      [tooltipPosition]="'bottom'"
    >
      <i>
        <app-icon-arrow-block-solid
          [width]="16"
          [height]="16"
          [color]="'var(--icon-color)'"
        ></app-icon-arrow-block-solid
      ></i>
      <span class="text text-12 fw-600">{{ 'fleet.badge.moving' | translate }}</span>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="VehicleStatus.Idling">
    <div class="stats-badge">
      <i>
        <app-icon-clock-solid
          [width]="16"
          [height]="16"
          [color]="'var(--icon-color)'"
        ></app-icon-clock-solid>
      </i>
      <span class="text text-12 fw-600">
        {{ telemetry.lastStatusChangeAt | formatTimeSince: true }}
      </span>
    </div>

    <div
      class="stats-badge"
      [class.red]="!telemetry.lastStatusInvalid"
      [pTooltip]="
        telemetry.lastStatusInvalid ? ('fleet.message.may-not-be-current' | translate) : ''
      "
      [tooltipPosition]="'bottom'"
    >
      <i>
        <app-icon-minus-solid
          [width]="14"
          [height]="14"
          [color]="'var(--icon-color)'"
        ></app-icon-minus-solid>
      </i>
      <span class="text text-12 fw-600">{{ 'fleet.badge.idling' | translate }}</span>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="VehicleStatus.Stopped">
    <div class="stats-badge">
      <i>
        <app-icon-clock-solid
          [width]="16"
          [height]="16"
          [color]="'var(--icon-color)'"
        ></app-icon-clock-solid>
      </i>
      <span class="text text-12 fw-600">
        {{ telemetry.lastStatusChangeAt | formatTimeSince: true }}
      </span>
    </div>

    <div class="stats-badge">
      <i>
        <app-icon-stop-solid
          [width]="15"
          [height]="15"
          [color]="'var(--icon-color)'"
        ></app-icon-stop-solid>
      </i>
      <span class="text text-12 fw-600">{{ 'fleet.badge.stopped' | translate }}</span>
    </div>
  </ng-container>
</ng-container>
