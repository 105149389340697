import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UserProfile } from '@ti-platform/contracts'

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: 'user-edit.component.html',
  styleUrl: 'user-edit.component.scss',
})
export class UserEditDialogComponent {
  @Output() cancelEdit = new EventEmitter<void>()
  @Output() submitEdit = new EventEmitter<UserProfile>()
  @Input() opened = false
  @Input() user: Partial<UserProfile> = {}
  @Input() displayRoles: 'TI' | 'TSP' | 'Fleet' = 'TSP'
}
