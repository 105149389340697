import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import {
  DateRange,
  getDateRangeFromPredefined,
  injectDestroy$,
  isPreDefinedRange,
  Memoize,
  PreDefinedRange,
} from '@ti-platform/web/common'
import { DeviceService, LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { Calendar, CalendarMonthChangeEvent } from 'primeng/calendar'
import { BehaviorSubject, map, Observable, Subject, combineLatest, takeUntil } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { ONE_DAY_MS } from '@ti-platform/web/video/contracts'
import { MessageService } from 'primeng/api'

export type DateRangeOption = { label: string; value: PreDefinedRange }

@Component({
  selector: 'app-date-range-picker',
  templateUrl: 'date-range-picker.component.html',
  styleUrls: ['date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true,
    },
  ],
})
export class DateRangePickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  protected readonly updateHighlight$ = new Subject<void>()
  protected readonly changeDetectorRef = inject(ChangeDetectorRef)
  protected readonly messageService = inject(MessageService)
  protected readonly destroy$ = injectDestroy$()
  protected readonly languageService = inject(LanguageService)
  protected readonly deviceService = inject(DeviceService)

  protected readonly enabledPreDefined$ = new BehaviorSubject<PreDefinedRange[] | undefined>(
    undefined,
  )

  protected onChange: () => void = () => undefined
  protected onTouched: () => void = () => undefined

  protected selectedRangeOption?: PreDefinedRange

  @ViewChild('calendar')
  protected readonly calendar!: Calendar

  // This variable is used to allow editing the end time before the end date is selected
  protected temporaryEndDate?: Date
  protected rangeValue: DateRange | undefined = undefined

  @Input() numberOfMonths = 2
  @Input() touched = false
  @Input() disabled = false
  @Input() withTime = false
  @Input() singleDay = false
  @Input() showPreDefined = true
  @Input() maxDate?: Date
  @Input() maxDays?: number

  @Input() set enabledPreDefined(value: PreDefinedRange[] | undefined) {
    this.enabledPreDefined$.next(value)
  }
  get enabledPreDefined(): never {
    throw new Error(`Do not use this getter`)
  }

  protected _value: DateRange | PreDefinedRange | Date | undefined

  get value() {
    return this._value
  }

  @Input()
  set value(value: DateRange | PreDefinedRange | Date | undefined) {
    this._value = value

    // Reset selected pre-defined range
    this.selectedRangeOption = undefined

    if (this._value) {
      if (isPreDefinedRange(this._value)) {
        this.rangeValue = getDateRangeFromPredefined(this._value as PreDefinedRange) || undefined
        this.selectedRangeOption = this._value as PreDefinedRange
      } else if (
        Array.isArray(this._value) &&
        this._value?.length == 2 &&
        this._value[0] &&
        this._value[1]
      ) {
        this.rangeValue = this._value
      } else if (this._value instanceof Date) {
        this.rangeValue = [this._value, undefined as unknown as Date]
      }
    } else {
      this.rangeValue = undefined
    }
  }

  @Output() readonly valueChange = new EventEmitter<DateRange | PreDefinedRange>()
  @Output() readonly dateSelected = new EventEmitter<Date>()
  @Output() readonly monthChanged = new EventEmitter<CalendarMonthChangeEvent>()
  @Output() readonly savedClicked = new EventEmitter<DateRange | PreDefinedRange>()
  @Output() readonly cancelClicked = new EventEmitter()

  get shownNumberOfMonths() {
    return this.deviceService.isMobileBreakPoint$.value ? 1 : this.numberOfMonths
  }

  @Memoize()
  get preDefinedOptions$(): Observable<{ value: PreDefinedRange; label: string }[]> {
    return combineLatest([
      this.enabledPreDefined$,
      this.languageService.massTranslate$({
        today: 'date-range.today',
        yesterday: 'date-range.yesterday',
        last7days: 'date-range.last-7-days',
        last30days: 'date-range.last-30-days',
      }),
    ]).pipe(
      map(([enabledPreDefined, labels]) => {
        let items = [
          {
            value: PreDefinedRange.Today,
            label: labels.today,
          },
          {
            value: PreDefinedRange.Yesterday,
            label: labels.yesterday,
          },
          {
            value: PreDefinedRange.Last7Days,
            label: labels.last7days,
          },
          {
            value: PreDefinedRange.Last30Days,
            label: labels.last30days,
          },
        ]

        if (enabledPreDefined) {
          items = items.filter((item) => enabledPreDefined?.includes(item.value))
        }

        return items
      }),
    )
  }

  protected markAsTouched() {
    if (!this.touched) {
      this.onTouched()
      this.touched = true
    }
  }

  public writeValue(value: DateRange | PreDefinedRange) {
    if (isPreDefinedRange(value)) {
      this.rangeValue = getDateRangeFromPredefined(value as PreDefinedRange) as DateRange
    } else {
      // Ensure start <= end
      if (Array.isArray(value) && value[0] && value[1]) {
        if (value[0].valueOf() > value[1].valueOf()) {
          const tmp = value[0]
          value[0] = value[1]
          value[1] = tmp
        }
      }

      this.rangeValue = value as DateRange
    }

    this.updateHighlight$.next()
  }

  public registerOnChange(onChange: () => void) {
    this.onChange = onChange
  }

  public registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled
  }

  public triggerHighlightUpdate() {
    setTimeout(() => this.updateHighlight$.next(), 16)
  }

  public ngOnInit() {
    this.updateHighlight$
      .pipe(debounceTime(16), takeUntil(this.destroy$))
      .subscribe(() => this.updateRangeHighlight())
  }

  public ngAfterViewInit() {
    // To display current month at the right of date-picker
    if (this.shownNumberOfMonths > 1) {
      const targetMonth = this.calendar.currentMonth - this.shownNumberOfMonths + 1
      if (targetMonth >= 0) {
        this.setDisplayedCalendarMonthAndYear(targetMonth, this.calendar.currentYear)
      } else {
        this.setDisplayedCalendarMonthAndYear(12 + targetMonth, this.calendar.currentYear - 1)
      }
    }
  }

  protected selectPreDefinedRange(value: PreDefinedRange) {
    this.selectedRangeOption = value
    this.markAsTouched()
    this.writeValue(value)
    this.updateHighlight$.next()
  }

  protected onSelected(value: Date) {
    this.dateSelected.next(value)
    this.selectedRangeOption = undefined
    this.updateHighlight$.next()
  }

  protected onMonthChange(event: CalendarMonthChangeEvent) {
    this.monthChanged.next(event)
    this.updateHighlight$.next()
  }

  // for reaction on primeng component changes
  protected onRangeUpdated(value: DateRange | Date) {
    // Support `singleDay` mode
    if (!Array.isArray(value)) {
      value = [value, null as unknown as Date]
    }

    if (this.withTime) {
      if (value[0] && value[1]) {
        value[1] = dateToTheEndOfDay(value[1])
      } else if (value[0]) {
        this.temporaryEndDate = dateToTheEndOfDay(value[0])
      }
    }

    this.markAsTouched()
    this.writeValue(value)
    this.updateHighlight$.next()
  }

  protected updateRangeHighlight() {
    // Remove previous range styling
    const oldRange = Array.from(
      document.querySelectorAll<HTMLElement>('.range-start, .range-end, .range-entry'),
    )
    if (oldRange.length > 0) {
      oldRange.forEach((element) => {
        element.classList.remove('range-start', 'range-end', 'range-entry')
      })
    }
    // Add new range styling if selected
    if (this.rangeValue && this.rangeValue[0] && this.rangeValue[1]) {
      const startDateFormatted = this.dateToDatePickerFormat(this.rangeValue[0])
      const endDateFormatted = this.dateToDatePickerFormat(this.rangeValue[1])
      // Ensure calendar component is updated
      this.changeDetectorRef.detectChanges()

      // Wait for the calendar component to re-render properly
      const range = Array.from(
        document.querySelectorAll<HTMLElement>('.p-datepicker-group-container span.p-highlight'),
      )
      if (range.length > 0) {
        range.forEach((element) => {
          const elementDate = element.dataset['date']
          const classesToAdd = new Array<string>()
          if (elementDate === startDateFormatted) {
            classesToAdd.push('range-start')
          }
          if (elementDate === endDateFormatted) {
            classesToAdd.push('range-end')
          }
          if (!classesToAdd.length) {
            classesToAdd.push('range-entry')
          }
          classesToAdd.forEach((className) => element.parentElement?.classList.add(className))
        })
      }
    }
  }

  protected dateToDatePickerFormat(date: Date): string {
    return [date.getFullYear(), date.getMonth(), date.getDate()].join('-')
  }

  protected onCancel() {
    this.cancelClicked.emit()
  }

  protected onSubmit() {
    if (this.maxDays && !this.isDateValid()) {
      return this.languageService
        .translate(`errors.maximum-date-range-days`, { days: this.maxDays })
        .then((detail) => this.messageService.add({ severity: 'error', detail, life: 3000 }))
    }

    this.savedClicked.emit(this.selectedRangeOption ?? this.rangeValue)
  }

  protected onTimeChanged(date: Date, type: 'start' | 'end') {
    this.selectedRangeOption = undefined
    if (this.rangeValue && !this.rangeValue[1] && this.temporaryEndDate) {
      this.rangeValue[1] = this.temporaryEndDate
    }

    const original = this.calendar.createMonths
    this.calendar.createMonths = (...args) => undefined

    if (type === 'start' && this.rangeValue?.[1]) {
      this.markAsTouched()
      this.writeValue([date, this.rangeValue?.[1]])
      this.updateHighlight$.next()
    }

    if (type === 'end' && this.rangeValue?.[0]) {
      this.markAsTouched()
      this.writeValue([this.rangeValue?.[0], date])
      this.updateHighlight$.next()
    }

    setTimeout(() => {
      this.calendar.createMonths = original
    }, 50)
  }

  protected setDisplayedCalendarMonthAndYear(month: number, year: number) {
    setTimeout(() => {
      this.calendar.currentMonth = month
      this.calendar.currentYear = year
      this.calendar.createMonths(this.calendar.currentMonth, this.calendar.currentYear)
      this.calendar.cd.detectChanges()
    }, 10)
  }

  protected get isOneDay() {
    if (this.rangeValue && this.rangeValue[0]) {
      if (!this.rangeValue[1]) {
        return true
      } else {
        return (
          this.rangeValue[0].getDate() === this.rangeValue[1].getDate() &&
          this.rangeValue[0].getMonth() === this.rangeValue[1].getMonth() &&
          this.rangeValue[0].getFullYear() === this.rangeValue[1].getFullYear()
        )
      }
    } else {
      return false
    }
  }

  protected get isTheSameYear() {
    if (this.rangeValue && this.rangeValue[0] && this.rangeValue[1]) {
      return this.rangeValue[0].getFullYear() === this.rangeValue[1].getFullYear()
    } else {
      return false
    }
  }

  protected isDateValid() {
    if (
      Array.isArray(this.rangeValue) &&
      this.rangeValue[0] &&
      this.rangeValue[1] &&
      this.maxDays
    ) {
      const durationMs = this.rangeValue[1].valueOf() - this.rangeValue[0].valueOf()
      return durationMs / ONE_DAY_MS <= this.maxDays
    }
    return !!this.rangeValue
  }
}

const dateToTheEndOfDay = (date: Date): Date => {
  const endOfDay = new Date(date)
  endOfDay.setHours(23)
  endOfDay.setMinutes(59)
  endOfDay.setSeconds(59)
  endOfDay.setMilliseconds(999)
  return endOfDay
}
