import { Component, inject, Input } from '@angular/core'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'

@Component({
  selector: 'app-filter-button',
  templateUrl: 'filter-button.component.html',
  styleUrls: ['filter-button.component.scss'],
})
export class FilterButtonComponent {
  @Input() filtersCount?: number
  @Input() iconOnly = false
  @Input() showText = false
  @Input() mode: 'desktop' | 'compact' | 'mobile' = 'desktop'
  @Input() sortingApplied = false

  protected readonly deviceService = inject(DeviceService)
}
