import { inject, Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { injectDestroy$, NavigationHistoryProvider } from '@ti-platform/web/common'

@Injectable({ providedIn: 'root' })
export class Navigation {
  protected readonly router = inject(Router)
  protected readonly historyProvider = inject(NavigationHistoryProvider)
  protected readonly activatedRoute = inject(ActivatedRoute)
  protected readonly destroy$ = injectDestroy$()

  goBack(defaultRoute = '..') {
    const previousUrl = this.historyProvider.popPreviousUrl()
    if (previousUrl) {
      return this.router.navigateByUrl(previousUrl)
    }

    defaultRoute ??= '..'
    return this.router.navigate([defaultRoute])
  }

  goTo(defaultRoute = '..') {
    return this.router.navigate([defaultRoute])
  }
}
