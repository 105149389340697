import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-bookmark-alert-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16699 12.25C4.16699 12.25 4.89616 11.5 7.08366 11.5C9.27116 11.5 10.7295 13 12.917 13C15.1045 13 15.8337 12.25 15.8337 12.25V3.25C15.8337 3.25 15.1045 4 12.917 4C10.7295 4 9.27116 2.5 7.08366 2.5C4.89616 2.5 4.16699 3.25 4.16699 3.25V12.25Z"
        fill="#E9D7FE"
        style="fill:#E9D7FE;fill:color(display-p3 0.9137 0.8431 0.9961);fill-opacity:1;"
      />
      <path
        d="M4.16699 12.25C4.16699 12.25 4.89616 11.5 7.08366 11.5C9.27116 11.5 10.7295 13 12.917 13C15.1045 13 15.8337 12.25 15.8337 12.25V3.25C15.8337 3.25 15.1045 4 12.917 4C10.7295 4 9.27116 2.5 7.08366 2.5C4.89616 2.5 4.16699 3.25 4.16699 3.25V12.25ZM4.16699 12.25V17.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: start;
        justify-content: center;
        cursor: pointer;
      }
    `,
  ],
})
export class BookmarkAlertIconComponent extends BaseIconComponent {
  @Input() override color = '#7F56D9'
}
