import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule, Routes } from '@angular/router'
// App
import { AuthModule } from '@ti-platform/web/auth/auth.module'
import { AppCommonModule } from '@ti-platform/web/common/common.module'
import { UiKitModule } from '@ti-platform/web/ui-kit/ui-kit.module'
import { BookmarksListModel } from './models'
import {
  BookmarksGridComponent,
  BookmarksListComponent,
  BookmarksListItemComponent,
  BookmarksPageComponent,
  BookmarksTypeIconComponent,
  EditBookmarkDialogComponent,
} from './components'

const COMPONENTS = [
  EditBookmarkDialogComponent,
  BookmarksGridComponent,
  BookmarksListComponent,
  BookmarksListItemComponent,
  BookmarksPageComponent,
  BookmarksTypeIconComponent,
]

const routes: Routes = [
  { path: '', component: BookmarksPageComponent, data: { title: 'bookmarks.title' } },
  { path: '**', redirectTo: '' },
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    // App
    AuthModule,
    AppCommonModule,
    UiKitModule,
  ],
  providers: [BookmarksListModel],
  declarations: [...COMPONENTS],
  exports: [RouterModule, EditBookmarkDialogComponent],
})
export class BookmarksModule {}
