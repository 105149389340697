/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import {
  clone,
  DateRange,
  ExportFormat,
  FilterOptions,
  PreDefinedRange,
} from '@ti-platform/web/common'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { MenuItem } from 'primeng/api'
import { SidebarService } from '../../services'
import { DataGridColumn, DataGridSortOrder } from '../data-grid'
import { TagBarOption } from '../slider/slider.component'
import { AppFeatures, BookmarkDTO } from '@ti-platform/contracts'
import { OptionsItem } from '@ti-platform/web/ui-kit/layout'
import { Navigation } from '@ti-platform/web/common/services/navigation'

export interface HeaderTemplates {
  mobileMenuButton?: TemplateRef<any>
  title?: TemplateRef<any>
  description?: TemplateRef<any>
  returnButton?: TemplateRef<any>
  rightControls?: TemplateRef<any>
  leftControls?: TemplateRef<any>
  countIcon?: TemplateRef<any>
  titleBadge?: TemplateRef<any>
  actionButtons?: TemplateRef<any>
  mobileMenuBtn?: TemplateRef<any>
}

@Component({
  selector: 'app-fleet-page-header',
  templateUrl: 'fleet-header.component.html',
  styleUrls: ['fleet-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FleetHeaderComponent implements OnChanges {
  @Output() export = new EventEmitter<ExportFormat>()
  @Output() searchChanged = new EventEmitter<string>()
  @Output() dateRangeChanged = new EventEmitter<DateRange | PreDefinedRange | undefined>()
  @Output() sortChanged = new EventEmitter<DataGridSortOrder>()
  @Output() filterChanged = new EventEmitter<Record<string, any>>()
  @Output() clearSearch = new EventEmitter<void>()
  @Output() clearFilter = new EventEmitter<void>()

  @Input() filterPanelStyle: 'default' | 'compact' | 'none' = 'default'
  @Input() counter: number | undefined
  @Input() breadcrumbs?: MenuItem[] | null
  @Input() adaptiveMenuOptions?: OptionsItem[] | null | undefined
  @Input() bookmark?: BookmarkDTO | null = null
  @Input() columns?: DataGridColumn[] | null
  @Input() dateRange?: DateRange | PreDefinedRange | null
  @Input() filterOptions?: FilterOptions[] | null
  @Input() filterValue?: Record<string, any> | null
  @Input() search?: string | null = ''
  @Input() sortOrder?: DataGridSortOrder | null
  @Input() defaultSortOrder?: DataGridSortOrder | null
  @Input() showReturnButton = false
  @Input() mobileMenuButton = true

  @Input() templates?: HeaderTemplates

  @Input() titleText = ''
  @Input() adaptiveMenuTitle?: string
  @Input() adaptiveMenuDescription?: string
  @Input() descriptionText = ''
  @Input() defaultReturnRoute?: string
  @Input() forceDefaultReturnRoute = false
  @Input() useCompactLayout = false
  @Input() showActionsSpacer = true
  @Input() showCounter = false
  @Input() showSearch = true
  @Input() showExport = false
  @Input() showDatePicker = false
  @Input() showDatePickerTime = false
  @Input() showMobileMenuButton = true
  @Input() showDescriptionOnMobile = false
  @Input() headerTextSize = 32 // Applied only on desktop

  @ViewChild('mobileSearchInput')
  protected readonly mobileSearchInputRef!: ElementRef

  @ViewChild('tabletSearchInput')
  protected readonly tabletSearchInputRef!: ElementRef
  protected readonly AppFeatures = AppFeatures

  protected filterTags?: TagBarOption[]
  protected filtersCount?: number
  protected readonly isSearchOpened = signal(!!this.search)

  protected readonly deviceService = inject(DeviceService)
  protected readonly sidebarService = inject(SidebarService)
  protected readonly navigation = inject(Navigation)

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.filterOptions || changes.filterValue) {
      this.filterTags = this.prepareFilterTags(this.filterOptions, this.filterValue)
      this.filtersCount = this.countFiltersValues(this.filterValue)
    }

    if (changes.search && changes.search.currentValue) {
      this.isSearchOpened.set(true)
      this.mobileSearchInputRef?.nativeElement?.focus()
    }
  }

  protected prepareFilterTags(
    filterOptions?: FilterOptions[] | null,
    filterValue?: Record<string, any> | null,
  ): TagBarOption[] {
    let combinedFiltersData = new Array<TagBarOption>()
    if (filterOptions) {
      for (const filterOption of filterOptions) {
        const valuesArray = filterValue ? (filterValue[filterOption.key] ?? undefined) : undefined
        if (valuesArray) {
          combinedFiltersData = combinedFiltersData.concat(
            valuesArray
              .map((value: unknown) => {
                return filterOption.options.find((option) => option.value === value)
              })
              .filter((obj: unknown) => obj !== undefined),
          )
        }
      }
    }
    return combinedFiltersData
  }

  protected countFiltersValues(filter?: Record<string, any> | null): number {
    let count = 0
    if (filter) {
      for (const key in filter) {
        count += filter[key]?.length ?? 0
      }
    }
    return count
  }

  protected removeFilter(event: TagBarOption) {
    if (!this.filterValue) return

    const filters = clone(this.filterValue)
    const filterKeys = Object.keys(filters)
    const newFilters = {} as Record<string, any>

    for (const key of filterKeys) {
      if (Array.isArray(filters[key])) {
        newFilters[key] = filters[key].filter((val: string) => val !== event.value)
      }
    }

    const count = this.countFiltersValues(newFilters)
    if (count > 0) {
      this.filterChanged.emit(newFilters)
    } else {
      this.clearFilter.emit()
    }
  }

  protected goBack() {
    if (this.forceDefaultReturnRoute) {
      return this.navigation.goTo(this.defaultReturnRoute)
    } else {
      return this.navigation.goBack(this.defaultReturnRoute)
    }
  }

  protected refreshBookmark() {
    if (this.bookmark) {
      this.bookmark = {
        ...this.bookmark,
      }
    }
  }

  protected openMobileSearch() {
    this.isSearchOpened.set(true)
    setTimeout(() => this.mobileSearchInputRef?.nativeElement?.focus(), 0)
  }

  protected openTabletSearch() {
    this.isSearchOpened.set(true)
    setTimeout(() => this.tabletSearchInputRef?.nativeElement?.focus(), 0)
  }
}
