import { inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  Subject,
  Subscription,
  take,
  takeUntil,
  throttleTime,
  timer,
  withLatestFrom,
} from 'rxjs'
import { map } from 'rxjs/operators'
import getBbox from '@turf/bbox'
import circle from '@turf/circle'
import getCenter from '@turf/center'
import { Feature, Polygon } from 'geojson'
import { MapMouseEvent, MapTouchEvent, PointLike } from 'maplibre-gl'
import MapboxDraw, { MapboxDrawOptions } from '@mapbox/mapbox-gl-draw'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CircleMode, DirectMode, DragCircleMode, SimpleSelectMode } from 'mapbox-gl-draw-circle'
import {
  Action,
  ADDRESS_LOCATION_RADIUS,
  AlertTriggerTypes,
  LocationType,
  Resource,
} from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { AccessControl, BrowserLocalStorage, injectDestroy$ } from '@ti-platform/web/common'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { MapAdapter, MapLibreMapAdapter } from '../adapters'
import {
  Coordinates,
  GeofenceData,
  GeofenceFeature,
  MapGeofenceDataSource,
  MapGeofenceEditDataSource,
  MapGeofenceManager,
  MapGeofenceVisualizer,
} from '../contracts'
import { GeofenceEditMarkerStep } from '../components'
import { GeofenceMarkerOptions, MapLibreGeofenceMarker } from '../markers'
import { MaplibreGeofenceVisualizer } from './geofence-visualizer'
import { MapGuideModel, MapGuideOptions } from '../services'
import {
  addMaplibreLongTouchListener,
  applyMapboxDrawOverrides,
  getCircleFeatureFromCoords,
  getDrawStyles,
  getSquarePolygonFeature,
  toLatLng,
  toLngLat,
} from '../utils'

@Injectable()
export class MaplibreGeofenceManager extends MapGeofenceManager {
  public readonly isEnabled$ = new BehaviorSubject<boolean>(true)
  public readonly editEnabled$ = new BehaviorSubject<boolean>(true)
  public readonly editedGeofenceId$ = new BehaviorSubject<string | null>(null)
  public readonly editModeExited$ = new Subject<void>()
  public readonly editModeEntered$ = new Subject<void>()

  protected readonly accessControl = inject(AccessControl)
  protected readonly api = inject(ApiService)
  protected readonly browserStorage = inject(BrowserLocalStorage)
  protected readonly adapter = inject(MapAdapter) as MapLibreMapAdapter
  protected readonly dataSource = inject(MapGeofenceDataSource) as MapGeofenceEditDataSource
  protected readonly mapGuide = inject(MapGuideModel, { optional: true })
  protected readonly router = inject(Router)
  protected readonly deviceService = inject(DeviceService)
  protected readonly visualizer = injectMapGeofenceVisualizer()
  protected readonly destroy$ = injectDestroy$()

  protected readonly DRAFT_FEATURE_ID = 'draft-geofence'
  protected readonly GEOFENCE_PINNED_BY_DEFAULT = false
  protected readonly GEOFENCE_PINNED_STORAGE_KEY = 'TI_GEOFENCE_FORM_PINNED'

  protected readonly DRAW_CLICK_BUFFER = 10
  protected readonly DRAW_TOUCH_BUFFER = 20

  protected readonly MIN_CIRCLE_GEOFENCE_RADIUS = 0.001 // km
  protected readonly MAX_CIRCLE_GEOFENCE_RADIUS = 1000 // km

  protected readonly draw!: MapboxDraw & { options: MapboxDrawOptions }
  protected readonly currentMarker$ = new BehaviorSubject<MapLibreGeofenceMarker | undefined>(
    undefined,
  )

  public constructor() {
    super()

    applyMapboxDrawOverrides()

    this.draw = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      clickBuffer: this.DRAW_CLICK_BUFFER,
      touchBuffer: this.DRAW_TOUCH_BUFFER,
      modes: {
        ...MapboxDraw.modes,
        draw_circle: CircleMode,
        drag_circle: DragCircleMode,
        direct_select: DirectMode,
        simple_select: SimpleSelectMode,
      },
      styles: getDrawStyles(this.visualizer.geofenceColor),
    }) as MapboxDraw & { options: MapboxDrawOptions }

    this.adapter.onInit$.subscribe(() => this.init())

    // Sync `isEnabled` status to visualizer
    this.isEnabled$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isEnabled) => this.visualizer.isEnabled$.next(isEnabled))

    // Ensure current marker is properly removed from DOM
    this.destroy$.subscribe(() => this.currentMarker$.value?.destroy())
  }

  public selectGeofence(id: string) {
    this.editModeEntered$.next()
    return this.editedGeofenceId$.next(id)
  }

  public unselectGeofence() {
    if (this.editedGeofenceId$.value) {
      this.editedGeofenceId$.next(null)
      this.mapGuide?.hideSelectedOption()
      this.editModeExited$.next()
    }
  }

  public async createDraftGeofence(
    options: Partial<GeofenceMarkerOptions> & Pick<GeofenceMarkerOptions, 'latLng'>,
  ) {
    if (this.currentMarker$.value) {
      this.draw.deleteAll()
      this.currentMarker$.value.destroy()
    }

    const isAllowed = await this.accessControl.check(Resource.Location, Action.Create)
    if (!isAllowed) return

    this.currentMarker$.next(
      this.createMarker({
        ...options,
        name: options.name ?? '',
        step: options.step ?? GeofenceEditMarkerStep.SelectShape,
        id: this.DRAFT_FEATURE_ID,
        radiusInKm: options.type === LocationType.Address ? ADDRESS_LOCATION_RADIUS : undefined,
      }),
    )
    this.editModeEntered$.next()
    this.editedGeofenceId$.next(this.DRAFT_FEATURE_ID)
  }

  public getGeofenceVisualizer(): MapGeofenceVisualizer {
    return this.visualizer
  }

  protected init() {
    this.initDraw()

    // Event listeners
    this.initEditByDblClick()
    this.initCreateByRightClick()
    this.initCancelCreateByMapClick()
    this.initDeleteVerticesHandler()
    this.initAutoRecalculateMarkerOffset()
    this.initForceDirectSelectWhileEditing()
    this.initCursorSwitchWhenControlsHovered()
  }

  protected initDraw() {
    this.adapter.map.addControl(this.draw)

    // Reset the state when is disabled
    this.isEnabled$.pipe(takeUntil(this.destroy$)).subscribe((isEnabled) => {
      if (!isEnabled) {
        if (this.adapter.map.hasControl(this.draw)) {
          this.draw.deleteAll()
        }

        if (this.currentMarker$.value) {
          this.currentMarker$.value.destroy()
          this.currentMarker$.next(undefined)
        }

        if (this.editedGeofenceId$.value) {
          this.unselectGeofence()
        }
      }
    })

    // Automatically open geofence in editor
    combineLatest([this.editedGeofenceId$, this.dataSource.data$])
      .pipe(debounceTime(16), takeUntil(this.destroy$))
      .subscribe(([id, features]) => {
        // Do not execute for draft features
        if (id === this.DRAFT_FEATURE_ID) {
          return undefined
        }

        this.draw.deleteAll()
        if (id && this.isEnabled$.value) {
          const targetFeature = features.find((f) => f.properties.id === id)
          if (targetFeature) {
            this.addAndSelectFeatureInDraw(targetFeature)
          }
        }
      })

    this.editedGeofenceId$
      .pipe(distinctUntilChanged(), withLatestFrom(this.dataSource.data$), takeUntil(this.destroy$))
      .subscribe(([editedGeofenceId, geofences]) => {
        if (editedGeofenceId) {
          this.adapter.map.touchZoomRotate._tapDragZoom.disable()
          this.visualizer.hiddenGeofenceIds$.next([editedGeofenceId])
        } else {
          this.adapter.map.touchZoomRotate._tapDragZoom.enable()
          this.visualizer.hiddenGeofenceIds$.next([])
        }

        // Do not execute for draft features
        if (editedGeofenceId === this.DRAFT_FEATURE_ID) {
          return undefined
        }

        // Destroy previous marker if exists
        if (this.currentMarker$.value) {
          this.currentMarker$.value.destroy()
          this.currentMarker$.next(undefined)
        }

        // Create new marker and position in the center
        if (editedGeofenceId) {
          const targetGeofence = geofences.find((g) => g.properties.id === editedGeofenceId)
          if (targetGeofence) {
            const center = getCenter(targetGeofence)
            this.currentMarker$.next(
              this.createMarker({
                id: targetGeofence.properties.id,
                name: targetGeofence.properties.name,
                step: GeofenceEditMarkerStep.Edit,
                latLng: toLatLng(center.geometry.coordinates as Coordinates),
              }),
            )

            this.mapGuide?.showOption(
              targetGeofence.properties.isCircle
                ? MapGuideOptions.EditingCircleGeofence
                : MapGuideOptions.EditingPolygonGeofence,
            )
          }
        }
      })

    // Update the paint styles when the tiles source changes
    this.adapter.tilesSource$
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {
        const color = this.visualizer.geofenceColor
        const styles = (this.draw.options?.styles ?? []) as Record<string, any>[]
        styles.forEach((style) => {
          if (style?.paint['circle-color']) {
            style.paint['circle-color'] = color
            style.paint['circle-stroke-color'] = color
          }
          if (style?.paint['fill-color']) {
            style.paint['fill-color'] = color
            style.paint['fill-outline-color'] = color
          }
          if (style?.paint['line-color']) {
            style.paint['line-color'] = color
          }
        })
      })
  }

  protected createMarker(options: GeofenceMarkerOptions) {
    // TODO: Move marker initialization code to base GeofenceMarker class
    const marker = this.adapter.addMarker(MapLibreGeofenceMarker, options)

    // Approximate address on click
    if (!options.address && options.latLng) {
      this.api.fleet.locations
        .searchByPosition(toLngLat(options.latLng))
        .then((positionDetails) => {
          if (positionDetails.length) {
            const address = positionDetails[0].label
            options.address = address
            options.name = options.name || address
            marker.setName(options.name)
          }
        })
    }

    marker.setIsPinned(this.getIsGeofenceFormPinned())

    // Sync all geofence names to prevent name duplicates during create/edit
    this.dataSource.data$.pipe(takeUntil(marker.destroy$)).subscribe((data) => {
      marker.setNotAllowedNames(
        data
          .filter((location) => location.id !== marker.options.id)
          .map((location) => (location.properties.name ?? '').trim()),
      )
    })

    const subscriptions: Subscription[] = []

    // Initial pin icon click
    subscriptions.push(
      marker.events.activate$.subscribe(() => {
        // Initialize Address with circle geofence
        if (options.type === LocationType.Address) {
          marker.setStep(GeofenceEditMarkerStep.Create)
          this.addCircularGeofence(toLngLat(marker.getLatLng()), marker.options.radiusInKm, options)
          this.mapGuide?.hideSelectedOption()
        } else {
          marker.setStep(GeofenceEditMarkerStep.SelectShape)
        }
      }),
    )

    subscriptions.push(
      marker.events.save$.subscribe(async (event) => {
        const editedFeature = this.editedGeofenceId$.value
          ? (this.draw.get(this.editedGeofenceId$.value) as GeofenceFeature)
          : undefined

        if (editedFeature) {
          // Calculate the center to estimate address
          const center = editedFeature.properties.center
            ? editedFeature.properties.center
            : (getCenter(editedFeature.geometry).geometry.coordinates as Coordinates)
          const positionDetails = await this.api.fleet.locations.searchByPosition(center)
          if (positionDetails.length) {
            editedFeature.properties.address = positionDetails[0].label
          }

          // Validate circle radius
          if (editedFeature.properties.isCircle) {
            if (!editedFeature.properties.radiusInKm || !editedFeature.properties.center) {
              throw new Error(`Circle feature is invalid`)
            }
            if (editedFeature.properties.radiusInKm < this.MIN_CIRCLE_GEOFENCE_RADIUS) {
              editedFeature.properties.radiusInKm = this.MIN_CIRCLE_GEOFENCE_RADIUS
              editedFeature.geometry = circle(
                editedFeature.properties.center,
                editedFeature.properties.radiusInKm,
                { units: 'kilometers' },
              ).geometry
            }
            if (editedFeature.properties.radiusInKm > this.MAX_CIRCLE_GEOFENCE_RADIUS) {
              editedFeature.properties.radiusInKm = this.MAX_CIRCLE_GEOFENCE_RADIUS
              editedFeature.geometry = circle(
                editedFeature.properties.center,
                editedFeature.properties.radiusInKm,
                { units: 'kilometers' },
              ).geometry
            }
          }

          marker.store.isSaving$.next(true)

          let result: GeofenceFeature | undefined
          Object.assign(editedFeature.properties, event.data)
          if (this.DRAFT_FEATURE_ID === editedFeature.id) {
            result = await this.dataSource.addGeofence(editedFeature)
          } else {
            result = await this.dataSource.updateGeofence(editedFeature)
          }

          marker.store.isSaving$.next(false)

          // TODO: Find better place for this redirect
          if (result && event.setupAlert) {
            return this.router.navigate(['alerts', 'setup'], {
              queryParams: {
                locationId: result.properties.id,
                triggerType: AlertTriggerTypes.geofence_activity,
              },
            })
          }
        }

        this.unselectGeofence()
      }),
    )

    subscriptions.push(marker.events.cancel$.subscribe(() => this.unselectGeofence()))

    subscriptions.push(
      marker.events.delete$.subscribe(() => {
        const editedFeature = this.editedGeofenceId$.value
          ? this.draw.get(this.editedGeofenceId$.value)
          : undefined
        if (editedFeature) {
          this.dataSource.deleteGeofence(editedFeature as GeofenceFeature)
        }
        this.unselectGeofence()
      }),
    )

    subscriptions.push(
      marker.events.setShape$.subscribe((e) => {
        if (e === 'circle') {
          this.mapGuide?.showOption(MapGuideOptions.AddingCircleGeofence)
          this.addCircularGeofence(toLngLat(marker.getLatLng()), marker.options.radiusInKm, options)
        }
        if (e === 'polygon') {
          this.addSquareGeofence(toLngLat(marker.getLatLng()), options)
          this.mapGuide?.showOption(MapGuideOptions.AddingPolygonGeofence)
        }
        marker.setStep(GeofenceEditMarkerStep.Create)
      }),
    )

    subscriptions.push(
      marker.events.pinned$.subscribe((pinned) => {
        this.setIsGeofenceFormPinned(pinned)
        this.currentMarker$.value?.setIsPinned(pinned)
      }),
    )

    marker.destroy$.subscribe(() => subscriptions.forEach((sub) => sub.unsubscribe()))

    // Skip select-shape step
    if (options.type === LocationType.Address && options.step === GeofenceEditMarkerStep.Create) {
      timer(128)
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(() => marker.events.activate$.next())
    }

    return marker
  }

  protected initEditByDblClick() {
    this.visualizer.onDblClick$
      .pipe(
        withLatestFrom(
          this.editEnabled$,
          this.accessControl.check$(Resource.Location, Action.Update),
        ),
        filter(([, editEnabled, isAllowed]) => editEnabled && isAllowed),
        takeUntil(this.destroy$),
      )
      .subscribe(([id]) => this.selectGeofence(id))
  }

  protected initCancelCreateByMapClick() {
    const enableOnSteps = [GeofenceEditMarkerStep.MapPin, GeofenceEditMarkerStep.SelectShape]
    const listener = async () => {
      if (this.currentMarker$.value) {
        const step = await firstValueFrom(this.currentMarker$.value.store.step$)
        if (step === GeofenceEditMarkerStep.MapPin || step === GeofenceEditMarkerStep.SelectShape) {
          this.unselectGeofence()
        }
      }
    }

    let listenersOn = false
    const onListeners = () => {
      if (!listenersOn) {
        listenersOn = true
        this.adapter.map.on(this.deviceService.isMobilePlatform ? 'touchstart' : 'click', listener)
      }
    }
    const offListeners = () => {
      if (listenersOn) {
        listenersOn = false
        this.adapter.map.off(this.deviceService.isMobilePlatform ? 'touchstart' : 'click', listener)
      }
    }

    let markerStepSub: Subscription | undefined
    this.currentMarker$.pipe(takeUntil(this.destroy$)).subscribe((marker) => {
      // Disable previously added listeners
      offListeners()

      if (markerStepSub) {
        markerStepSub.unsubscribe()
        markerStepSub = undefined
      }

      if (marker) {
        markerStepSub = marker.store.step$.pipe(takeUntil(this.destroy$)).subscribe((step) => {
          if (enableOnSteps.includes(step)) {
            onListeners()
          } else {
            offListeners()
          }
        })
      }
    })
  }

  protected initCreateByRightClick() {
    const listener = async (e: MapMouseEvent | MapTouchEvent) => {
      const isAllowed = await this.accessControl.check(Resource.Location, Action.Update)
      if (!isAllowed || !this.editEnabled$.value) {
        return undefined
      }

      if (!this.currentMarker$.value && this.isEnabled$.value) {
        const latLng = [e.lngLat.lat, e.lngLat.lng] as Coordinates
        this.createDraftGeofence({ latLng: latLng })
        setTimeout(() => {
          this.adapter.moveTo(latLng, this.adapter.getZoomLevel())
        }, 250)
      }
    }

    const offLongTouchListener = addMaplibreLongTouchListener(this.adapter.map, undefined, listener)
    this.adapter.map.on('contextmenu', listener)
    this.destroy$.subscribe(() => {
      offLongTouchListener()
      this.adapter.map.off('contextmenu', listener)
    })
  }

  protected initDeleteVerticesHandler() {
    const MIN_VERTEX_COUNT = 4
    const HOT_LAYER_ID = 'gl-draw-polygon-and-line-vertex-active.hot'
    const COLD_LAYER_ID = 'gl-draw-polygon-and-line-vertex-active.cold'

    const listener = (e: MapMouseEvent | MapTouchEvent) => {
      const selected = this.draw.getSelected()
      if (
        !selected ||
        !selected.features.length ||
        // Do not delete vertex for circles
        selected.features[0]?.properties?.isCircle ||
        // Check to keep at least 3 vertex for polygons
        (selected.features[0]?.geometry as Polygon)?.coordinates[0].length <= MIN_VERTEX_COUNT
      ) {
        return
      }

      const target = e.type.startsWith('touch')
        ? (MapboxDraw.lib.mapEventToBoundingBox(e as any, 25) as [PointLike, PointLike])
        : e.point
      const features = this.adapter.map.queryRenderedFeatures(target, {
        layers: [HOT_LAYER_ID, COLD_LAYER_ID],
      })
      if (features.length) {
        const feature = features[0]
        if (feature.properties.meta === 'vertex' || feature.properties.meta === 'feature') {
          this.draw.trash()
        }
      }
    }

    const offLongTouchListener = addMaplibreLongTouchListener(this.adapter.map, undefined, listener)
    this.adapter.map.on('dblclick', HOT_LAYER_ID, listener)
    this.destroy$.subscribe(() => {
      offLongTouchListener()
      this.adapter.map.off('dblclick', HOT_LAYER_ID, listener)
    })
  }

  protected initForceDirectSelectWhileEditing() {
    const listener = (event: { mode: MapboxDraw.DrawMode }) => {
      const editedGeofenceId = this.editedGeofenceId$.getValue()
      if (editedGeofenceId && event.mode !== 'direct_select') {
        this.directSelectCurrentFeatureInDraw()
      }
    }
    this.adapter.map.on('draw.modechange', listener)
    this.destroy$.subscribe(() => this.adapter.map.off('draw.modechange', listener))
  }

  protected directSelectCurrentFeatureInDraw() {
    try {
      const collection = this.draw.getAll()
      if (collection.features.length) {
        const featureId = `${collection.features[0].id}`
        this.draw.changeMode('direct_select', { featureId })
      }
    } catch (error) {
      console.warn(`Cannot select feature in draw`, error)
    }
  }

  protected initAutoRecalculateMarkerOffset() {
    if (this.deviceService.isMobile()) return

    const recalculateMarkerOffset$ = new Subject<void>()
    recalculateMarkerOffset$
      .pipe(throttleTime(16, undefined, { trailing: true }), takeUntil(this.destroy$))
      .subscribe(() => this.recalculateMarkerPlacementAndOffset())

    const recalculateMarkerOffsetListener = () => recalculateMarkerOffset$.next()
    this.adapter.map.on('draw.create', recalculateMarkerOffsetListener)
    this.adapter.map.on('draw.update', recalculateMarkerOffsetListener)
    this.adapter.map.on('draw.drag-feature', recalculateMarkerOffsetListener)
    this.adapter.map.on('draw.drag-vertex', recalculateMarkerOffsetListener)
    this.adapter.map.on('zoom', recalculateMarkerOffsetListener)
    this.adapter.map.on('zoomend', recalculateMarkerOffsetListener)
    this.editedGeofenceId$.pipe(takeUntil(this.destroy$)).subscribe(recalculateMarkerOffsetListener)

    this.destroy$.subscribe(() => {
      this.adapter.map.off('draw.create', recalculateMarkerOffsetListener)
      this.adapter.map.off('draw.update', recalculateMarkerOffsetListener)
      this.adapter.map.off('draw.drag-feature', recalculateMarkerOffsetListener)
      this.adapter.map.off('draw.drag-vertex', recalculateMarkerOffsetListener)
      this.adapter.map.off('zoom', recalculateMarkerOffsetListener)
      this.adapter.map.off('zoomend', recalculateMarkerOffsetListener)
    })
  }

  protected initCursorSwitchWhenControlsHovered() {
    if (this.deviceService.isMobile()) return

    const mapEl = this.adapter.map
      .getContainer()
      .querySelector<HTMLDivElement>('div.maplibregl-interactive')

    if (mapEl) {
      const VERTEX_LAYER_ID = 'gl-draw-polygon-and-line-vertex-active.cold'
      const MIDPOINT_LAYER_ID = 'gl-draw-polygon-midpoint.cold'
      const QUERY_LAYERS = [VERTEX_LAYER_ID, MIDPOINT_LAYER_ID]

      const onMove$ = new Subject<MapMouseEvent>()
      onMove$
        .pipe(
          throttleTime(128, undefined, { trailing: true }),
          withLatestFrom(this.isEnabled$, this.editedGeofenceId$),
          filter(([, isEnabled, editedGeofenceId]) => Boolean(isEnabled && editedGeofenceId)),
          map(([event]) => {
            return [
              [event.point.x - this.DRAW_CLICK_BUFFER, event.point.y - this.DRAW_CLICK_BUFFER],
              [event.point.x + this.DRAW_CLICK_BUFFER, event.point.y + this.DRAW_CLICK_BUFFER],
              // TODO: Find better workaround for this hotfix
              // Have to use wrong type due to issue in MaplibreGL typescript definition
            ] as unknown as PointLike
          }),
          takeUntil(this.destroy$),
        )
        .subscribe((bbox) => {
          const features = this.adapter.map.queryRenderedFeatures(bbox, { layers: QUERY_LAYERS })
          const hasVertexPoint = features.some((f) => f.layer.id === VERTEX_LAYER_ID)
          const hasMidpointPoint = features.some((f) => f.layer.id === MIDPOINT_LAYER_ID)
          mapEl.style.cursor = hasVertexPoint ? 'move' : hasMidpointPoint ? 'pointer' : ''
        })

      const onMoveListener = (e: MapMouseEvent) => onMove$.next(e)
      this.adapter.map.on('mousemove', onMoveListener)
      this.destroy$.subscribe(() => {
        onMove$.complete()
        this.adapter.map.off('mousemove', onMoveListener)
      })
    }
  }

  // TODO: Optimize this method
  protected recalculateMarkerPlacementAndOffset() {
    if (this.editedGeofenceId$.value && this.currentMarker$.value) {
      const geofenceFeature = this.draw.get(this.editedGeofenceId$.value)
      if (geofenceFeature) {
        const bbox = getBbox(geofenceFeature)
        const center = getCenter(geofenceFeature)

        const topLeft = this.adapter.map.project([bbox[0], bbox[3]])
        const bottomLeft = this.adapter.map.project([bbox[0], bbox[1]])
        const sizePxY = Math.abs(topLeft.y - bottomLeft.y)
        const coordinates = center.geometry.coordinates as Coordinates

        let offset: number
        if (sizePxY >= this.adapter.map.getCanvas().offsetHeight * 0.75) {
          offset = 20 // Place popup at center of figure
        } else {
          offset = sizePxY / 2 // Place the popup at the bottom of figure
        }

        this.currentMarker$.value.setOffset(offset)
        this.currentMarker$.value.setLatLng(toLatLng(coordinates))
      }
    }
  }

  protected addSquareGeofence(lngLat: Coordinates, properties?: GeofenceData) {
    this.addAndSelectFeatureInDraw(
      getSquarePolygonFeature(
        this.DRAFT_FEATURE_ID,
        lngLat,
        this.adapter.map.getBounds(),
        properties,
      ),
    )
  }

  protected addCircularGeofence(lngLat: Coordinates, radius?: number, properties?: GeofenceData) {
    this.addAndSelectFeatureInDraw(
      getCircleFeatureFromCoords(
        this.DRAFT_FEATURE_ID,
        lngLat,
        {
          bounds: this.adapter.map.getBounds(),
          radius,
        },
        properties,
      ),
    )
  }

  protected addAndSelectFeatureInDraw(feature: Feature) {
    const featureId = feature.id || feature.properties?.id
    if (!feature) {
      console.error(`Feature id is missing`, feature)
      throw new Error(`Feature id is missing`)
    }

    this.draw.set({ type: 'FeatureCollection', features: [feature] })

    if (this.deviceService.isMobilePlatform) {
      // On mobile devices we need to select the feature after
      // the touch is ended to prevent drag glitch on iOS Safari PWA
      this.adapter.map.once('touchend', () => {
        timer(128)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => this.directSelectCurrentFeatureInDraw())
      })

      timer(64, 128)
        .pipe(take(5), takeUntil(this.destroy$))
        .subscribe(() => this.directSelectCurrentFeatureInDraw())
    } else {
      this.directSelectCurrentFeatureInDraw()
    }

    if (feature.geometry.type === 'Polygon') {
      this.adapter.fitBounds(
        feature.geometry.coordinates[0].map((lngLat) => toLatLng(lngLat as Coordinates)),
        featureId.id === this.DRAFT_FEATURE_ID
          ? this.adapter.getZoomLevel()
          : this.adapter.getMaxZoomLevel(),
        undefined,
        Math.min(
          this.adapter.map.getCanvas().offsetWidth,
          this.adapter.map.getCanvas().offsetHeight,
        ) * 0.375,
      )
    }

    if (!this.deviceService.isMobile()) {
      setTimeout(() => this.recalculateMarkerPlacementAndOffset(), 25)
    }
  }

  protected getIsGeofenceFormPinned(): boolean {
    const cached = this.browserStorage.getItem(this.GEOFENCE_PINNED_STORAGE_KEY)
    if (cached !== null) {
      try {
        return JSON.parse(cached)
      } catch (error) {
        console.error(error)
      }
    }
    return this.GEOFENCE_PINNED_BY_DEFAULT
  }

  protected setIsGeofenceFormPinned(value: boolean) {
    this.browserStorage.setItem(this.GEOFENCE_PINNED_STORAGE_KEY, JSON.stringify(value))
  }
}

// Get or create an GeofenceVisualizerInstance
const injectMapGeofenceVisualizer = () => {
  const existing = inject(MapGeofenceVisualizer, { optional: true })
  return (existing ?? new MaplibreGeofenceVisualizer()) as MaplibreGeofenceVisualizer
}
