import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  TemplateRef,
} from '@angular/core'
import { DateRange, FilterOptions, PreDefinedRange } from '@ti-platform/web/common'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { MenuItem } from 'primeng/api'
import { SidebarService } from '../../services'
import { DataGridColumn, DataGridSortOrder } from '../data-grid'
import { TagBarOption } from '../slider/slider.component'
import { AppFeatures, BookmarkDTO } from '@ti-platform/contracts'

@Component({
  selector: 'app-grid-controls',
  templateUrl: 'grid-controls.component.html',
  styleUrls: ['grid-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridControlsComponent implements OnChanges {
  @Output() searchChanged = new EventEmitter<string>()
  @Output() dateRangeChanged = new EventEmitter<DateRange | PreDefinedRange | undefined>()
  @Output() sortChanged = new EventEmitter<DataGridSortOrder>()
  @Output() filterChanged = new EventEmitter<Record<string, any>>()
  @Output() clearSearch = new EventEmitter<void>()
  @Output() clearFilter = new EventEmitter<void>()

  @Input() filterPanelStyle: 'default' | 'compact' | 'none' = 'default'
  @Input() breadcrumbs?: MenuItem[] | null
  @Input() bookmark?: BookmarkDTO | null = null
  @Input() columns?: DataGridColumn[] | null
  @Input() dateRange?: DateRange | PreDefinedRange | null
  @Input() filterOptions?: FilterOptions[] | null
  @Input() filterValue?: Record<string, any> | null
  @Input() search?: string | null = ''
  @Input() sortOrder?: DataGridSortOrder | null
  @Input() defaultSortOrder?: DataGridSortOrder | null

  @Input() returnButtonTemplate?: TemplateRef<unknown>
  @Input() extraControlsTemplate?: TemplateRef<unknown>
  @Input() leftExtraControlsTemplate?: TemplateRef<unknown>
  @Input() countTemplate?: TemplateRef<unknown>
  @Input() titleBadge?: TemplateRef<unknown>
  @Input() actionButtonsTemplate?: TemplateRef<unknown>
  @Input() mobileMenuButtonTemplate?: TemplateRef<unknown>

  @Input() titleText = ''
  @Input() descriptionText = ''
  @Input() showSearch = true
  @Input() showDatePicker = false
  @Input() showDatePickerTime = false
  @Input() showMobileMenuButton = true
  @Input() headerTextSize = 32 // Applied only on desktop

  protected readonly AppFeatures = AppFeatures

  protected filterTags?: TagBarOption[]
  protected filtersCount?: number
  protected readonly isSearchOpened = signal(!!this.search)

  protected readonly deviceService = inject(DeviceService)
  protected readonly sidebarService = inject(SidebarService)

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.filterOptions || changes.filterValue) {
      this.filterTags = this.prepareFilterTags(this.filterOptions, this.filterValue)
      this.filtersCount = this.countFiltersValues(this.filterValue)
    }

    if (changes.search && changes.search.currentValue) {
      this.isSearchOpened.set(true)
    }
  }

  protected prepareFilterTags(
    filterOptions?: FilterOptions[] | null,
    filterValue?: Record<string, any> | null,
  ): TagBarOption[] {
    let combinedFiltersData = new Array<TagBarOption>()
    if (filterOptions) {
      for (const filterOption of filterOptions) {
        const valuesArray = filterValue ? (filterValue[filterOption.key] ?? undefined) : undefined
        if (valuesArray) {
          combinedFiltersData = combinedFiltersData.concat(
            valuesArray
              .map((value: unknown) => {
                return filterOption.options.find((option) => option.value === value)
              })
              .filter((obj: unknown) => obj !== undefined),
          )
        }
      }
    }
    return combinedFiltersData
  }

  protected countFiltersValues(filter?: Record<string, any> | null): number {
    let count = 0
    if (filter) {
      for (const key in filter) {
        count += filter[key]?.length ?? 0
      }
    }
    return count
  }

  protected removeFilter(event: TagBarOption) {
    const filters = {
      ...this.filterValue,
    }
    const filterKeys = Object.keys(filters)
    const newFilters: any = {}

    for (const key of filterKeys) {
      if (Array.isArray(filters[key])) {
        newFilters[key] = filters[key].filter((val: string) => {
          return val !== event.value
        })
      }
    }

    const count = this.countFiltersValues(newFilters)
    if (count > 0) {
      this.filterChanged.emit(newFilters)
    } else {
      this.clearFilter.emit()
    }
  }
}
