<header class="header-panel bordered">
  <div class="heading-group flex">
    <p-button
      (click)="sidebarService.show()"
      [severity]="'secondary'"
      class="menu-button h--40 show-on--mobile"
      icon="pi pi-bars"
    ></p-button>
    <ng-container *ngIf="returnButtonTemplate">
      <ng-container [ngTemplateOutlet]="returnButtonTemplate"></ng-container>
    </ng-container>
    <section class="flex flex--col">
      <h1 class="text-32 fw-600">{{ title | translate }}</h1>
      <span *ngIf="description" class="text-light-description--panel hide-on--mobile">
        {{ description | translate }}
      </span>
    </section>
    <ng-container *ngIf="countTemplate">
      <ng-container [ngTemplateOutlet]="countTemplate"></ng-container>
    </ng-container>
  </div>

  <div class="controls">
    <ng-container *ngIf="actionButtonsTemplate">
      <ng-container [ngTemplateOutlet]="actionButtonsTemplate"></ng-container>
    </ng-container>
  </div>
</header>
