<div
  [ngClass]="{
    highlighted: isHighlighted(),
    selected: selectedBookmarkId === bookmark.id,
  }"
  class="bookmark-item"
  (click)="bookmarks.navigateToBookmark(bookmark)"
>
  <header class="mb-6">
    <section class="content">
      <app-fleet-bookmarks-type-icon
        [type]="bookmark.type"
        class="mr-6"
      ></app-fleet-bookmarks-type-icon>
      <h3 class="name text-16 fw-600">
        {{ bookmark.name }}
      </h3>
    </section>
    <i
      (click)="menuRef.toggle($event); $event.stopPropagation(); clickAtBody()"
      class="icon pi pi-ellipsis-v clickable"
    ></i>
  </header>
  <p-menu
    #menuRef
    [model]="(options$ | async | translateLabels) ?? []"
    [popup]="true"
    appendTo="body"
  ></p-menu>
</div>
