import { NgModule } from '@angular/core'
import { InfoSolidIconComponent } from '@ti-platform/web/ui-kit/icons/components/info-solid/info-solid-icon.component'
import { TripDistanceIconComponent } from '@ti-platform/web/ui-kit/icons/components/trip-distance/trip-distance-icon.component'
import { TripDrivingTimeIconComponent } from '@ti-platform/web/ui-kit/icons/components/trip-driving-time/trip-driving-time-icon.component'
import {
  AlertsIconComponent,
  AreaIconComponent,
  ArrowBlockSolidIconComponent,
  ArrowIconComponent,
  BarChart2IconComponent,
  BatteryIconComponent,
  BicycleIconComponent,
  BillingIconComponent,
  Bus1IconComponent,
  Bus1SideIconComponent,
  Bus2IconComponent,
  BusDoubleIconComponent,
  BusIconComponent,
  CSVFileColorfulIconComponent,
  CSVFileIconComponent,
  CalendarBoldIconComponent,
  CameraIconComponent,
  Car1IconComponent,
  CarCargoIconComponent,
  CarIconComponent,
  CarSolid1IconComponent,
  CarSolidIconComponent,
  ChangeIconComponent,
  ChatsIconComponent,
  CheckIconComponent,
  ChevronIconComponent,
  CircleIconComponent,
  Clock1IconComponent,
  ClockIconComponent,
  ClockSolidIconComponent,
  CloseIconComponent,
  CogIconComponent,
  CommandIconComponent,
  CompassIconComponent,
  CopyIconComponent,
  CopyOutlinedIconComponent,
  CustomizeIconComponent,
  DateIconComponent,
  DeleteIconComponent,
  DeviceAddIconComponent,
  DeviceIconComponent,
  DeviceRemoveIconComponent,
  DispatchIconComponent,
  DistanceIconComponent,
  DollarIconComponent,
  DownloadIconComponent,
  DriversIconComponent,
  EmailIconComponent,
  EngineHoursIconComponent,
  ExportIconComponent,
  FavoritesIconComponent,
  Feedback2IconComponent,
  FeedbackIconComponent,
  FileSearchIconComponent,
  FiltersActiveIconComponent,
  FiltersIconComponent,
  FinishIconComponent,
  FlagBoldIconComponent,
  FlagCrossIconComponent,
  FleetIconComponent,
  FleetsCountIconComponent,
  FuelIconComponent,
  FuelLevelIconComponent,
  GalleryIconComponent,
  GarageIconComponent,
  GeneralIconComponent,
  GeofenceBoldIconComponent,
  GeofenceIconComponent,
  Group1IconComponent,
  Group2IconComponent,
  Group3IconComponent,
  IgnitionIconComponent,
  InfoIconComponent,
  LanguageIconComponent,
  LaunchIconComponent,
  LineDashedIconComponent,
  LockClosedIconComponent,
  LockOpenedIconComponent,
  LogIconComponent,
  MaintenanceIconComponent,
  MapPinIconComponent,
  MapPinOutlinedIconComponent,
  MapStyleIconComponent,
  MaximizeIconComponent,
  MclarenIconComponent,
  MileageIconComponent,
  MinimizeIconComponent,
  MinusCircleIconComponent,
  MinusIconComponent,
  MinusSolidIconComponent,
  NewOutlinedIconComponent,
  NoAlertIconComponent,
  NoDevicesIconComponent,
  NoGeofenceIconComponent,
  NoSearchIconComponent,
  PayDisableIconComponent,
  PencilIconComponent,
  PlaySolidIconComponent,
  PlusIconComponent,
  PoliceIconComponent,
  PolygonIconComponent,
  RecycleBinDisableIconComponent,
  RecycleBinIconComponent,
  ReportsIconComponent,
  RotateIconComponent,
  RouteIconComponent,
  RpmIconComponent,
  SafariSymbolIconComponent,
  SatelliteIconComponent,
  ScooterIconComponent,
  SearchIconComponent,
  SendIconComponent,
  SettingsIconComponent,
  SignOutIconComponent,
  SpeedometerSolidIconComponent,
  StopIconComponent,
  StopIconOutlineComponent,
  StopSolid2IconComponent,
  StopSolidIconComponent,
  SwitchVerticalIconComponent,
  Taxi1IconComponent,
  TaxiIconComponent,
  TemperatureIconComponent,
  TimeStoppedIconComponent,
  TimelineHandlesIconComponent,
  TpmsIconComponent,
  TrolleybusIconComponent,
  TruckCargoBigIconComponent,
  TruckCargoIconComponent,
  TruckHighwayIconComponent,
  TruckIconComponent,
  UploadIconComponent,
  UserIconComponent,
  UserSolidIconComponent,
  UsersIconComponent,
  VehiclesIconComponent,
  Video1IconComponent,
  VideoIconComponent,
  WarningCircleIconComponent,
  WarningIconComponent,
  WaterIconComponent,
  WhiteLabelIconComponent,
  WrenchIconComponent,
  XLSFileIconComponent,
} from './components'
import { BaseIconComponent } from './components/base-icon.component'

export const ICONS = [
  AlertsIconComponent,
  AreaIconComponent,
  ArrowBlockSolidIconComponent,
  ArrowIconComponent,
  BarChart2IconComponent,
  BaseIconComponent,
  BatteryIconComponent,
  BicycleIconComponent,
  BillingIconComponent,
  Bus1IconComponent,
  Bus1SideIconComponent,
  Bus2IconComponent,
  BusDoubleIconComponent,
  BusIconComponent,
  CSVFileColorfulIconComponent,
  CSVFileIconComponent,
  CalendarBoldIconComponent,
  CameraIconComponent,
  Car1IconComponent,
  CarCargoIconComponent,
  CarIconComponent,
  CarSolid1IconComponent,
  CarSolidIconComponent,
  ChangeIconComponent,
  ChatsIconComponent,
  CheckIconComponent,
  ChevronIconComponent,
  CircleIconComponent,
  Clock1IconComponent,
  ClockIconComponent,
  ClockSolidIconComponent,
  CloseIconComponent,
  CogIconComponent,
  CommandIconComponent,
  CompassIconComponent,
  CopyIconComponent,
  CopyOutlinedIconComponent,
  CustomizeIconComponent,
  DateIconComponent,
  DeleteIconComponent,
  DeviceAddIconComponent,
  DeviceIconComponent,
  DeviceRemoveIconComponent,
  DispatchIconComponent,
  DistanceIconComponent,
  DollarIconComponent,
  DownloadIconComponent,
  DriversIconComponent,
  EmailIconComponent,
  EngineHoursIconComponent,
  ExportIconComponent,
  FavoritesIconComponent,
  Feedback2IconComponent,
  FeedbackIconComponent,
  FileSearchIconComponent,
  FiltersActiveIconComponent,
  FiltersIconComponent,
  FinishIconComponent,
  FlagBoldIconComponent,
  FlagCrossIconComponent,
  FleetIconComponent,
  FleetsCountIconComponent,
  FuelIconComponent,
  FuelLevelIconComponent,
  GalleryIconComponent,
  GarageIconComponent,
  GeneralIconComponent,
  GeofenceBoldIconComponent,
  GeofenceIconComponent,
  Group1IconComponent,
  Group2IconComponent,
  Group3IconComponent,
  IgnitionIconComponent,
  InfoIconComponent,
  InfoSolidIconComponent,
  LanguageIconComponent,
  LaunchIconComponent,
  LineDashedIconComponent,
  LockClosedIconComponent,
  LockOpenedIconComponent,
  LogIconComponent,
  MaintenanceIconComponent,
  MapPinIconComponent,
  MapPinOutlinedIconComponent,
  MapStyleIconComponent,
  MaximizeIconComponent,
  MclarenIconComponent,
  MileageIconComponent,
  MinimizeIconComponent,
  MinusCircleIconComponent,
  MinusIconComponent,
  MinusSolidIconComponent,
  NewOutlinedIconComponent,
  NoAlertIconComponent,
  NoDevicesIconComponent,
  NoGeofenceIconComponent,
  NoSearchIconComponent,
  PayDisableIconComponent,
  PencilIconComponent,
  PlaySolidIconComponent,
  PlusIconComponent,
  PoliceIconComponent,
  PolygonIconComponent,
  RecycleBinDisableIconComponent,
  RecycleBinIconComponent,
  ReportsIconComponent,
  RotateIconComponent,
  RouteIconComponent,
  RpmIconComponent,
  SafariSymbolIconComponent,
  SatelliteIconComponent,
  ScooterIconComponent,
  SearchIconComponent,
  SendIconComponent,
  SettingsIconComponent,
  SignOutIconComponent,
  SpeedometerSolidIconComponent,
  StopIconComponent,
  StopIconOutlineComponent,
  StopSolid2IconComponent,
  StopSolidIconComponent,
  SwitchVerticalIconComponent,
  Taxi1IconComponent,
  TaxiIconComponent,
  TemperatureIconComponent,
  TimeStoppedIconComponent,
  TimelineHandlesIconComponent,
  TpmsIconComponent,
  TripDistanceIconComponent,
  TripDrivingTimeIconComponent,
  TrolleybusIconComponent,
  TruckCargoBigIconComponent,
  TruckCargoIconComponent,
  TruckHighwayIconComponent,
  TruckIconComponent,
  UploadIconComponent,
  UserIconComponent,
  UserSolidIconComponent,
  UsersIconComponent,
  VehiclesIconComponent,
  Video1IconComponent,
  VideoIconComponent,
  WarningCircleIconComponent,
  WarningIconComponent,
  WaterIconComponent,
  WhiteLabelIconComponent,
  WrenchIconComponent,
  XLSFileIconComponent,
]

@NgModule({
  imports: [],
  exports: [...ICONS],
  declarations: [...ICONS],
})
export class IconsModule {}
