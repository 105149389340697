import { Feature, Polygon } from 'geojson'
import { BehaviorSubject, Observable, Subject } from 'rxjs'
import { LocationType } from '@ti-platform/contracts'
import { GeofenceEditMarkerStep } from '../components'
import { Coordinates } from '../contracts'
import { GeofenceMarkerOptions } from '../markers'

export type GeofenceData = {
  id: string
  name?: string
  type?: LocationType
  address?: string
  // Circle properties
  isCircle?: boolean
  center?: Coordinates
  radiusInKm?: number
}
export type GeofenceFeature<T = GeofenceData> = Feature<Polygon, T>

export interface GeofenceMarkerState {
  step$: Observable<GeofenceEditMarkerStep>
  name$: Observable<string>
  offset$: Observable<number>
  isSaving$: Observable<boolean>
  isPinned$: Observable<boolean>
  notAllowedNames$: Observable<string[]>
}

export interface GeofenceMarkerEvents {
  activate$: Subject<void>
  cancel$: Subject<void>
  delete$: Subject<void>
  save$: Subject<{ data: { name?: string }; setupAlert?: boolean }>
  setShape$: Subject<'polygon' | 'circle'>
  pinned$: Subject<boolean>
}

export abstract class MapGeofenceDataSource {
  public onCreated = new Subject<GeofenceFeature>()
  public onUpdated = new Subject<GeofenceFeature>()
  public onDeleted = new Subject<GeofenceFeature>()
  public abstract data$: Observable<GeofenceFeature[]>
  public readonly dataFilterCb$ = new BehaviorSubject<
    ((items: GeofenceFeature[]) => GeofenceFeature[]) | undefined
  >(undefined)
}

export abstract class MapGeofenceEditDataSource extends MapGeofenceDataSource {
  public abstract addGeofence(data: GeofenceFeature): Promise<GeofenceFeature | undefined>
  public abstract deleteGeofence(data: GeofenceFeature): unknown
  public abstract updateGeofence(data: GeofenceFeature): Promise<GeofenceFeature | undefined>
}

export abstract class MapGeofenceVisualizer {
  public readonly onTap$ = new Subject<string>()
  public readonly onDblClick$ = new Subject<string>()

  public abstract isEnabled$: BehaviorSubject<boolean>
  public abstract enablePinMarkers$: BehaviorSubject<boolean>
  public abstract hiddenGeofenceIds$: BehaviorSubject<string[]>
  public abstract refreshDataSource$: Subject<void>
  public abstract fitMapToViewAll(padding?: number): void
  protected abstract dataSource: MapGeofenceDataSource
  protected abstract destroy$: Observable<void>
}

export abstract class MapGeofenceManager {
  public abstract isEnabled$: BehaviorSubject<boolean>
  public abstract editEnabled$: BehaviorSubject<boolean>
  public abstract editedGeofenceId$: BehaviorSubject<string | null>
  public abstract editModeExited$: Observable<void>
  public abstract editModeEntered$: Observable<void>
  public abstract selectGeofence(id: string): unknown
  public abstract unselectGeofence(): unknown
  public abstract createDraftGeofence(
    options: Partial<GeofenceMarkerOptions> & Pick<GeofenceMarkerOptions, 'latLng'>,
  ): unknown
  public abstract getGeofenceVisualizer(): MapGeofenceVisualizer
  protected abstract destroy$: Observable<void>
}
