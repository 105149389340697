import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core'
import { AppFeatures } from '@ti-platform/contracts'
import { ExportFormat, injectDestroy$, Memoize, PricingPlanService } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { MenuItem } from 'primeng/api'
import { BehaviorSubject, combineLatest, map, Observable, takeUntil } from 'rxjs'

@Component({
  selector: 'app-export-btn',
  templateUrl: 'export-btn.component.html',
  styleUrls: ['export-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExportBtnComponent implements OnInit {
  @Output() export = new EventEmitter<ExportFormat>()
  @Input() severity: 'primary' | 'secondary' = 'secondary'
  @Input() compact?: boolean
  protected readonly languageService = inject(LanguageService)
  protected readonly pricingPlan = inject(PricingPlanService)
  protected readonly destroy$ = injectDestroy$()
  protected readonly ExportFormat = ExportFormat

  protected readonly isPdfAllowed$ = new BehaviorSubject<boolean>(false)

  @Memoize()
  get items$(): Observable<MenuItem[]> {
    return combineLatest([
      this.languageService.massTranslate$({
        exportXls: 'export.xls',
        exportCsv: 'export.csv',
        exportPDF: 'export.pdf',
      }),
      this.isPdfAllowed$,
    ]).pipe(
      takeUntil(this.destroy$),
      map(([labels, isPdfAllowed]) => {
        const items = [
          {
            type: ExportFormat.Excel,
            label: labels.exportXls,
            command: () => {
              this.exportExcel()
            },
          },
          {
            type: ExportFormat.CSV,
            label: labels.exportCsv,
            command: () => {
              this.exportCsv()
            },
          },
        ]
        if (isPdfAllowed) {
          items.push({
            type: ExportFormat.PDF,
            label: labels.exportPDF,
            command: () => {
              this.exportPDF()
            },
          })
        }
        return items
      }),
    )
  }

  protected exportExcel() {
    this.export.emit(ExportFormat.Excel)
  }

  protected exportCsv() {
    this.export.emit(ExportFormat.CSV)
  }

  protected exportPDF() {
    this.export.emit(ExportFormat.PDF)
  }

  async ngOnInit() {
    const isPdfAllowed = await this.pricingPlan.areFeaturesAllowed([AppFeatures.Video])
    this.isPdfAllowed$.next(isPdfAllowed)
  }
}
