import { inject, Injectable, OnDestroy } from '@angular/core'
import { injectDestroy$ } from '@ti-platform/web/common'
import { MapAdapter, MapLibreMapAdapter } from '../adapters'
import { AbstractAlertMarker, AlertMarkerOptions, MaplibreAlertMarker } from '../markers/alert'
import { Coordinates } from '@ti-platform/web/map'

export abstract class MapAlertsManager {
  public abstract fitMapToViewAll(): void
  public abstract addAlertMarker(options: AlertMarkerOptions): AbstractAlertMarker
}

@Injectable()
export class MaplibreAlertManager extends MapAlertsManager implements OnDestroy {
  protected readonly adapter = inject(MapAdapter) as MapLibreMapAdapter
  protected readonly destroy$ = injectDestroy$()

  protected readonly markers = new Map<string, MaplibreAlertMarker>()

  public fitMapToViewAll() {
    const boundaries: Coordinates[] = Array.from(this.markers.values()).reduce((carry, item) => {
      return carry.concat(item.getFullRoute())
    }, new Array<Coordinates>())

    this.adapter.fitBounds(boundaries, 17, 20)
  }

  public addAlertMarker(options: AlertMarkerOptions): AbstractAlertMarker {
    const marker = this.adapter.addMarker(MaplibreAlertMarker, options)
    this.markers.set(options.alertId, marker)
    return marker
  }

  public ngOnDestroy() {
    this.markers.forEach((marker) => marker.destroy())
    this.markers.clear()
  }
}
