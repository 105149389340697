<div
  class="text-wrapper"
  [class.disabled]="disabled"
  [ngClass]="{ 'edit-mode': isEditMode, 'keep-pencil-icon': keepPencilIcon }"
>
  @if (isEditMode) {
    <div class="text" (click)="$event.stopPropagation()">
      <p-iconField iconPosition="right" [class]="{ loading: isLoading }">
        <input
          #textEditInput
          class="h--34 edit-value-input"
          type="text"
          pInputText
          [value]="value$.value"
          [maxLength]="maxLength"
          [minLength]="minLength"
          [disabled]="isLoading"
          (input)="value$.next(textEditInput.value)"
          (keyup.enter)="onSubmit()"
        />
        <p-inputIcon>
          <i [class]="cancelBtnStyleClass" (click)="onCancel(); $event.stopPropagation()"></i>
          <i [class]="editBtnStyleClass" (click)="onSubmit(); $event.stopPropagation()"></i>
        </p-inputIcon>
      </p-iconField>
    </div>
  } @else {
    <div class="text">
      <div class="overflow-wrapper">
        {{ value }}
      </div>

      @if (!disabled) {
        <i
          class="pi pi-pencil"
          [class.clickable]="!isLoading"
          (click)="enterEdit.emit(); $event.stopPropagation()"
        ></i>
      }
    </div>
  }
</div>
