import { inject, NgModule, Provider } from '@angular/core'
import { LOGIN_AS_SESSION } from '@ti-platform/web/auth'
import { BrowserLocalStorage } from './models/storage/local.storage'
import { BrowserSessionStorage } from './models/storage/session.storage'
import { CheckAccessDirective, CheckPricingPlanDirective } from './directives'
import { ClearBaseUrlPipe, FormatCommaSeparatedPipe } from '@ti-platform/web/common/pipes'

const DIRECTIVES = [CheckAccessDirective, CheckPricingPlanDirective]
const PIPES = [FormatCommaSeparatedPipe, ClearBaseUrlPipe]
const PROVIDERS: Provider[] = [
  {
    provide: BrowserLocalStorage,
    useFactory: () => {
      const activeLoginAsSession = inject(LOGIN_AS_SESSION, { optional: true })
      if (activeLoginAsSession) {
        // Overwrite LocalStorage with SessionStorage for Login As session
        return sessionStorage
      }
      return new BrowserLocalStorage()
    },
    deps: [BrowserSessionStorage],
  },
  BrowserSessionStorage,
]

@NgModule({
  imports: [],
  exports: [...DIRECTIVES, ...PIPES],
  providers: [...PROVIDERS],
  declarations: [...DIRECTIVES, ...PIPES],
})
export class AppCommonModule {}
