import { inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import * as Sentry from '@sentry/angular'
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  ReplaySubject,
  takeUntil,
} from 'rxjs'
import { MyProfile, PricingPlans, UserRole } from '@ti-platform/contracts'
import { ApiService, HttpException } from '@ti-platform/web/api'
import { AuthService } from '@ti-platform/web/auth'
import { CONFIG, injectDestroy$, Memoize } from '@ti-platform/web/common'
import { AuthSession } from './auth.session'

@Injectable({
  providedIn: 'root',
})
export class Profile {
  protected readonly api = inject(ApiService)
  protected readonly authService = inject(AuthService)
  protected readonly authSession = inject(AuthSession)
  protected readonly config = inject(CONFIG)
  protected readonly router = inject(Router)
  protected readonly destroy$ = injectDestroy$()

  protected readonly data$ = new ReplaySubject<MyProfile>(1)

  public constructor() {
    // Temporary solution for resetting app state
    this.authSession.state.isAuthenticated$
      .pipe(distinctUntilChanged())
      .subscribe(async (isAuthenticated) => {
        if (isAuthenticated) {
          await this.loadData()
        }
      })

    if (this.config.sentry.enabled) {
      this.data$
        .pipe(filter(Boolean), debounceTime(2048), takeUntil(this.destroy$))
        .subscribe(async (data) => {
          const tsp = data.tspId ? await this.api.myProfile.getTspView() : undefined
          Sentry.setTags({
            tsp_id: data.tspId ?? '',
            tsp_name: tsp?.name ?? '',
            fleet_id: data.fleetId ?? '',
            fleet_name: data.fleet?.name ?? '',
            fleet_pricing_plan_id: data.fleet?.pricingPlanId ?? '',
            fleet_pricing_plan_name: data.fleet?.pricingPlanId
              ? (PricingPlans[data.fleet.pricingPlanId] ?? '')
              : '',
            user_role_id: data.roleId ?? '',
            user_role_name: data.role?.name ?? '',
          })
        })
    }
  }

  @Memoize()
  public get state(): Observable<MyProfile> {
    return this.data$.pipe(distinctUntilChanged())
  }

  @Memoize()
  public get isDemoUser$(): Observable<boolean> {
    return this.state.pipe(
      takeUntil(this.destroy$),
      map((profile) => profile?.roleId === UserRole.FleetDemo),
    )
  }

  @Memoize()
  public get roleLabelKey$(): Observable<string> {
    return this.state.pipe(
      takeUntil(this.destroy$),
      map((user) => (user ? `users.roles.${user.roleId}` : '')),
    )
  }

  public refresh() {
    this.loadData().catch((error) => {
      console.error(`Cannot refresh profile data`, error)
    })
  }

  public async isDemoUser() {
    return firstValueFrom(this.isDemoUser$)
  }

  protected async loadData() {
    try {
      this.data$.next(await this.api.myProfile.getData())
    } catch (error) {
      console.error(`Error loading user data`, error)
      if (
        error instanceof HttpException &&
        (error.getStatus() === 401 || error.getStatus() === 403)
      ) {
        return this.authService.signOut()
      }
    }
  }
}
