import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import {
  Action,
  DevicesGridData,
  FleetDisplayData,
  FleetView,
  Resource,
  TspDeviceListData,
} from '@ti-platform/contracts'
import { DeviceProcessingStatuses } from '@ti-platform/web/api/scopes/tsp/routes/devices.route'
import {
  BrowserSessionStorage,
  ExportFormat,
  injectDestroy$,
  Memoize,
  selectState,
  sleep,
} from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { DateFormatService } from '@ti-platform/web/ui-kit/i18n/services'
import { DataGridSortOrder } from '@ti-platform/web/ui-kit/layout/components'
import { DialogFacade } from '@ti-platform/web/ui-kit/layout/services'
import { MenuItem, MessageService } from 'primeng/api'
import { BehaviorSubject, map, Observable, takeUntil } from 'rxjs'
import {
  defaultEditNameStatus,
  defaultTestStatus,
  DevicesListModel,
  DevicesTypesDataProvider,
  editNameStatus,
  MAX_NAME_LENGTH,
  MIN_NAME_LENGTH,
  testStatus,
} from '../../models'

@Component({
  selector: 'app-tsp-devices-list',
  templateUrl: 'devices-list.component.html',
  styleUrls: ['devices-list.component.scss'],
})
export class DevicesListComponent implements OnInit {
  protected readonly dialogFacade = inject(DialogFacade)
  protected readonly dateFormatService = inject(DateFormatService)
  protected readonly devicesTypesDataProvider = inject(DevicesTypesDataProvider)
  protected readonly devicesViewModel = inject(DevicesListModel)
  protected readonly state = selectState(DevicesListModel)
  protected readonly languageService = inject(LanguageService)
  protected readonly messageService = inject(MessageService)
  protected readonly router = inject(Router)
  protected readonly sessionStorage = inject(BrowserSessionStorage)
  protected readonly showAddDeviceDialog$ = new BehaviorSubject(false)
  protected readonly showLoadDeviceListDialog$ = new BehaviorSubject(false)
  protected readonly showBulkAssignDevicesDialog$ = new BehaviorSubject(false)
  protected readonly destroy$ = injectDestroy$()

  protected readonly Action = Action
  protected readonly Resource = Resource

  protected deviceEditNameStatus: editNameStatus = {
    ...defaultEditNameStatus,
  }

  protected deviceTestStatus: testStatus = { ...defaultTestStatus }

  protected gridOrder: DataGridSortOrder = {
    column: 'createdAt',
    order: 'DESC',
  }

  protected lastEditFormDevice: TspDeviceListData | null = null

  @Output() public devicesChanged = new EventEmitter()
  @Input() public displayColumns = [
    'name',
    'model',
    'identifier',
    'sim',
    'createdAt',
    'fleetName',
    'server',
    'lastActive',
    'deviceTest',
    'actions',
  ]
  @Input() public headerClass = ''
  @Input() public showDevicesCount = true
  @Input() public showManageCommands = true

  protected addMenuItems: MenuItem[] = this.getAddMenuItems(null)

  protected getAddMenuItems(fleet: FleetView | null): MenuItem[] {
    return [
      {
        type: 'add',
        label: 'tsp-admin.devices.add.add-new',
        command: () => this.onAddOneClick(),
        disabled: false,
      },
      {
        type: 'upload',
        label: 'tsp-admin.devices.add.upload-list',
        command: () => this.onAddManyClick(),
        disabled: !!fleet?.isDemo,
      },
    ]
  }

  public async ngOnInit() {
    const promises: Promise<void>[] = []
    promises.push(this.devicesTypesDataProvider.getDevicesTypesData())
    promises.push(this.devicesViewModel.getAssignRecentFleets())
    promises.push(this.devicesViewModel.loadRecentBillableFleets())
    await Promise.all(promises)

    this.devicesViewModel.deviceDeletedEvent$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.devicesChanged.emit()
    })

    this.devicesViewModel.selectedFleet$.pipe(takeUntil(this.destroy$)).subscribe((fleet) => {
      this.addMenuItems = this.getAddMenuItems(fleet)
    })
  }

  public async onDeviceAdded(result: boolean) {
    const messageKey = result
      ? 'tsp-admin.devices.add.success'
      : 'tsp-admin.devices.add.with-errors'
    const message = await this.languageService.translate(messageKey)
    this.messageService.add({
      severity: result ? 'success' : 'warn',
      detail: message,
      life: 5000,
    })
    this.devicesViewModel.refresh()
    this.showAddDeviceDialog$.next(false)
    this.devicesViewModel.loadCount()
    this.devicesChanged.emit()
  }

  public async onDevicesAdded(result: { added: number; all: number }) {
    const messageKey =
      result.all === result.added
        ? 'tsp-admin.devices.add.load-list.added-success'
        : 'tsp-admin.devices.add.load-list.added-warn'
    const message = await this.languageService.translate(messageKey, {
      addedNumber: result.added,
      allNumber: result.all,
    })
    this.messageService.add({
      severity: result.all === result.added ? 'success' : 'warn',
      detail: message,
      life: 5000,
    })
    await this.onLoadDevices()
  }

  public async onLoadDevices() {
    // possible that several devices were added before process was aborted
    this.devicesViewModel.refresh()
    this.showLoadDeviceListDialog$.next(false)
    this.devicesViewModel.loadCount()
    this.devicesChanged.emit()
  }

  protected cancelEditName() {
    if (!this.deviceEditNameStatus.isLoading) {
      this.deviceEditNameStatus = { ...defaultEditNameStatus }
    }
  }

  protected async onAddClick() {
    this.cancelEditName()
  }

  protected async onAddOneClick() {
    await this.devicesViewModel.getAssignRecentFleets()
    this.showAddDeviceDialog$.next(true)
  }

  protected async onAddManyClick() {
    this.showLoadDeviceListDialog$.next(true)
  }

  protected async onAssignBulkClick() {
    await this.devicesViewModel.getAssignRecentFleets()
    this.showBulkAssignDevicesDialog$.next(true)
  }

  protected onChangeName(data: { device: DevicesGridData; newName: string }) {
    const validValue =
      data.newName.length >= MIN_NAME_LENGTH && data.newName.length <= MAX_NAME_LENGTH
    this.setDeviceEditNameStatus({
      isLoading: false,
      device: data.device,
      editValue: data.newName,
      validValue,
    })
  }

  protected onClearSearch() {
    this.cancelEditName()
    this.onSearch('')
  }

  protected onClickEditName(data: DevicesGridData) {
    this.setDeviceEditNameStatus({
      isLoading: false,
      device: data,
      editValue: data.name,
      validValue: true,
    })
  }

  protected async onClickManageCommands() {
    this.cancelEditName()
    return this.router.navigate(['devices', 'commands'])
  }

  protected async onClickMediaUsage() {
    this.cancelEditName()
    return this.router.navigate(['devices', 'media-usage'])
  }

  protected async onDeviceEdited(result: boolean) {
    const messageKey = result
      ? 'tsp-admin.devices.edit.success'
      : 'tsp-admin.devices.edit.with-errors'
    const message = await this.languageService.translate(messageKey)
    this.messageService.add({
      severity: result ? 'success' : 'warn',
      detail: message,
      life: 5000,
    })
    this.devicesViewModel.unselect()
    this.devicesViewModel.refresh()
    this.devicesViewModel.loadCount()
    this.devicesChanged.emit()
  }

  protected onMenuClick() {
    this.cancelEditName()
  }

  protected async onSaveDeviceName({
    device,
    savedName,
  }: {
    device: DevicesGridData
    savedName: string
  }) {
    const lastEditStatus = this.deviceEditNameStatus
    if (
      lastEditStatus?.device?.id == device.id &&
      !lastEditStatus?.isLoading &&
      lastEditStatus.validValue
    ) {
      this.deviceEditNameStatus = { ...lastEditStatus, isLoading: true }
      try {
        const result = await this.devicesViewModel.editDevice(device.id, { name: savedName })
        if (result.status === DeviceProcessingStatuses.done_completely) {
          await sleep(200)
          this.deviceEditNameStatus = { ...defaultEditNameStatus }
          if (this.gridOrder?.column === 'name') {
            // research because of sort by name and name was changed
            this.devicesViewModel.refresh()
          } else {
            device.name = savedName
          }
        } else {
          this.deviceEditNameStatus = { ...lastEditStatus, isLoading: false }
        }
      } catch (e) {
        console.log(e)
        await this.devicesViewModel.getAndShowMessageOnException(e)
        this.deviceEditNameStatus = { ...lastEditStatus, isLoading: false }
      }
    }
  }

  protected onSearch(text: string) {
    this.cancelEditName()
    this.devicesViewModel.setSearch(text)
  }

  protected onSortUpdated(event: DataGridSortOrder) {
    this.cancelEditName()
  }

  @Memoize()
  protected get isDisabledBulkAssignment$(): Observable<boolean> {
    return this.devicesViewModel.state.multiSelectedItems$.pipe(
      takeUntil(this.destroy$),
      map((selectedDevices) => {
        if (!selectedDevices.length) {
          return true
        }
        return selectedDevices.some((device) => device.fleetId)
      }),
    )
  }

  @Memoize()
  protected get isDisabledBulkUnassignment$(): Observable<boolean> {
    return this.devicesViewModel.state.multiSelectedItems$.pipe(
      takeUntil(this.destroy$),
      map((selectedDevices) => {
        if (!selectedDevices.length) {
          return true
        }
        return selectedDevices.some((device) => !device.fleetId)
      }),
    )
  }

  protected async onTestDevice(deviceId: string) {
    this.cancelEditName()
    try {
      this.deviceTestStatus = { isLoading: true, deviceId }
      const deviceTraffic = await this.devicesViewModel.getDeviceTrafficData(deviceId)
      const mainMessage = deviceTraffic
        ? await this.languageService.translate('tsp-admin.devices.test.success')
        : await this.languageService.translate('tsp-admin.devices.test.unsuccess')
      const severity = deviceTraffic ? 'success' : 'error'
      let additionalMessage = ''
      if (deviceTraffic) {
        const dateFormat = await this.languageService.translate('grid-date-time-sec')
        const lastMessageDate = new Date(deviceTraffic.last_active * 1000)
        additionalMessage = await this.dateFormatService.formatDate(lastMessageDate, dateFormat)
      } else {
        additionalMessage = await this.languageService.translate('tsp-admin.devices.test.tryAgain')
      }
      const life = 10000

      this.messageService.add({
        severity,
        summary: mainMessage,
        detail: additionalMessage,
        life,
      })
    } catch (e) {
      await this.devicesViewModel.getAndShowMessageOnException(e)
    }
    this.deviceTestStatus = { ...defaultTestStatus }
  }

  protected setDeviceEditNameStatus(data: editNameStatus) {
    if (!this.deviceEditNameStatus.isLoading) {
      this.deviceEditNameStatus = data
    }
  }

  protected async exportReport(format = ExportFormat.CSV) {
    await this.devicesViewModel.exportReport(format)
  }

  protected afterBulkAction() {
    this.devicesChanged.emit()
    this.devicesViewModel.resetMultiSelectedItems()
    this.devicesViewModel.reset()
    this.devicesViewModel.loadCount()
  }

  protected async bulkDeleteDevices() {
    const selectedDevices = this.devicesViewModel.multiSelectedItems
    if (!selectedDevices.length) {
      return
    }
    const labels = await this.languageService.massTranslate(
      {
        summary: 'tsp-admin.devices.bulk-actions.delete.summary',
        description: 'tsp-admin.devices.delete.dialog.fleet-data-warn',
        confirmButton: 'tsp-admin.devices.delete.dialog.confirm-button',
        cancelButton: 'button.cancel',
      },
      { val: selectedDevices.length },
    )
    const dialogResult = await this.dialogFacade.confirm({
      ...labels,
      cancelButtonColor: 'var(--color-alert-500)',
      confirmButtonIcon: 'pi-trash',
    })
    if (dialogResult) {
      const deleteLabels = await this.languageService.massTranslate(
        {
          header: 'tsp-admin.devices.bulk-actions.delete.header',
          description: 'tsp-admin.devices.bulk-actions.delete.description',
          completeDescription: 'tsp-admin.devices.bulk-actions.delete.complete-description',
          confirmButton: 'common.ok',
          cancelButton: 'button.cancel',
        },
        { val: selectedDevices.length },
      )
      deleteLabels.confirmButton = deleteLabels.confirmButton.toUpperCase()

      const deleteResult = await this.dialogFacade.bulkActionWithLoading({
        ...deleteLabels,
        itemKey: 'id',
        items: selectedDevices,
        chunkSize: 50,
        delayBeforeStart: 1500,
        action: async (data: any) => {
          await this.devicesViewModel.deleteDevice(data['id'])
        },
        getFailDescription: async (data: any) => {
          return this.languageService.translate('tsp-admin.devices.bulk-actions.fail-description', {
            name: data['name'],
          })
        },
        getErrorSummary: async (value: number) => {
          return this.languageService.translate(
            'tsp-admin.devices.bulk-actions.delete.devices-not-deleted',
            { val: value },
          )
        },
        autoClose: true,
      })
      if (deleteResult.result) {
        // show message
        const messageKey =
          deleteResult.value.all === deleteResult.value.success
            ? 'tsp-admin.devices.bulk-actions.delete.deleted-success'
            : 'tsp-admin.devices.bulk-actions.delete.deleted-warn'
        const message = await this.languageService.translate(messageKey, {
          deletedNumber: deleteResult.value.success,
          allNumber: deleteResult.value.all,
        })
        this.messageService.add({
          severity: deleteResult.value.all === deleteResult.value.success ? 'success' : 'warn',
          detail: message,
          life: 5000,
        })
      }
      this.afterBulkAction()
    }
  }

  protected async bulkUnassignDevices() {
    const selectedDevices = this.devicesViewModel.multiSelectedItems
    if (!selectedDevices.length) {
      return
    }
    const labels = await this.languageService.massTranslate(
      {
        summary: 'tsp-admin.devices.bulk-actions.unassign.question-summary',
        description: 'tsp-admin.devices.bulk-actions.unassign.question-description',
        confirmButton: 'tsp-admin.devices.bulk-actions.unassign.confirm-button',
        cancelButton: 'button.cancel',
      },
      { val: selectedDevices.length },
    )
    const dialogResult = await this.dialogFacade.confirm({
      ...labels,
      cancelButtonColor: 'var(--color-alert-500)',
    })
    if (dialogResult) {
      const unassignLabels = await this.languageService.massTranslate(
        {
          header: 'tsp-admin.devices.bulk-actions.unassign.header',
          description: 'tsp-admin.devices.bulk-actions.unassign.description',
          completeDescription: 'tsp-admin.devices.bulk-actions.unassign.complete-description',
          confirmButton: 'common.ok',
          cancelButton: 'button.cancel',
        },
        { val: selectedDevices.length },
      )
      unassignLabels.confirmButton = unassignLabels.confirmButton.toUpperCase()

      const unassignResult = await this.dialogFacade.bulkActionWithLoading({
        ...unassignLabels,
        itemKey: 'id',
        items: selectedDevices,
        chunkSize: 50,
        action: async (data: any) => {
          await this.devicesViewModel.unassignDeviceFromFleet(data['id'])
        },
        getFailDescription: async (data: any) => {
          return this.languageService.translate('tsp-admin.devices.bulk-actions.fail-description', {
            name: data['name'],
          })
        },
        getErrorSummary: async (value: number) => {
          return this.languageService.translate(
            'tsp-admin.devices.bulk-actions.unassign.devices-not-unassigned',
            { val: value },
          )
        },
        autoClose: true,
      })
      if (unassignResult.result) {
        // show message
        const messageKey =
          unassignResult.value.all === unassignResult.value.success
            ? 'tsp-admin.devices.bulk-actions.unassign.unassigned-success'
            : 'tsp-admin.devices.bulk-actions.unassign.unassigned-warn'
        const message = await this.languageService.translate(messageKey, {
          assignedNumber: unassignResult.value.success,
          allNumber: unassignResult.value.all,
        })
        this.messageService.add({
          severity: unassignResult.value.all === unassignResult.value.success ? 'success' : 'warn',
          detail: message,
          life: 5000,
        })
      }
      this.afterBulkAction()
    }
  }

  protected async bulkAssignDevices(fleet: FleetDisplayData | null) {
    const selectedDevices = this.devicesViewModel.multiSelectedItems
    if (!fleet?.id || !selectedDevices.length) {
      return
    }

    const assignLabels = await this.languageService.massTranslate(
      {
        header: 'tsp-admin.devices.bulk-actions.assign.header',
        description: 'tsp-admin.devices.bulk-actions.assign.description',
        completeDescription: 'tsp-admin.devices.bulk-actions.assign.complete-description',
        confirmButton: 'common.ok',
        cancelButton: 'button.cancel',
      },
      { val: selectedDevices.length, fleetName: fleet.name },
    )
    assignLabels.confirmButton = assignLabels.confirmButton.toUpperCase()

    const assignResult = await this.dialogFacade.bulkActionWithLoading({
      ...assignLabels,
      itemKey: 'id',
      items: selectedDevices,
      chunkSize: 50,
      action: async (data: any) => {
        await this.devicesViewModel.assignDeviceToFleet(data['id'], fleet.id)
      },
      getFailDescription: async (data: any) => {
        return this.languageService.translate('tsp-admin.devices.bulk-actions.fail-description', {
          name: data['name'],
        })
      },
      getErrorSummary: async (value: number) => {
        return this.languageService.translate(
          'tsp-admin.devices.bulk-actions.assign.devices-not-assigned',
          { val: value },
        )
      },
      autoClose: true,
    })
    if (assignResult.result) {
      // show message
      const messageKey =
        assignResult.value.all === assignResult.value.success
          ? 'tsp-admin.devices.bulk-actions.assign.assigned-success'
          : 'tsp-admin.devices.bulk-actions.assign.assigned-warn'
      const message = await this.languageService.translate(messageKey, {
        assignedNumber: assignResult.value.success,
        allNumber: assignResult.value.all,
        fleetName: fleet.name,
      })
      this.messageService.add({
        severity: assignResult.value.all === assignResult.value.success ? 'success' : 'warn',
        detail: message,
        life: 5000,
      })
    }
    this.afterBulkAction()
  }
}
