import { inject, Injectable } from '@angular/core'
import { BehaviorSubject, debounceTime, filter, takeUntil, tap, withLatestFrom } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { Vehicle } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { TripHistoryItem } from '@ti-platform/web/api/scopes/fleet/routes/vehicles.route'
import { completeStore, DateRange, injectDestroy$, Memoize } from '@ti-platform/web/common'
import { UITripHistoryData } from '../contracts'

@Injectable()
export class TripsListModel {
  protected readonly api = inject(ApiService)
  protected readonly destroy$ = injectDestroy$()

  protected readonly store = {
    areTripsLoading$: new BehaviorSubject<boolean>(true),
    canLoadMoreTrips$: new BehaviorSubject<boolean>(true),
    currentVehicle$: new BehaviorSubject<Vehicle | undefined>(undefined),
    dateRange$: new BehaviorSubject<DateRange | undefined>(undefined),
    initialPageSize$: new BehaviorSubject<number>(5),
    loadNextPage$: new BehaviorSubject<void>(undefined),
    loadedTrips$: new BehaviorSubject<TripHistoryItem[]>([]),
    selectedTrip$: new BehaviorSubject<UITripHistoryData | null>(null),
    tripsPageSize$: new BehaviorSubject<number>(10),
  }

  @Memoize()
  public get state() {
    return {
      areTripsLoading$: this.store.areTripsLoading$.asObservable(),
      canLoadMoreTrips$: this.store.canLoadMoreTrips$.asObservable(),
      currentVehicle$: this.store.currentVehicle$.asObservable(),
      dateRange$: this.store.dateRange$.asObservable(),
      loadedTrips$: this.store.loadedTrips$.asObservable(),
      selectedTrip$: this.store.selectedTrip$.asObservable(),
    }
  }

  public constructor() {
    Promise.resolve()
      .then(() => this.init())
      .catch((error) => console.warn(`Cannot initialize TripsListModel`, error))

    this.destroy$.subscribe(() => completeStore(this.store))
  }

  public setVehicle(vehicle: Vehicle) {
    this.store.currentVehicle$.next(vehicle)
    this.store.loadedTrips$.next([])
    return this.loadTripsNextPage()
  }

  public setDateRange(range?: DateRange) {
    this.store.dateRange$.next(range)
    this.store.loadedTrips$.next([])
    return this.loadTripsNextPage()
  }

  public selectTrip(trip?: UITripHistoryData) {
    const selected = this.store.selectedTrip$.getValue()
    if (!trip || trip?.id === selected?.id) {
      this.store.selectedTrip$.next(null)
    } else {
      this.store.selectedTrip$.next(trip)
    }
  }

  public loadTripsNextPage() {
    this.store.loadNextPage$.next()
  }

  public setPageSize(pageSize: number, initialPageSize?: number) {
    this.store.tripsPageSize$.next(pageSize)
    this.store.initialPageSize$.next(initialPageSize ?? pageSize)
  }

  protected init() {
    this.store.loadNextPage$
      .pipe(
        withLatestFrom(this.store.loadedTrips$, this.store.currentVehicle$, this.store.dateRange$),
        filter(([, , currentVehicle]) => !!currentVehicle),
        tap(() => this.store.areTripsLoading$.next(true)),
        switchMap(async ([, loadedTrips, currentVehicle, appliedDateRange]) => {
          const pageSize = loadedTrips.length
            ? this.store.tripsPageSize$.getValue()
            : this.store.initialPageSize$.getValue()

          const nextTrips = await this.api.fleet.vehicle.timeline(
            (currentVehicle as Vehicle).primaryExternalDeviceId,
            {
              limit: pageSize,
              timestampTo: loadedTrips.length
                ? loadedTrips[loadedTrips.length - 1].begin
                : Math.round(
                    (appliedDateRange ? appliedDateRange[1].valueOf() : Date.now()) / 1000,
                  ),
              timestampFrom: appliedDateRange
                ? Math.round(appliedDateRange[0].valueOf() / 1000)
                : undefined,
            },
          )

          this.store.canLoadMoreTrips$.next(nextTrips.length === pageSize)

          const tripIds = loadedTrips.map((t) => t.id)
          return [...loadedTrips, ...nextTrips.filter((t) => !tripIds.includes(t.id))]
        }),
        tap(() => this.store.areTripsLoading$.next(false)),
        takeUntil(this.destroy$),
      )
      .subscribe((totalTrips) => this.store.loadedTrips$.next(totalTrips))
  }
}
