@if (
  {
    isLoading: (model.state.isLoading$ | async) ?? false,
    bookmarks: model.state.items$ | async,
  };
  as view
) {
  <div class="bookmarks-list scroll-wrapper scrollbar-gray">
    @if (view.isLoading) {
      <div class="bookmarks-item-skeleton" *appRepeat="9">
        <p-skeleton width="100%" height="100%" animation="wave"></p-skeleton>
      </div>
    } @else {
      @if (view.bookmarks; as bookmarks) {
        @for (bookmark of bookmarks; track trackByFn) {
          <app-bookmarks-list-item
            class="mb-12"
            [bookmark]="bookmark"
            [selectedBookmarkId]="(model.state.selectedItem$ | async)?.id"
          ></app-bookmarks-list-item>
        }

        @if (bookmarks.length === 0) {
          <div class="no-results-found-screen">
            <div class="no-data-icon mb-12">
              <app-bookmarks-icon
                [color]="'var(--color-gray-900)'"
                [width]="32"
                [height]="32"
              ></app-bookmarks-icon>
            </div>
            <div class="title text-18 fw-600">
              {{ 'bookmarks.title' | translate }}
            </div>
            <div class="description text-14 fw-400">
              {{
                (model.isSearchOrFilterApplied
                  ? 'search.no-data-description'
                  : 'bookmarks.no-data-description'
                ) | translate
              }}
            </div>
          </div>
        }

        @if (bookmarks.length > 0 && (model.state.canLoadNextPage$ | async)) {
          <div class="more-button text-14 fw-600" (click)="model.loadNextPage()">
            {{ 'button.show-more' | translate }}
          </div>
        }
      }
    }
  </div>
}
