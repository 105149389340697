import { Component } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-bookmarks-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.33301 7.5026V16.6659C3.33301 17.2984 4.00551 17.7026 4.56134 17.4001C5.91884 16.6593 7.91634 15.5701 7.91634 15.5701C7.91634 15.5701 9.91384 16.6601 11.2713 17.4001C11.8272 17.7026 12.4997 17.2984 12.4997 16.6659V7.5026C12.4997 6.58177 11.7538 5.83594 10.833 5.83594H4.99967C4.07967 5.83594 3.33301 6.58177 3.33301 7.5026Z"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 5.83333V4.16667C7.5 3.24583 8.24583 2.5 9.16667 2.5H15C15.9208 2.5 16.6667 3.24583 16.6667 4.16667V13.3692C16.6667 14.0017 15.99 14.4033 15.4342 14.1008L12.5 12.5"
        [attr.stroke]="color"
        style="stroke-opacity:1;"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: start;
        justify-content: center;
        cursor: pointer;
      }
    `,
  ],
})
export class BookmarksIconComponent extends BaseIconComponent {}
