<div [class.counter-compact]="compactMode" class="counter flex text-14 fw-600">
  <i>
    <ng-content></ng-content>
  </i>
  {{ text }}
  @switch (brackets) {
    @case (true) {
      ({{ value }})
    }
    @case (false) {
      {{ value }}
    }
  }
</div>
