<header [class.align-right]="!showDevicesCount" class="tsp-admin-header {{ headerClass }}">
  <div *ngIf="showDevicesCount" class="heading-group flex">
    <h1 class="text-32 fw-600">{{ 'tsp-admin.menu.devices' | translate }}</h1>
    @if (devicesViewModel.state.count$ | async) {
      <div class="counter flex text-14 fw-600">
        <div class="flex">
          <i>
            <app-icon-device [width]="20" [height]="20"></app-icon-device>
          </i>

          <div>
            {{ 'tsp-admin.devices.total' | translate }}:
            {{ (devicesViewModel.state.count$ | async) || 0 }} /
            {{ 'tsp-admin.devices.billable' | translate }}:
            {{ (devicesViewModel.billableDevicesCount$ | async) || 0 }}
          </div>
        </div>
      </div>
    }
  </div>

  <div class="controls" [class]="{ 'align-right': !showManageCommands }">
    <div class="search-container p-fluid">
      <p-iconField class="h--40">
        <p-inputIcon>
          <app-icon-search [height]="20" [width]="20"></app-icon-search>
        </p-inputIcon>
        <input
          (ngModelChange)="onSearch($event)"
          [ngModel]="(devicesViewModel.state.search$ | async) ?? false"
          [placeholder]="'button.search' | translate"
          pInputText
          type="text"
        />
        <p-inputIcon>
          <app-icon-close
            *ngIf="devicesViewModel.state.search$ | async"
            (click)="onClearSearch()"
            [color]="'var(--color-gray-500)'"
            [height]="22"
            [width]="22"
            class="clickable"
          ></app-icon-close>
        </p-inputIcon>
      </p-iconField>
    </div>

    @if ((state.multiSelectedItems$ | async)?.length) {
      <div class="multi-select-options">
        <app-closable-tag
          styleClass="bg-200"
          [label]="
            'tsp-admin.devices.bulk-actions.device-selected'
              | translate: { val: (state.multiSelectedItems$ | async)?.length }
          "
          (closeClicked)="devicesViewModel.resetMultiSelectedItems()"
        ></app-closable-tag>

        <div class="assign-unassign-group">
          <!-- tooltip class need so that tooltip text will be in 2 lines and shown at top -->
          @if (!((devicesViewModel.selectedFleet$ | async) || null)) {
            <p-button
              (onClick)="onAssignBulkClick()"
              [appCheckAccess]="[Resource.Device, Action.Update]"
              class="multi-select-button h--40 p--18 text-14 fw-500"
              [severity]="'secondary'"
              [pTooltip]="
                (isDisabledBulkAssignment$ | async)
                  ? ('tsp-admin.devices.bulk-actions.assign.disabled-tooltip' | translate)
                  : undefined
              "
              [tooltipPosition]="'top'"
              tooltipStyleClass="mw-240"
              [disabled]="isDisabledBulkAssignment$ | async"
              [style.pointer-events]="'auto'"
            >
              {{
                'tsp-admin.devices.bulk-actions.assign.selected'
                  | translate: { val: (state.multiSelectedItems$ | async)?.length }
              }}
            </p-button>
          }

          <p-button
            (onClick)="bulkUnassignDevices()"
            [appCheckAccess]="[Resource.Device, Action.Update]"
            class="multi-select-button h--40 p--18 text-14 fw-500"
            [severity]="'secondary'"
            [pTooltip]="
              (isDisabledBulkUnassignment$ | async)
                ? ('tsp-admin.devices.bulk-actions.unassign.disabled-tooltip' | translate)
                : undefined
            "
            tooltipStyleClass="mw-280"
            [tooltipPosition]="'top'"
            [disabled]="isDisabledBulkUnassignment$ | async"
            [style.pointer-events]="'auto'"
          >
            {{
              'tsp-admin.devices.bulk-actions.unassign.selected'
                | translate: { val: (state.multiSelectedItems$ | async)?.length }
            }}
          </p-button>
        </div>

        <p-button
          class="multi-select-button h--40 p--18 text-14 fw-500"
          outlined="true"
          [severity]="'danger'"
          [appCheckAccess]="[Resource.Device, Action.Delete]"
          (onClick)="bulkDeleteDevices()"
        >
          {{
            'tsp-admin.devices.bulk-actions.delete.selected'
              | translate: { val: (state.multiSelectedItems$ | async)?.length }
          }}
        </p-button>
      </div>
    } @else {
      <p-button
        (onClick)="onClickMediaUsage()"
        class="media-usage-button h--40 p--18 text-14 fw-500"
        [severity]="'secondary'"
        [disabled]="(devicesViewModel.videoDevicesCount$ | async) === 0"
        [pTooltip]="
          (devicesViewModel.videoDevicesCount$ | async) === 0
            ? ('tsp-admin.devices.media-usage.no-video-usage' | translate)
            : undefined
        "
        [tooltipPosition]="'top'"
        [style.pointer-events]="'auto'"
      >
        <app-icon-video [height]="20" [width]="20" class="icon"></app-icon-video>
        {{ 'tsp-admin.devices.media-usage.title' | translate }}
      </p-button>

      <div class="export-btn-wrapper">
        <app-export-btn (export)="exportReport($event)"></app-export-btn>
      </div>

      <div class="vertical-spacer--12"></div>

      @if (showManageCommands) {
        <p-button
          (onClick)="onClickManageCommands()"
          class="manage-commands-button h--40 p--18 text-14 fw-500"
          [severity]="'secondary'"
        >
          <app-icon-settings [height]="20" [width]="20" class="icon"></app-icon-settings>
          {{ 'tsp-admin.devices.manage-commands' | translate }}
        </p-button>
      }

      <p-button
        [appCheckAccess]="[Resource.Device, Action.Create]"
        (onClick)="addMenu.toggle($event); onAddClick()"
        class="header-button add-button h--40 p--18 text-14 fw-500"
      >
        <app-icon-plus [height]="20" [width]="20" class="icon"></app-icon-plus>
        {{ 'tsp-admin.devices.add-new-device' | translate }}
      </p-button>
    }
  </div>
</header>

<app-tsp-admin-devices-list
  (addEvent)="onAddClick(); onAddOneClick()"
  (cancelEditNameEvent)="cancelEditName()"
  (changeNameEvent)="onChangeName($event)"
  (editNameClickEvent)="onClickEditName($event)"
  (menuClickEvent)="onMenuClick()"
  (saveNameEvent)="onSaveDeviceName($event)"
  (sortUpdated)="onSortUpdated($event)"
  (testClickEvent)="onTestDevice($event)"
  [deviceEditNameStatus]="deviceEditNameStatus"
  [deviceTestStatus]="deviceTestStatus"
  [displayColumns]="displayColumns"
  [gridOrder]="gridOrder"
></app-tsp-admin-devices-list>

<app-device-edit
  #deviceEdit
  (cancelEvent)="devicesViewModel.unselect()"
  (deviceEditedEvent)="onDeviceEdited($event)"
  [device]="devicesViewModel.state.selectedItem$ | async"
  [opened]="!!(devicesViewModel.state.selectedItem$ | async)"
></app-device-edit>

<ng-container *ngIf="showAddDeviceDialog$ | async as showAddDialog">
  <app-add-new-device
    (cancelEvent)="showAddDeviceDialog$.next(false)"
    (deviceAddedEvent)="onDeviceAdded($event)"
    [opened]="showAddDialog"
  ></app-add-new-device>
</ng-container>

<ng-container *ngIf="showLoadDeviceListDialog$ | async as showUploadDialog">
  <app-load-devices
    (cancelEvent)="showLoadDeviceListDialog$.next(false)"
    (abortAddingEvent)="onLoadDevices()"
    (devicesAddedEvent)="onDevicesAdded($event)"
    [opened]="showUploadDialog"
  ></app-load-devices>
</ng-container>

@if (showBulkAssignDevicesDialog$ | async; as showAssignDialog) {
  <app-bulk-devices-assign
    (cancelEvent)="showBulkAssignDevicesDialog$.next(false)"
    (confirmEvent)="showBulkAssignDevicesDialog$.next(false); bulkAssignDevices($event)"
    [opened]="showAssignDialog"
    [numberToAssignment]="(state.multiSelectedItems$ | async)?.length || 0"
  ></app-bulk-devices-assign>
}

<p-menu #addMenu [model]="addMenuItems || []" [popup]="true" styleClass="add-device-menu">
  <ng-template pTemplate="item" let-item>
    <a pRipple class="flex align-items-center p-menuitem-link add-device-menu-item">
      @switch (item.type) {
        @case ('add') {
          <app-icon-plus [height]="16" [width]="16" class="mr-8"></app-icon-plus>
        }
        @case ('upload') {
          <app-icon-upload [height]="16" [width]="16" class="mr-8"></app-icon-upload>
        }
      }
      <span class="ml-2 menu-item-label">{{ item.label | translate }}</span>
    </a>
  </ng-template>
</p-menu>
