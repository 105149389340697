<p-dialog
  class="dialog-primary dialog-content-flex headless custom-duration-dialog"
  [ngClass]="{
    'mobile-stick-to-bottom': deviceService.isMobile(),
    small: !deviceService.isMobile(),
  }"
  [styleClass]="'mobile-custom-show-animation'"
  [modal]="modal"
  [visible]="visible"
  [dismissableMask]="dissmisable"
  (visibleChange)="visible = false; closed.emit()"
>
  <div class="content form">
    <header>
      <app-icon-clock
        class="icon mb-16"
        [width]="64"
        [height]="64"
        [color]="'var(--color-primary-500)'"
      ></app-icon-clock>
      <h3 class="text-18 fw-600">
        {{ caption }}
      </h3>
      <p class="text-14 fw-500">
        {{ description }}
      </p>
    </header>

    <div class="duration">
      <div class="text text-14 fw-500">{{ 'video.request.mobile-flow.duration' | translate }}</div>
      <app-duration-input
        [invalid]="inputInvalid && isDurationChanged"
        [duration]="duration"
        (durationChanged)="durationUpdated($event)"
      ></app-duration-input>
    </div>

    @if (isDurationChanged && inputInvalid) {
      <div class="error-section">
        @if (isDurationTooLong) {
          <div class="error-message">
            {{ 'video.request.errors.duration-too-long' | translate }}
          </div>
        }
        @if (isDurationTooShort) {
          <div class="error-message">
            {{ 'video.request.errors.duration-too-short' | translate }}
          </div>
        }
      </div>
    }

    <div class="dialog-footer">
      @if (deviceService.isMobile()) {
        <p-button
          class="h--40 w100 text-14 fw-600"
          [disabled]="inputInvalid"
          (click)="durationChange.emit(duration); closed.emit()"
        >
          {{ 'button.continue' | translate }}
        </p-button>
      } @else {
        <p-button
          class="h--40 w100 text-14 fw-600"
          [severity]="'secondary'"
          (click)="visible = false; closed.emit()"
        >
          {{ 'button.close' | translate }}
        </p-button>
        <p-button
          class="h--40 w100 text-14 fw-600"
          [disabled]="inputInvalid"
          (click)="durationChange.emit(duration); closed.emit()"
        >
          {{ 'button.confirm' | translate }}
        </p-button>
      }
    </div>
  </div>
</p-dialog>
