import { ComponentRef, Type } from '@angular/core'
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs'
import { Marker } from 'maplibre-gl'
import { MapAdapter, MapLibreMapAdapter } from '../adapters'
import { BaseMarker } from './base'
import { Coordinates } from '../contracts'
import { toLngLat } from '../utils'

export interface ComponentMarkerOptions {
  latLng: Coordinates
  cssClass?: string
  zIndex?: number
  opacity?: string
}

export abstract class BaseComponentMarker<T extends object> extends BaseMarker {
  protected constructor(
    public override readonly options: ComponentMarkerOptions & Partial<T>,
    protected override readonly adapter: MapAdapter,
    protected readonly componentType: Type<T>,
  ) {
    super(options, adapter)
    Promise.resolve().then(() => this.init())
  }
  public abstract getElement(): HTMLElement
  public abstract setOpacity(opacity: string): unknown
  protected abstract init(): unknown
}

export class MaplibreComponentMarker<T extends object> extends BaseComponentMarker<T> {
  public nativeRef!: Marker
  public componentRef?: ComponentRef<T>
  public readonly onInit$ = new ReplaySubject<void>(1)
  public readonly onClick$ = new Subject<void>()

  public constructor(
    public override readonly options: ComponentMarkerOptions & Partial<T>,
    protected override readonly adapter: MapLibreMapAdapter,
    protected override readonly componentType: Type<T>,
  ) {
    super(options, adapter, componentType)

    this.nativeRef = new Marker({
      element: document.createElement('div'),
    }).setLngLat(toLngLat(this.options.latLng))

    if (this.options.opacity) {
      this.setOpacity(this.options.opacity)
    }

    if (this.options.zIndex) {
      this.setZIndex(this.options.zIndex)
    }
  }

  public override getElement(): HTMLElement {
    return this.nativeRef.getElement()
  }

  public setOpacity(opacity: string) {
    this.options.opacity = opacity
    if (this.nativeRef) {
      this.nativeRef.setOpacity(opacity)
    } else {
      this.onInit$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
        this.nativeRef?.setOpacity(opacity)
      })
    }
  }

  public setZIndex(zIndex: number) {
    this.options.zIndex = zIndex
    if (this.nativeRef) {
      this.nativeRef.getElement().style.zIndex = `${zIndex}`
    } else {
      this.onInit$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
        if (this.nativeRef) {
          this.nativeRef.getElement().style.zIndex = `${zIndex}`
        }
      })
    }
  }

  public setOption<K extends keyof T>(key: K, value: T[K]) {
    // @ts-expect-error void
    this.options[key] = value
    this.componentRef?.setInput(key as string, value)
  }

  protected override init() {
    this.adapter
      .getComponentFactory()(this.componentType, this.options)
      .then((ref) => {
        this.nativeRef.getElement().appendChild(ref.location.nativeElement)
        this.nativeRef.addTo(this.adapter.map)
        this.componentRef = ref
        this.onInit$.next()

        if (this.options.cssClass) {
          this.nativeRef.getElement().classList.add(this.options.cssClass)
        }
      })

    const onClick = () => this.onClick$.next()
    this.nativeRef.getElement().addEventListener('click', onClick)
    this.destroy$.subscribe(() => this.nativeRef.getElement().removeEventListener('click', onClick))
  }

  public override destroy() {
    super.destroy()
    this.onInit$.complete()
    this.nativeRef?.remove()
    this.componentRef?.destroy()
  }
}
