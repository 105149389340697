import { Component, EventEmitter, Input, Output } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-inline-text-editor',
  templateUrl: 'inline-text-editor.component.html',
  styleUrl: 'inline-text-editor.component.scss',
})
export class InlineTextEditorComponent {
  protected readonly value$ = new BehaviorSubject<string>('')

  @Output() cancelled = new EventEmitter<void>()
  @Output() enterEdit = new EventEmitter<void>()
  @Output() submitted = new EventEmitter<string>()

  protected _isEditMode = false

  get isEditMode() {
    return this._isEditMode
  }

  @Input()
  set isEditMode(value: boolean) {
    this._isEditMode = value
    this.value$.next(this.value)
  }

  @Input() isValidValue = true
  @Input() isLoading = false
  @Input() minLength = 1
  @Input() maxLength = 256
  @Input() disabled: boolean | null = false
  @Input() keepPencilIcon?: boolean = false

  protected initialValue?: string

  @Input() set value(value: string) {
    if (value !== this.initialValue) {
      this.value$.next(value)
      this.initialValue = value
    }
  }
  public get value(): string {
    return this.initialValue || ''
  }

  protected onCancel() {
    this.value$.next(this.value)
    this.cancelled.emit()
  }

  protected onSubmit() {
    const newValue = this.value$.getValue().trim()
    if (this.value.trim() !== newValue) {
      this.initialValue = newValue
      this.submitted.emit(newValue)
    } else {
      this.onCancel()
    }
  }

  protected get cancelBtnStyleClass(): string {
    return this.isLoading ? 'pi pi-times hidden' : 'pi pi-times clickable'
  }

  protected get editBtnStyleClass(): string {
    let styleClass = ''
    if (this.isLoading) {
      styleClass = 'pi pi-spinner pi-spin disabled'
    } else {
      styleClass = this.isValidValue ? `pi pi-check clickable` : `pi pi-check disabled`
    }
    return styleClass
  }
}
