import { Component, Input } from '@angular/core'
import { VehicleTelemetry } from '@ti-platform/web/fleet/contracts'
import { VehicleStatus } from '@ti-platform/web/map'
import { UnitType } from '@ti-platform/web/ui-kit/i18n'

@Component({
  selector: 'app-vehicle-state',
  templateUrl: 'vehicle-state.component.html',
  styleUrl: 'vehicle-state.component.scss',
})
export class VehicleStateComponent {
  @Input() telemetry!: Partial<VehicleTelemetry>
  protected readonly VehicleStatus = VehicleStatus
  protected readonly UnitType = UnitType
}
