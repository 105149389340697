import { ComponentRef } from '@angular/core'
import { Marker } from 'maplibre-gl'
import { MapLibreMapAdapter } from '../adapters'
import { IncidentMarkerComponent } from '../components'
import { Coordinates } from '../contracts'
import { toLngLat } from '../utils'
import { BaseMarker } from './base'

export interface IncidentMarkerOptions {
  id: string
  coordinates: Coordinates
}

export abstract class AbstractIncidentMarker extends BaseMarker {
  public override readonly options!: IncidentMarkerOptions
}

export class MaplibreIncidentMarker extends AbstractIncidentMarker {
  protected readonly nativeRef!: Marker
  protected componentRef?: ComponentRef<IncidentMarkerComponent>

  public constructor(
    public override readonly options: IncidentMarkerOptions,
    protected override readonly adapter: MapLibreMapAdapter,
  ) {
    super(options, adapter)

    this.nativeRef = new Marker({
      element: document.createElement('div'),
    }).setLngLat(toLngLat(this.options.coordinates))

    this.render()
  }

  protected render() {
    if (!this.componentRef) {
      this.adapter
        .getComponentFactory()(IncidentMarkerComponent, {})
        .then((ref) => {
          this.nativeRef.getElement().appendChild(ref.location.nativeElement)
          this.nativeRef.addTo(this.adapter.map)
          this.componentRef = ref
        })
    }
  }

  public override destroy() {
    super.destroy()
    this.nativeRef.remove()
    this.componentRef?.destroy()
  }
}
