import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-unbookmark-icon',
  template: `
    <svg
      [attr.width]="width"
      [attr.height]="height"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.41699 6.05398V17.1356C3.41699 17.8288 3.74844 18.4362 4.26497 18.7834C4.79069 19.1368 5.49771 19.2054 6.1213 18.8245L6.12209 18.824C6.98586 18.2949 8.05328 17.6413 8.9048 17.1201L9.96764 16.4695L10.0002 16.4495L10.0328 16.4695L11.0958 17.1205C11.9473 17.6419 13.0149 18.2955 13.8789 18.8242L13.8793 18.8245C14.3553 19.1152 14.8798 19.1441 15.3352 18.9843L13.1624 16.627C12.7161 16.3537 12.2743 16.0833 11.8791 15.8412L10.8163 15.1904L10.5047 14.9995L10.421 14.9483L10.3994 14.9351L10.3939 14.9317L10.3925 14.9308L10.3922 14.9306L10.3921 14.9306L10.0003 15.5701L10.3921 14.9306C10.1517 14.7834 9.84918 14.7833 9.60881 14.9304L10.0003 15.5701L9.60878 14.9304L9.60869 14.9305L9.60834 14.9307L9.60696 14.9316L9.60146 14.9349L9.57983 14.9482L9.49618 14.9994L9.18456 15.1901L8.12164 15.8407C7.27029 16.3619 6.20301 17.0154 5.33915 17.5445C5.2554 17.5956 5.18081 17.5916 5.10178 17.5385C5.0135 17.4791 4.91699 17.346 4.91699 17.1356V7.68137L3.41699 6.05398ZM15.0837 11.9488V4.04503C15.0837 3.05995 14.3911 2.41406 13.7124 2.41406H6.29528L5.1464 1.16761C5.49131 1.0053 5.87572 0.914062 6.2882 0.914062H13.7124C15.3777 0.914062 16.5837 2.39914 16.5837 4.04503V13.5762L15.0837 11.9488Z"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
      <rect
        x="1.43652"
        y="1.45312"
        width="1.25"
        height="24.2945"
        rx="0.625"
        transform="rotate(-42.6675 1.43652 1.45312)"
        [attr.fill]="color"
        style="fill-opacity:1;"
      />
    </svg>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: start;
        justify-content: center;
        cursor: pointer;
      }
    `,
  ],
})
export class UnbookmarkIconComponent extends BaseIconComponent {
  @Input() override width: string | number = 16
  @Input() override height: string | number = 20
}
