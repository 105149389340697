import { Component, Input } from '@angular/core'
import { BaseIconComponent } from '../base-icon.component'

@Component({
  selector: 'app-bookmark-icon',
  template: `
    @if (checked) {
      <svg
        [attr.width]="width"
        [attr.height]="height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5 4.85717V20.5659C5 21.6502 6.02709 22.343 6.876 21.8244C8.94927 20.5544 12 18.6873 12 18.6873C12 18.6873 15.0507 20.5559 17.124 21.8244C17.9729 22.343 19 21.6502 19 20.5659V4.85717C19 3.27858 17.8609 2 16.4545 2H7.54545C6.14036 2 5 3.27858 5 4.85717Z"
          fill="#7F56D9"
          stroke="#7F56D9"
          style="fill:#7F56D9;fill:color(display-p3 0.4964 0.3384 0.8498);fill-opacity:1;stroke:#7F56D9;stroke:color(display-p3 0.4964 0.3384 0.8498);stroke-opacity:1;"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    } @else {
      <svg
        [attr.width]="width"
        [attr.height]="height"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5 4.85717V20.5659C5 21.6502 6.02709 22.343 6.876 21.8244C8.94927 20.5544 12 18.6873 12 18.6873C12 18.6873 15.0507 20.5559 17.124 21.8244C17.9729 22.343 19 21.6502 19 20.5659V4.85717C19 3.27858 17.8609 2 16.4545 2H7.54545C6.14036 2 5 3.27858 5 4.85717Z"
          [attr.stroke]="color"
          style="stroke:color(display-p3 0.4000 0.4392 0.5216);stroke-opacity:1;"
          stroke-width="1.28571"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    }
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: start;
        justify-content: center;
        cursor: pointer;
      }
    `,
  ],
})
export class BookmarkIconComponent extends BaseIconComponent {
  @Input() override width: string | number = 20
  @Input() override height: string | number = 24
  @Input() public checked = false
}
