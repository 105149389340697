import { inject, Injectable } from '@angular/core'
import { injectDestroy$ } from '@ti-platform/web/common'
import { UIVehicleAggregate } from '@ti-platform/web/fleet/contracts'
import { combineLatest, distinctUntilChanged, Subject, takeUntil, throttleTime, timer } from 'rxjs'
import { MapAdapter, TilesSource } from '../adapters'
import { Coordinates, MapGeofenceManager, VehicleStatus } from '../contracts'
import { AbstractVehicleMarker } from '../markers'
import { MapVehiclesManager } from './vehicles-manager'

export type VehicleMarkerControllerOptions = {
  clickListener?: (marker: AbstractVehicleMarker) => void | Promise<void>
}

@Injectable()
export class VehicleMarkerController {
  public readonly resetTracking$ = new Subject<void>()

  protected readonly map = inject(MapAdapter)
  protected readonly destroy$ = injectDestroy$()
  protected readonly vehiclesManager = inject(MapVehiclesManager)
  protected readonly geofenceManager = inject(MapGeofenceManager, { optional: true })

  // TODO: Use markers map from MapVehiclesManager
  protected readonly markersMap = new Map<string, AbstractVehicleMarker>()

  protected options?: VehicleMarkerControllerOptions
  protected isAutoCentered?: boolean
  protected trackedVehicleId?: string
  protected trackedVehicleSelectedAt?: number

  public initialize(options?: VehicleMarkerControllerOptions) {
    this.options = options ?? {}

    this.initIsLightModeSync()
    this.initTrackingVehicleReset()

    combineLatest([timer(256, 1024), this.map.onInit$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateTrackedVehicle())
  }

  public updateVehiclesData(vehicles: UIVehicleAggregate[]) {
    // Create or update vehicle markers
    for (const vehicle of vehicles) {
      if (!vehicle.telemetry.latitude || !vehicle.telemetry.longitude) {
        continue
      }

      let marker = this.markersMap.get(vehicle.id)
      if (!marker) {
        marker = this.vehiclesManager.addVehicleMarker({
          id: vehicle.id,
          name: vehicle.name,
          color: vehicle.color,
          icon: vehicle.icon,
          sizePx: [44, 44],
          latLng: [vehicle.telemetry.latitude, vehicle.telemetry.longitude],
          directionDeg: vehicle.telemetry.direction ? vehicle.telemetry.direction : 0,
          status: vehicle.telemetry.lastStatus ?? VehicleStatus.Stopped,
          statusInvalid: vehicle.telemetry.lastStatusInvalid,
          isLightMode: this.isMarkerLightMode,
        })

        this.markersMap.set(vehicle.id, marker)

        marker.onClick$.pipe(throttleTime(250), takeUntil(this.destroy$)).subscribe(() => {
          if (typeof this.options?.clickListener === 'function') {
            this.options.clickListener(marker as AbstractVehicleMarker)
          }
        })
      } else {
        if (typeof vehicle.telemetry.lastStatus === 'number') {
          marker.setStatus(vehicle.telemetry.lastStatus, vehicle.telemetry.lastStatusInvalid)
        }
        if (vehicle.telemetry.direction !== undefined) {
          marker.setDirectionDeg(vehicle.telemetry.direction)
        }
        marker.setLatLng([vehicle.telemetry.latitude, vehicle.telemetry.longitude])
      }
    }

    // Search for markers to be removed
    const vehicleIds = vehicles.map((v) => v.id)
    for (const [id, marker] of this.markersMap.entries()) {
      if (!vehicleIds.includes(id)) {
        marker.destroy()
        this.markersMap.delete(id)
      }
    }

    this.ensureAutoCentered()
    this.updateTrackedVehicle()
  }

  public trackVehicle(vehicleId?: string | null) {
    if (this.trackedVehicleId === vehicleId) return

    this.trackedVehicleId = vehicleId ? vehicleId : undefined
    this.trackedVehicleSelectedAt = vehicleId ? Date.now() : undefined
    this.updateTrackedVehicle(true)
  }

  protected get isMarkerLightMode(): boolean {
    const tileSource = this.map.tilesSource$.getValue()
    return (
      tileSource === TilesSource.GoogleSatellite ||
      tileSource === TilesSource.EsriImagery ||
      tileSource === TilesSource.HEREImagery ||
      tileSource === TilesSource.Satellite
    )
  }

  protected ensureAutoCentered() {
    if (!this.isAutoCentered && this.markersMap.size) {
      this.isAutoCentered = true
      this.vehiclesManager.fitMapToViewAll()
    }
  }

  protected updateTrackedVehicle(force?: boolean) {
    this.map.scheduleAfterInit(() => {
      const shouldMove = force || (!this.map.isEasing() && !this.map.isMoving())

      // Slide map to the tracked vehicle if no other move animation is running
      if (this.trackedVehicleId && shouldMove) {
        const marker = this.markersMap.get(this.trackedVehicleId)
        if (marker && (this.isMarkerOffCenter(marker.options.latLng) || force)) {
          this.map.moveTo(
            marker.getLatLng(),
            Math.max(10, this.map.getZoomLevel()),
            // Move marker slowly if shift is less a half of map size
            force || this.isMarkerOffCenter(marker.options.latLng, 0.5) ? 4 : 1,
          )
        }
      }
    })
  }

  // Threshold is percent value of map dimensions
  protected isMarkerOffCenter(newCoordinates: Coordinates, threshold = 0.125): boolean {
    const currentCenter = this.map.getCenter()
    const distanceX = Math.abs(currentCenter[1] - newCoordinates[1])
    const distanceY = Math.abs(currentCenter[0] - newCoordinates[0])

    const mapBounds = this.map.getBounds()
    const width = mapBounds.getEast() - mapBounds.getWest()
    const height = mapBounds.getNorth() - mapBounds.getSouth()

    return distanceX > width * threshold || distanceY > height * threshold
  }

  protected initIsLightModeSync() {
    // Update the light mode for vehicle markers when tile source changed
    this.map.tilesSource$.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(() => {
      this.markersMap.forEach((marker) => marker.setIsLightMode(this.isMarkerLightMode))
    })
  }

  protected initTrackingVehicleReset() {
    this.resetTracking$.pipe(takeUntil(this.destroy$)).subscribe(() => this.trackVehicle(null))

    // Reset tracked vehicle if it is moved away
    combineLatest([this.map.onDrag$])
      .pipe(
        takeUntil(this.destroy$),
        throttleTime(250, undefined, { trailing: true, leading: true }),
      )
      .subscribe(() => {
        if (
          this.trackedVehicleId &&
          this.trackedVehicleSelectedAt &&
          this.trackedVehicleSelectedAt < Date.now() - 2000
        ) {
          const marker = this.markersMap.get(this.trackedVehicleId)
          if (marker && this.isMarkerOffCenter(marker.options.latLng, 0.2)) {
            this.resetTracking$.next()
          }
        }
      })

    // Reset tracking if geofence is being edited
    if (this.geofenceManager) {
      this.geofenceManager.editedGeofenceId$
        .pipe(takeUntil(this.destroy$))
        .subscribe((editedId) => {
          if (editedId) {
            this.resetTracking$.next()
          }
        })
    }
  }
}
