import { MapLibreMapAdapter } from '../adapters'
import { BaseMarker } from './base'
import { ComponentFactory, Coordinates } from '../contracts'
import { MaplibreComponentMarker } from './component'
import { StaticVehicleMarkerComponent } from '../components'

export interface StaticVehicleMarkerOptions {
  id: string
  coordinates: Coordinates
  color: string
  icon: string
}

export abstract class AbstractStaticVehicleMarker extends BaseMarker {
  public override readonly options!: StaticVehicleMarkerOptions
}

export class MaplibreStaticVehicleMarker extends AbstractStaticVehicleMarker {
  protected readonly marker!: MaplibreComponentMarker<StaticVehicleMarkerComponent>

  public constructor(
    public override readonly options: StaticVehicleMarkerOptions,
    protected override readonly adapter: MapLibreMapAdapter,
  ) {
    super(options, adapter)

    this.marker = new MaplibreComponentMarker(
      {
        icon: this.options.icon,
        color: this.options.color,
        latLng: this.options.coordinates,
      },
      this.adapter,
      StaticVehicleMarkerComponent,
    )
  }

  public override destroy() {
    super.destroy()
    this.marker?.destroy()
  }
}
