import { Type } from '@angular/core'
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs'
import { ComponentFactory, Coordinates, MapBounds, MapPadding } from '../contracts'
import {
  AbstractStaticVehicleMarker,
  AbstractStopMarker,
  AbstractTripMarker,
  BaseMarker,
  StaticVehicleMarkerOptions,
  StopMarkerOptions,
  TripMarkerOptions,
} from '../markers'

export enum TilesSource {
  AWS = 'aws',
  OSM = 'osm',
  MapTiler = 'maptiler',
  Google = 'google',
  GoogleSatellite = 'google-satellite',
}

export abstract class MapAdapter {
  public map!: unknown
  public hostEl!: HTMLElement
  public tilesSource$ = new BehaviorSubject(TilesSource.AWS)
  public readonly onInit$ = new ReplaySubject<void>(1)
  public readonly onDrag$ = new Subject<void>()
  public readonly onMove$ = new Subject<void>()
  public readonly onMoveEnd$ = new Subject<void>()
  public readonly onZoom$ = new Subject<void>()

  public readonly state = {
    pitch$: new BehaviorSubject(0),
    bearing$: new BehaviorSubject(0),
  }

  public abstract init(el: HTMLElement): Promise<void>
  public abstract zoomIn(): void
  public abstract zoomOut(): void
  public abstract reset(): void
  public abstract resize(): void
  public abstract moveTo(latLng: Coordinates, zoom?: number, speed?: number): void
  public abstract fitBounds(
    latLngRoute: Coordinates[],
    maxZoom?: number,
    speed?: number,
    padding?: number | MapPadding,
  ): void
  public abstract getZoomLevel(): number
  public abstract getMaxZoomLevel(): number
  public abstract getCenter(): Coordinates
  public abstract getBounds(): MapBounds
  public abstract getBearing(): number
  public abstract getPitch(): number
  public abstract getContainer(): HTMLElement
  public abstract isEasing(): boolean
  public abstract isMoving(): boolean
  public abstract disableInteraction(): void
  public abstract addMarker<T extends BaseMarker>(marker: Type<T>, options: T['options']): T
  public abstract addTripMarker(options: TripMarkerOptions): AbstractTripMarker
  public abstract addStopMarker(options: StopMarkerOptions): AbstractStopMarker
  public abstract addStaticVehicleMarker(
    options: StaticVehicleMarkerOptions,
  ): AbstractStaticVehicleMarker
  public setTileSource(value: TilesSource) {
    if (value && value !== this.tilesSource$.value) {
      this.tilesSource$.next(value)
    }
  }
  public setComponentFactory(factory: ComponentFactory<NonNullable<unknown>>) {
    this.componentFactory = factory
  }
  public getComponentFactory() {
    return this.componentFactory
  }
  protected componentFactory!: ComponentFactory<any>
  protected isTabActive() {
    return document.visibilityState === 'visible'
  }
  protected destroy() {
    this.onInit$.complete()
    this.onMove$.complete()
    this.onZoom$.complete()
    this.state.pitch$.complete()
    this.state.bearing$.complete()
  }
}
