import { Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { DateRange, PreDefinedRange } from '@ti-platform/web/common'
import { FilterPanelService } from '../filter-panel/filter-panel.service'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { DataGridSortOrder } from '@ti-platform/web/ui-kit/layout'

@Component({
  selector: 'app-filter-control-btn',
  templateUrl: 'filter-control-btn.component.html',
  styleUrls: ['filter-control-btn.component.scss'],
})
export class FilterControlBtnComponent {
  @Output() dateRangeChanged = new EventEmitter<DateRange | PreDefinedRange | undefined>()

  @Input() mode: 'desktop' | 'compact' | 'mobile' = 'desktop'

  @Input() dateRange?: DateRange | PreDefinedRange | null
  @Input() filtersCount?: number

  @Input() gridSortOrder?: DataGridSortOrder | null
  @Input() defaultSortOrder?: DataGridSortOrder | null

  protected readonly deviceService = inject(DeviceService)
  protected readonly filterPanelService = inject(FilterPanelService)
}
