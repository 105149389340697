import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-round-timer',
  templateUrl: 'round-timer.component.html',
  styleUrl: 'round-timer.component.scss',
})
export class RoundTimerComponent implements OnInit {
  @Output() timerExpired = new EventEmitter()

  @Input() duration = 5
  @Input() accuracy = 0

  protected readonly circleConstant = 126
  protected readonly svgOffset = new BehaviorSubject<number | null>(null)
  protected currentTime = 0
  public intervalId: any | null = null

  ngOnInit() {
    this.svgOffset.next(this.circleConstant)
  }

  public start() {
    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        this.calculateSvgOffset()
      }, 10)
    }
  }

  public update() {
    if (this.intervalId) {
      this.svgOffset.next(this.circleConstant)
      this.currentTime = 0
    }
  }

  public cancel() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
      this.svgOffset.next(this.circleConstant)
      this.currentTime = 0
    }
  }

  public calculateSvgOffset() {
    const newTime = this.currentTime + 0.01

    if (newTime >= this.duration) {
      this.cancel()
      this.timerExpired.emit()
    } else {
      this.currentTime += 0.01
    }

    this.svgOffset.next(
      this.circleConstant - (this.circleConstant * this.currentTime) / this.duration,
    )
  }

  public get timerValue() {
    return this.currentTime.toFixed(this.accuracy)
  }
}
