import { Inject, Injectable } from '@angular/core'
import { BaseConfig, CONFIG } from '@ti-platform/web/common'
import { ApiContext } from '../contracts'
import { ApiClient } from './api.client'
import { FleetScope, TspScope, TiScope } from '../scopes'
import { MyProfileRoute } from '../scopes/common/my-profile.route'

@Injectable()
export class ApiService {
  public readonly ti!: TiScope
  public readonly tsp!: TspScope
  public readonly fleet!: FleetScope
  public readonly myProfile!: MyProfileRoute

  public constructor(client: ApiClient, @Inject(CONFIG) config: BaseConfig) {
    const context: ApiContext = { client, config }
    this.ti = new TiScope(context)
    this.tsp = new TspScope(context)
    this.fleet = new FleetScope(context)
    this.myProfile = new MyProfileRoute(context)
  }
}
