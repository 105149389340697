import { ChangeDetectionStrategy, Component, inject, QueryList, ViewChildren } from '@angular/core'
import { Bookmark } from '@ti-platform/contracts'
import { BookmarksListModel } from '../../models'
import { BookmarksListItemComponent } from '../bookmarks-list-item/bookmarks-list-item.component'

@Component({
  selector: 'app-bookmarks-list',
  templateUrl: 'bookmarks-list.component.html',
  styleUrls: ['bookmarks-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookmarksListComponent {
  protected readonly model = inject(BookmarksListModel)
  @ViewChildren(BookmarksListItemComponent)
  protected readonly bookmarkListItems!: QueryList<BookmarksListItemComponent>

  protected readonly trackByFn = (i: number, item: Bookmark) => item.id
}
