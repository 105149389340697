import { Device } from './device'
import { Fleet } from './fleet'
import { Vehicle } from './vehicle'

export enum VideoStreamType {
  Live = 0,
  Playback = 1,
}

export interface MediaConnection {
  id: string
  externalDeviceId: number
  startDate: Date
  endDate: Date
  isVideoStream: boolean
  videoStreamType: VideoStreamType
  mediaTraffic: number
  fleetId: number
  tspId: number
  vehicleId: string
  deviceId: string
  createdAt: Date
  fleet: Fleet
  vehicle: Vehicle
  device: Device
}
