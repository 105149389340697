import { ChangeDetectorRef, Component, EventEmitter, inject, Input, Output } from '@angular/core'
import { BookmarkDTO } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { BookmarksManager } from '@ti-platform/web/bookmarks'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-toggle-bookmark-button',
  templateUrl: 'bookmark-button.component.html',
  styleUrls: ['bookmark-button.component.scss'],
})
export class BookmarkButtonComponent {
  @Output() bookmarkClicked = new EventEmitter<void>()
  protected readonly bookmarks = inject(BookmarksManager)
  protected readonly processing$ = new BehaviorSubject<boolean>(false)
  protected isChecked = false
  protected readonly api = inject(ApiService)
  protected readonly cd = inject(ChangeDetectorRef)
  private state: BookmarkDTO | null = null

  @Input()
  set bookmark(bookmark: BookmarkDTO | null) {
    this.state = bookmark
    this.revalidateIsChecked()
  }

  async toggleBookmark() {
    if (this.processing$.value) {
      return
    }
    if (this.state) {
      try {
        this.processing$.next(true)
        await this.bookmarks.toggleBookmark(this.state)
        await this.revalidateIsChecked()
        this.processing$.next(false)
      } catch (e) {
        console.error(e)
        this.processing$.next(false)
      }
      this.bookmarkClicked.emit()
    }
  }

  protected async revalidateIsChecked() {
    if (this.state) {
      this.isChecked = await this.bookmarks.isBookmarked(this.state)
      this.cd.detectChanges()
    }
  }
}
