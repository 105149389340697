import { inject, Injectable } from '@angular/core'
import {
  Action,
  ApiErrors,
  CreateDeviceData,
  DataQueryProps,
  DevicesGridData,
  DoubleDeviceCheckData,
  EditDeviceData,
  FleetDisplayData,
  FleetView,
  Resource,
  SensorOptionsData,
} from '@ti-platform/contracts'
import {
  AccessControl,
  checkExistApiError,
  getApiErrorKey,
  getMessageFromException,
  ListModel,
  ListModelConfig,
} from '@ti-platform/web/common'
import { DateFormatService } from '@ti-platform/web/ui-kit/i18n'
import {
  DataGridColumnActionsFn,
  DataGridColumnType,
} from '@ti-platform/web/ui-kit/layout/components'
import { DialogFacade } from '@ti-platform/web/ui-kit/layout/services'
import { MessageService } from 'primeng/api'
import { BehaviorSubject, map, shareReplay, takeUntil } from 'rxjs'
import { DevicesTypesDataProvider } from './devices-types-data.provider'

export interface editNameStatus {
  isLoading: boolean
  device: DevicesGridData | null
  editValue: string
  validValue: boolean
}

export const defaultEditNameStatus = {
  isLoading: false,
  device: null,
  editValue: '',
  validValue: false,
}

export interface testStatus {
  isLoading: boolean
  deviceId: string | null
}

export const defaultTestStatus = {
  isLoading: false,
  deviceId: null,
}

export const MIN_NAME_LENGTH = 1
export const MAX_NAME_LENGTH = 256

@Injectable()
export class DevicesListModel extends ListModel<DevicesGridData> {
  readonly recentFleetsList$ = new BehaviorSubject<FleetDisplayData[]>([])
  readonly allFleetsCount$ = new BehaviorSubject<number>(0)
  readonly recentBillableFleetsList$ = new BehaviorSubject<FleetDisplayData[]>([])
  readonly billableFleetsCount$ = new BehaviorSubject<number>(0)
  readonly billableDevicesCount$ = new BehaviorSubject<number>(0)
  readonly videoDevicesCount$ = new BehaviorSubject<number>(0)
  readonly selectedFleet$ = new BehaviorSubject<FleetView | null>(null)
  readonly deviceDeletedEvent$ = new BehaviorSubject<void>(undefined)
  protected readonly accessControl = inject(AccessControl)
  protected readonly dialogFacade = inject(DialogFacade)
  protected readonly devicesTypesDataProvider = inject(DevicesTypesDataProvider)
  protected readonly messageService = inject(MessageService)
  protected readonly dateFormatService = inject(DateFormatService)

  protected override readonly _exportColumns = [
    {
      field: 'name',
      labelTranslation: 'tsp-admin.devices.grid.device-name',
    },
    {
      field: 'model',
      labelTranslation: 'tsp-admin.devices.grid.brand-and-model',
    },
    {
      field: 'identifier',
      labelTranslation: 'tsp-admin.devices.grid.imei-serial',
    },
    {
      field: 'sim',
      labelTranslation: 'tsp-admin.devices.grid.phone-number-iccid',
    },
    {
      field: 'createdAt',
      labelTranslation: 'tsp-admin.devices.grid.created',
    },
    {
      field: 'fleetName',
      labelTranslation: 'tsp-admin.devices.grid.fleet',
    },
    {
      field: 'server',
      labelTranslation: 'tsp-admin.devices.grid.server',
    },
    {
      field: 'lastActive',
      labelTranslation: 'tsp-admin.devices.grid.last-active',
    },
  ]

  public get isActiveSearch() {
    return !!this.store.search$.value
  }

  public filterByFleet(fleet: FleetView) {
    this.selectedFleet$.next(fleet)
    this.refresh()
  }

  public async createDevice(deviceData: CreateDeviceData) {
    return this.api.tsp.devices.createDevice(deviceData)
  }

  public async editDevice(deviceId: string, deviceData: EditDeviceData) {
    return this.api.tsp.devices.editDevice(deviceId, deviceData)
  }

  public async deleteDevice(deviceId: string) {
    await this.api.tsp.devices.deleteDevice(deviceId)
  }

  public async unassignDeviceFromFleet(deviceId: string) {
    await this.api.tsp.devices.unassignDeviceFromFleet(deviceId)
  }

  public async assignDeviceToFleet(deviceId: string, fleetId: number) {
    await this.api.tsp.devices.assignDeviceToFleet(deviceId, fleetId)
  }

  public async getAndShowMessageOnException(e: any) {
    const message = getMessageFromException(e)
    if (message) {
      console.error(message)
    }
    await this.showUnknownErrorMessage()
  }

  public getFilterValue() {
    return this.store.filter$.getValue()
  }

  public getOrderByValue() {
    return this.store.orderBy$.getValue()
  }

  public async showUnknownErrorMessage() {
    const message = await this.languageService.translate(getApiErrorKey(ApiErrors.unknown))
    this.messageService.add({
      severity: 'error',
      detail: message,
      life: 2000,
    })
  }

  public async getDeviceSensorsOptions(deviceId: string): Promise<SensorOptionsData | null> {
    try {
      const result = await this.api.tsp.devices.getDeviceSensorsOptions(deviceId)
      return result
    } catch (e) {
      this.getAndShowMessageOnException(e)
      return null
    }
  }

  public async setDeviceCommands(deviceId: string, commandIds: string[]) {
    return this.api.tsp.devices.setDeviceCommands(deviceId, commandIds)
  }

  public async getDeviceTrafficData(deviceId: string) {
    return this.api.tsp.devices.trafficData(deviceId)
  }

  async loadCount() {
    const data = await this.api.tsp.devices.count()
    this.billableDevicesCount$.next(data.billable)
    this.videoDevicesCount$.next(data.video)
    this.store.count$.next(data.all)
    return data.all
  }

  public async getAssignRecentFleets() {
    try {
      const result = await this.api.tsp.fleet.shortDisplayList()
      this.recentFleetsList$.next(result?.fleets ?? [])
      this.allFleetsCount$.next(result?.countAll ?? 0)
    } catch (e) {
      await this.getAndShowMessageOnException(e)
    }
  }

  public async loadRecentBillableFleets() {
    try {
      const result = await this.api.tsp.fleet.shortDisplayList(true)
      this.recentBillableFleetsList$.next(result?.fleets ?? [])
      this.billableFleetsCount$.next(result?.countAll ?? 0)
    } catch (e) {
      await this.getAndShowMessageOnException(e)
    }
  }

  public async checkDoubleDevice(data: DoubleDeviceCheckData) {
    return this.api.tsp.devices.checkDouble(data)
  }

  public async checkFleetForAssignment(fleetId: number) {
    return this.api.tsp.devices.checkAssignFleet(fleetId)
  }

  public async getAssignFleetSearch(term: string, noDemo = false, onlyByName = true) {
    return this.api.tsp.fleet.search(term, noDemo, onlyByName)
  }

  protected override async convertDataToExport(data: DevicesGridData[]): Promise<any[]> {
    const result: any[] = []
    for (const row of data) {
      const createdAt = row.createdAt
        ? await this.dateFormatService.formatDate(row.createdAt, 'grid-date-time')
        : ''
      const lastActive = row.lastActive
        ? await this.dateFormatService.formatDate(row.lastActive, 'grid-date-time')
        : ''
      result.push({
        name: row.name ?? '',
        model: row.model ?? '',
        identifier: row.identifier ?? '',
        sim: row.sim ?? '',
        createdAt,
        fleetName: row.fleetName ?? '',
        server: row.server ?? '',
        lastActive,
      })
    }
    return result
  }

  protected processErrorOnException(e: any) {
    const message = getMessageFromException(e)
    console.error(message)
    return checkExistApiError(message) ? getApiErrorKey(message) : getApiErrorKey(ApiErrors.unknown)
  }

  protected config(): ListModelConfig {
    return {
      name: 'TspDevicesListModel',
      defaultOrderColumn: 'createdAt',
      defaultOrderDirection: 'DESC',
      loadCount: () => this.loadCount(),
      isMultiSelectable: true,
      keyColumn: 'id',
      gridColumns: [
        {
          field: 'name',
          label: 'tsp-admin.devices.grid.device-name',
          type: DataGridColumnType.Template,
          sortable: true,
        },
        {
          field: 'model',
          label: 'tsp-admin.devices.grid.brand-and-model',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'identifier',
          label: 'tsp-admin.devices.grid.imei-serial',
          type: DataGridColumnType.TextWithCopy,
          sortable: true,
        },
        {
          field: 'sim',
          label: 'tsp-admin.devices.grid.phone-number-iccid',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'createdAt',
          label: 'tsp-admin.devices.grid.created',
          type: DataGridColumnType.Date,
          sortable: true,
        },
        {
          field: 'fleetName',
          label: 'tsp-admin.devices.grid.fleet',
          type: DataGridColumnType.Text,
          sortable: true,
        },
        {
          field: 'server',
          label: 'tsp-admin.devices.grid.server',
          type: DataGridColumnType.TextWithCopy,
        },
        {
          field: 'lastActive',
          label: 'tsp-admin.devices.grid.last-active',
          type: DataGridColumnType.Date,
        },
        {
          field: 'deviceTest',
          label: 'tsp-admin.devices.grid.device-test',
          type: DataGridColumnType.Template,
        },
        {
          field: 'actions',
          label: '',
          type: DataGridColumnType.Actions,
          actions: ((target: DevicesGridData) => {
            return this.accessControl
              .massCheck$([
                [Resource.Device, Action.Update],
                [Resource.Device, Action.Delete],
              ])
              .pipe(
                map(([canUpdate, canDelete]) => {
                  return [
                    {
                      label: 'tsp-admin.devices.grid.edit-device',
                      icon: 'pi pi-pencil',
                      styleClass: `has-bottom-border ${canUpdate ? '' : 'not-allowed'}`,
                      command: () => this.select(target),
                    },
                    {
                      label: 'tsp-admin.devices.grid.delete-device',
                      icon: 'pi pi-trash',
                      styleClass: `error ${canDelete ? '' : 'not-allowed'}`,
                      command: () => this.delete(target),
                    },
                  ]
                }),
                takeUntil(this.destroy$),
                shareReplay({ bufferSize: 1, refCount: false }),
              )
          }) as DataGridColumnActionsFn,
        },
      ],
    }
  }

  protected async delete(device: DevicesGridData) {
    const deviceName = device.name
    const labels = await this.getDeleteDialogLabels(deviceName)
    const dialogResult = await this.dialogFacade.confirm({
      ...labels,
      cancelButtonColor: 'var(--color-alert-500)',
      confirmButtonIcon: 'pi-trash',
    })
    if (dialogResult) {
      try {
        await this.deleteDevice(device.id)
        const summary = await this.languageService.translate('tsp-admin.devices.delete.success')
        this.messageService.add({ summary, life: 5000, severity: 'success' })
        this.deviceDeletedEvent$.next()
        this.multiUnselectItem(device)
        this.reset()
        this.loadCount()
      } catch (error) {
        await this.getAndShowMessageOnException(error)
      }
    }
  }

  protected async getDeleteDialogLabels(deviceName: string) {
    const toTranslate: Promise<any>[] = [
      this.languageService.translate('tsp-admin.devices.delete.dialog.question', { deviceName }),
      this.languageService.translate('tsp-admin.devices.delete.dialog.fleet-data-warn'),
      this.languageService.translate('tsp-admin.devices.delete.dialog.confirm-button'),
      this.languageService.translate('tsp-admin.devices.delete.dialog.cancel-button'),
    ]
    const data = await Promise.all(toTranslate)
    return {
      summary: data[0],
      description: data[1],
      confirmButton: data[2],
      cancelButton: data[3],
    }
  }

  protected async getLastActiveData(devicesIds: string[]) {
    if (devicesIds.length) {
      try {
        const lastActiveData: Record<string, any> = {}
        const telemetryDataList = await this.api.tsp.devices.telemetry({
          devicesIds,
          parameters: ['server.timestamp', 'timestamp'],
        })
        if (telemetryDataList.length) {
          for (const item of telemetryDataList) {
            const telemetry = item.telemetry
            if (telemetry) {
              lastActiveData[item.id] =
                item.telemetry['server.timestamp']?.value ?? item.telemetry['timestamp']?.value
            } else {
              lastActiveData[item.id] = null
            }
          }
        }
        return lastActiveData
      } catch (e) {
        await this.getAndShowMessageOnException(e)
        return {}
      }
    } else {
      return {}
    }
  }

  protected async loadPage(props: DataQueryProps): Promise<DevicesGridData[]> {
    if (this.selectedFleet$.value) {
      const filter = props.filter ?? {}
      filter.fleetId = {
        eq: this.selectedFleet$.value.id,
      }
      props.filter = filter
    }

    const response = await this.api.tsp.devices.list(props)

    const devices = response.devices
    const devicesIdsWithExternal = devices.filter((item) => item.externalId).map((item) => item.id)
    const lastActiveData = await this.getLastActiveData(devicesIdsWithExternal)
    const result: DevicesGridData[] = []
    for (const device of devices) {
      const extendedDevice: DevicesGridData = {
        ...device,
        server: device.channel ? `${device.channel.host}:${device.channel.port}` : null,
        fleetName: device.fleet ? device.fleet.name : null,
        deviceTest: !device?.fleet,
        lastActive: lastActiveData[device.id]
          ? lastActiveData[device.id] * 1000
          : lastActiveData[device.id],
      }
      result.push(extendedDevice)
    }

    return result
  }
}
