import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core'
import { debounceTime, Subject, takeUntil } from 'rxjs'
import { injectDestroy$ } from '@ti-platform/web/common'

@Directive({
  selector: '[appScrollDetector]',
})
export class ScrollDetectorDirective implements OnInit, OnDestroy {
  private resizeObserver?: ResizeObserver
  protected readonly changeDetector = inject(ChangeDetectorRef)
  protected readonly renderer = inject(Renderer2)
  protected readonly elementRef = inject(ElementRef<HTMLElement>)
  protected readonly destroy$ = injectDestroy$()

  public ngOnInit() {
    this.changeDetector.detectChanges()
    const element = this.elementRef.nativeElement

    const onResize$ = new Subject<void>()
    onResize$.pipe(takeUntil(this.destroy$), debounceTime(16)).subscribe(() => {
      if (element.scrollHeight - element.clientHeight > 0) {
        this.renderer.addClass(element, 'scrollable')
      } else {
        this.renderer.removeClass(element, 'scrollable')
      }
    })

    this.resizeObserver = new ResizeObserver(() => onResize$.next())
    this.resizeObserver.observe(element)
  }

  public ngOnDestroy() {
    this.resizeObserver?.unobserve(this.elementRef.nativeElement)
  }
}
