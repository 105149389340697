<svg width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g filter="url(#filter0_d_3177_58504)">
    <rect
      x="4.19922"
      y="4"
      width="44.1398"
      height="44"
      rx="22"
      fill="#C3505D"
      style="fill: #c3505d; fill: color(display-p3 0.7647 0.3137 0.3647); fill-opacity: 1"
    />
    <rect
      x="2.19922"
      y="2"
      width="48.1398"
      height="48"
      rx="24"
      stroke="white"
      style="stroke: white; stroke-opacity: 1"
      stroke-width="4"
    />
    <path
      d="M19 28.7C19 28.7 19.875 27.8 22.5 27.8C25.125 27.8 26.875 29.6 29.5 29.6C32.125 29.6 33 28.7 33 28.7V17.9C33 17.9 32.125 18.8 29.5 18.8C26.875 18.8 25.125 17 22.5 17C19.875 17 19 17.9 19 17.9V28.7Z"
      fill="white"
      style="fill: white; fill-opacity: 1"
    />
    <path
      d="M19 28.7C19 28.7 19.875 27.8 22.5 27.8C25.125 27.8 26.875 29.6 29.5 29.6C32.125 29.6 33 28.7 33 28.7V17.9C33 17.9 32.125 18.8 29.5 18.8C26.875 18.8 25.125 17 22.5 17C19.875 17 19 17.9 19 17.9V28.7ZM19 28.7V35"
      stroke="white"
      style="stroke: white; stroke-opacity: 1"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
  <!-- <defs>
    <filter
      id="filter0_d_3177_58504"
      x="0.199219"
      y="0"
      width="52.1399"
      height="52"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feMorphology
        radius="4"
        operator="erode"
        in="SourceAlpha"
        result="effect1_dropShadow_3177_58504"
      />
      <feOffset />
      <feGaussianBlur stdDeviation="2" />
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3177_58504" />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_3177_58504"
        result="shape"
      />
    </filter>
  </defs> -->
</svg>
