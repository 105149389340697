import type { TracePropagationTargets } from '@sentry/types/build/types/tracing'
import { InjectionToken } from '@angular/core'

export type EnvType = 'local' | 'dev' | 'production'

export interface BaseConfig {
  envType: EnvType
  googleTagManagerId: string
  awsRegion: string
  awsMapName: string
  awsMapApiKey: string
  mapTilerApiKey: string
  mapTilerStyleName: string
  fleetApiUrl: string
  flespiUrl: string
  fleetDefaultDomain: string
  sentry: {
    enabled: boolean
    dsn?: string
    tracePropagationTargets?: TracePropagationTargets
    networkDetailAllowUrls?: (string | RegExp)[]
  }
  push: {
    instanceId: string
  }
  amplify: AmplifyConfig
  firebase: {
    apiKey: string
    authDomain: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId: string
    measurementId: string
  }
  fcmVapidKey: string
}

export interface AmplifyConfig {
  userPoolId: string
  identityPoolId: string
  userPoolClientId: string
}

export const CONFIG = new InjectionToken<BaseConfig>('config')
