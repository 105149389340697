import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core'
import { Router } from '@angular/router'
import { injectDestroy$ } from '@ti-platform/web/common'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'

export type ViewSwitcherData = {
  value: any
  label: string
  route: string[]
  iconComponent: any
}

@Component({
  selector: 'app-views-switcher',
  templateUrl: 'app-views-switcher.component.html',
  styleUrls: ['app-views-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppViewsSwitcherComponent {
  protected readonly destroy$ = injectDestroy$()
  protected readonly router = inject(Router)
  protected readonly deviceService = inject(DeviceService)

  protected _value: any | null = null

  @Input() options: ViewSwitcherData[] = []

  @Input()
  set value(item: any | null) {
    this._value = item
  }

  get value() {
    return this._value
  }

  protected onValueChange(value: any) {
    const option = this.options.find((item) => item.value === value)
    if (option?.route) {
      this.router.navigate(option.route)
    }
  }
}
