import { inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AppFeatures, BookmarkDTO } from '@ti-platform/contracts'
import { ApiService } from '@ti-platform/web/api'
import { injectDestroy$, PricingPlanService } from '@ti-platform/web/common'
import { LanguageService } from '@ti-platform/web/ui-kit/i18n'
import { DialogFacade } from '@ti-platform/web/ui-kit/layout'
import { PrimeIcons } from 'primeng/api'
import { DialogService } from 'primeng/dynamicdialog'
import { takeUntil } from 'rxjs'
import { EditBookmarkDialogComponent } from '../components/edit-dialog/edit-bookmark-dialog.component'

@Injectable({ providedIn: 'root' })
export class BookmarksManager {
  protected readonly api = inject(ApiService)
  protected readonly dialogFacade = inject(DialogFacade)
  protected readonly dialogService = inject(DialogService)
  protected readonly languageService = inject(LanguageService)
  protected readonly pricingPlan = inject(PricingPlanService)
  protected readonly router = inject(Router)
  protected readonly destroy$ = injectDestroy$()

  async toggleBookmark(bookmark: BookmarkDTO) {
    if (!(await this.pricingPlan.areFeaturesAllowed([AppFeatures.Bookmarks]))) {
      return false
    }

    const { type, route, name, entityId } = bookmark
    return this.api.fleet.bookmarks.toggle({ type, route, name, entityId })
  }

  async isBookmarked(bookmark: BookmarkDTO) {
    if (!(await this.pricingPlan.areFeaturesAllowed([AppFeatures.Bookmarks]))) {
      return false
    }
    return this.api.fleet.bookmarks.isBookmarked(bookmark.entityId, bookmark.type)
  }

  async editBookmarkName(bookmark: BookmarkDTO) {
    if (!(await this.pricingPlan.areFeaturesAllowed([AppFeatures.Bookmarks]))) {
      return false
    }
    const result = await new Promise((resolve) => {
      const ref = this.dialogService.open(EditBookmarkDialogComponent, {
        data: {
          bookmark,
        },
        showHeader: false,
        styleClass: 'unset-default-style',
      })
      ref.onClose.pipe(takeUntil(this.destroy$)).subscribe(resolve)
    })

    if (result) {
      const { type, route, name, entityId } = bookmark
      await this.editName({ type, route, name, entityId })
      return true
    }
    return false
  }

  async editName(bookmark: BookmarkDTO) {
    return this.api.fleet.bookmarks.update(bookmark)
  }

  navigateToBookmark(bookmark: BookmarkDTO) {
    return this.router.navigate([bookmark.route])
  }

  public async remove(bookmark: BookmarkDTO) {
    if (await this.confirmRemove(bookmark)) {
      await this.api.fleet.bookmarks.remove(bookmark.entityId, bookmark.type)
    }
  }

  public async removeWithoutConfirm(bookmark: BookmarkDTO) {
    await this.api.fleet.bookmarks.remove(bookmark.entityId, bookmark.type)
  }

  protected async confirmRemove(bookmark: BookmarkDTO) {
    const labels = await this.languageService.massTranslate(
      {
        summary: 'bookmarks.actions.remove-confirmation',
        description: 'bookmarks.actions.remove-description',
        confirmButton: 'bookmarks.actions.remove-btn',
        cancelButton: 'button.cancel',
      },
      { name: bookmark.name },
    )
    return this.dialogFacade.confirm({
      ...labels,
      cancelButtonColor: 'var(--color-alert-500)',
      confirmButtonIcon: PrimeIcons.BOOKMARK,
    })
  }
}
