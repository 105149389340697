<div class="player">
  <div class="left">
    @if ((isVideoEnded$ | async) !== true) {
      @switch (this.isPaused$ | async) {
        @case (true) {
          <div class="play" (click)="play()">
            <i class="pi pi-play"></i>
          </div>
        }
        @case (false) {
          <div class="pause" (click)="pause()">
            <i class="pi pi-pause"></i>
          </div>
        }
      }
    } @else {
      <div class="pause" (click)="restart()">
        <i class="pi pi-refresh"></i>
      </div>
    }

    <div class="time fw-400">{{ startDate | formatDate: 'long-time' }}</div>
  </div>

  <div class="slider">
    <div class="duration full" #slider (click)="sliderClick($event)"></div>
    <div
      class="duration current"
      [style.width]="(currentCursorPositionInPercents$ | async) + '%'"
    ></div>
    <div
      #cursor
      class="cursor-anchor"
      [style.left]="(currentCursorPositionInPercents$ | async) + '%'"
    >
      <div class="cursor" (mousedown)="handleMouseDown(); $event.stopPropagation()"></div>
    </div>
    @if (false) {
      <div class="highlight" [style.left]="'12%'"></div>
    }
  </div>

  <div class="right">
    <div class="time fw-400">{{ endDate | formatDate: 'long-time' }}</div>
  </div>
</div>
