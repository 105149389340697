<app-fleet-page-header
  (clearFilter)="model.resetFilter()"
  (clearSearch)="model.setSearch('')"
  (filterChanged)="model.applyTypeFilter($event)"
  (searchChanged)="model.setSearch($event)"
  (sortChanged)="model.setOrder($event)"
  [columns]="state.columns$ | async"
  [counter]="(state.count$ | async) ?? 0"
  [defaultSortOrder]="state.defaultSortOrder$ | async"
  [descriptionText]="'bookmarks.description'"
  [filterOptions]="state.filterOptions$ | async"
  [filterPanelStyle]="'compact'"
  [filterValue]="state.filter$ | async"
  [search]="state.search$ | async"
  [showCounter]="true"
  [sortOrder]="state.gridSortOrder$ | async"
  [templates]="{ countIcon }"
  [titleText]="model.title"
></app-fleet-page-header>

<!-- Counter badge -->
<ng-template #countIcon>
  <app-bookmarks-icon [height]="22" [width]="22"></app-bookmarks-icon>
</ng-template>

@if (deviceService.isSmallTablet$ | async) {
  <div class="bookmarks-list-wrapper">
    <app-bookmarks-list></app-bookmarks-list>
  </div>
} @else {
  <app-fleet-bookmarks-grid
    (rowItemClicked)="model.navigateToBookmark($event)"
  ></app-fleet-bookmarks-grid>
}
