import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core'
import {
  DateRange,
  PreDefinedRange,
  injectDestroy$,
  isPreDefinedRange,
} from '@ti-platform/web/common'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'

@Component({
  selector: 'app-date-range-picker-dialog',
  templateUrl: 'date-range-picker-dialog.component.html',
  styleUrls: ['date-range-picker-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerDialogComponent implements OnChanges {
  @Output() readonly dateRangeChange = new EventEmitter<DateRange | PreDefinedRange | undefined>()
  @Input() btnClass = ''
  @Input() numberOfMonths = 2
  @Input() dateRange?: DateRange | PreDefinedRange
  @Input() disabled = false
  @Input() withTime = false
  @Input() singleDay = false
  @Input() showPreDefined = true
  @Input() maxDate?: Date

  protected readonly destroy$ = injectDestroy$()
  protected readonly deviceService = inject(DeviceService)
  protected readonly TODAY_ANCHOR_START = new Date().setHours(0, 0, 0, 0)
  protected readonly TODAY_ANCHOR_DAY_END = new Date().setHours(23, 59, 59, 999)
  protected readonly MILLISECONDS_IN_DAY = this.TODAY_ANCHOR_DAY_END - this.TODAY_ANCHOR_START
  protected readonly PreDefinedRange = PreDefinedRange

  protected showTime = false

  protected get isOneDay(): boolean {
    let result = false
    if (this.dateRange && Array.isArray(this.dateRange) && this.dateRange[0] && this.dateRange[1]) {
      const start = new Date(this.dateRange[0].getTime()).setHours(0, 0, 0, 0)
      const end = new Date(this.dateRange[1].getTime()).setHours(0, 0, 0, 0)
      if (start === end) {
        result = true
      }
    }

    return result
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.withTime && changes['dateRange']) {
      const value = changes['dateRange'].currentValue
      if (value && Array.isArray(value) && value?.[0] && value?.[1]) {
        const start = value[0]
        const end = value[1]
        const startMsFromDayStart =
          start.valueOf() -
          new Date(start.getFullYear(), start.getMonth(), start.getDate()).valueOf()
        const endMsFromDayStart =
          end.valueOf() - new Date(end.getFullYear(), end.getMonth(), end.getDate()).valueOf()

        // Display time only if it's different from default one
        this.showTime = startMsFromDayStart > 0 || endMsFromDayStart < this.MILLISECONDS_IN_DAY
      }
    }
  }

  public onSavedClicked(value: DateRange | PreDefinedRange | undefined) {
    if (value) {
      this.dateRange = this.processSubmittedValue(value)
    }
    this.dateRangeChange.emit(this.dateRange)
  }

  protected processSubmittedValue(value: DateRange | PreDefinedRange): DateRange | PreDefinedRange {
    let result: DateRange | PreDefinedRange

    if (isPreDefinedRange(value)) {
      result = value
    } else {
      result = [...(value as DateRange)]
      if (result[1] === null || !this.withTime) {
        const endAnchor =
          result[1] === null
            ? new Date(result[0].getTime()).setHours(23, 59, 59, 999)
            : new Date(result[1].getTime()).setHours(23, 59, 59, 999)
        result[1] = new Date(endAnchor)
      }
    }
    return result
  }
}
