import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core'
import { DeviceService } from '@ti-platform/web/ui-kit/i18n'
import { OverlayPanel } from 'primeng/overlaypanel'

export interface TagsSelectorOption<V> {
  label: string
  value: V
  // TODO: Create re-usable type for severity
  severity: 'primary' | 'secondary' | 'success' | 'warning' | 'alert'
}

// TODO: Add this component to the design system page
@Component({
  selector: 'app-tag-select',
  templateUrl: 'tag-select.component.html',
  styleUrls: ['tag-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagSelectComponent<V = any> {
  @Output() opened = new EventEmitter<void>()
  @Output() valueChanged = new EventEmitter<V>()
  @Input() options?: TagsSelectorOption<V>[]
  @Input() value?: V
  @Input() redirectClickToBody?: boolean
  @Input() appendMobileToWrapper?: boolean = true
  @Input() appearance?: string

  @ViewChild('statusMenu')
  protected readonly panel!: OverlayPanel

  protected readonly device = inject(DeviceService)

  protected onClick(event: MouseEvent) {
    if (this.redirectClickToBody) {
      event.stopPropagation()
      if (!this.panel.overlayVisible) {
        document.body.click()
      }
    }
  }

  protected get selectedOption() {
    return this.options?.length
      ? this.options.find((option) => option.value === this.value)
      : undefined
  }
}
