import { BaseConfig } from '@ti-platform/web/common'

export default {
  envType: 'dev',
  awsRegion: 'eu-central-1',
  awsMapName: 'DevMap',
  awsMapApiKey:
    'v1.public.eyJqdGkiOiI3NDkxZjFiZS05ODU1LTQ1MzEtYmE2OC1lZDUxOTc1NmViMzcifVFgZqowvifk5I8VZu-YZxMsc3GoIhT5QFJ8raoGhO5gHr48DqMIWPHr2RSQrPJET6Wq40IIq2SpVMB_gHrBELeviHgSeYECpVi_s6FGND0E_-B1N6UzP3oCT0G7WCXCJuDaijs1JrU2qO2xBpIGuUqp5x2QTUXlw1nr4hnhHd5mf9Kigo-8a8tDIS0ok9yG4-Q65MezrjnDbJAfP9IyTYnRn1bb_wWNiuhxRu23wYS2AEBvGH4aS9_z-HRZ8cAifyM_iXS8FkcOlNGzYzFNMzIwCg1hw8SLJNk9w-rHCHUrzw5awYtLEFQFf_c_CxWPSF-EYNL1ecImcXDFTEhZJ5U.Y2MzMTcwNWYtZTgwMy00NGZlLWFiNGItNzVkMDFmMDM0Nzcy',
  mapTilerApiKey: 'rnAyG1XTcUMVHIefbAsp',
  mapTilerStyleName: 'streets-v2-light',
  apiUrl: 'https://rf34leklcatqbmzdplzjteb3ke0whnnw.lambda-url.eu-central-1.on.aws',
  tspDefaultDomain: 'admin-dev.telematicsinsights.ai',
  fleetDefaultDomain: 'dev.telematicsinsights.ai',
  flespiUrl: 'https://flespi.io',
  sentry: {
    enabled: true,
    dsn: 'https://fecff7c8852f8a37f8637477e85164ea@o4507342474313728.ingest.us.sentry.io/4507679786729472',
    tracePropagationTargets: [
      'admin-dev.telematicsinsights.ai',
      'localhost',
      'http://localhost:4200',
      'https://rf34leklcatqbmzdplzjteb3ke0whnnw.lambda-url.eu-central-1.on.aws',
    ],
  },
  amplify: {
    userPoolId: 'eu-central-1_wTjZAm1uk',
    identityPoolId: 'eu-central-1:c4a957ce-4a65-4d74-b443-368b1fa47e04',
    userPoolClientId: '7sas0rf8310n2i7u13n4hj1eiu',
  },
} as BaseConfig
