import { Bookmark, BookmarkDTO, BookmarkType, DataQueryProps } from '@ti-platform/contracts'
import { stripEmptyKeys } from '@ti-platform/web/common'
import { ApiRoute } from '../../../services/api.route'

export class BookmarksRoute extends ApiRoute {
  public override init() {
    this.route = 'bookmarks'
  }

  remove(id: string, type: BookmarkType) {
    return this.delete<boolean>().endpoint(`remove/${id}/${type}`)
  }

  isBookmarked(id: string, type: BookmarkType) {
    return this.get<boolean>().withParams({ id }).endpoint(`is-bookmarked-query/${type}`)
  }

  list(params: DataQueryProps) {
    return this.get<Bookmark[]>()
      .withParams(
        stripEmptyKeys({
          ...params,
          filter: params.filter ? JSON.stringify(params.filter) : '',
        }),
      )
      .endpoint('list')
  }

  public count() {
    return this.get<number>().endpoint('count-all')
  }

  public toggle(dto: BookmarkDTO) {
    return this.post<Bookmark | boolean>().withData(dto).endpoint('toggle')
  }

  public update(dto: BookmarkDTO) {
    return this.post<Bookmark | boolean>().withData(dto).endpoint('update')
  }
}
